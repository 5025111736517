import React, { createContext, useContext, useEffect, useState } from "react";
import {
  AllocationCategory,
  getAllocationCategories,
} from "../utils/adjustInvestment/getAllocationCategories";
import { InvestmentSummaryType } from "../types/adjust-investments";
import { useLocation } from "react-router-dom";
import { INVEST_MORE_PAGE, RATING_PAGE, REBALANCING_PAGE } from "../routes";

export type SelectedThemeType = {
  theme_id: number;
  rating: number;
};

export type ApprovedType = {
  approved?: boolean;
  message?: {
    title: string;
    text: string;
  };
};

export enum InvestmentPageType {
  themeChange,
  rebalancing,
  investMore,
}

interface IInvestmentContext {
  revealed: boolean;
  setRevealed?: (val: boolean) => void;
  selectedThemes?: SelectedThemeType[];
  setSelectedThemes?: (items: SelectedThemeType[]) => void;
  approved: ApprovedType | null;
  setApproved?: (approved: ApprovedType | null) => void;
  investmentSummary: InvestmentSummaryType | null;
  categories: AllocationCategory[];
  setCalculationsResult?: (
    data: InvestmentSummaryType,
    formChanged: boolean,
  ) => void;
  setIsDefaultInvestmentSummary?: (val: boolean) => void;
  isDefaultInvestmentSummary: boolean;
  setIsCalculating?: (val: boolean) => void;
  isCalculating: boolean;
  pageType: InvestmentPageType | null;
  setPageType?: (type: InvestmentPageType) => void;
}

const InvestmentContext = createContext<IInvestmentContext>({
  revealed: false,
  approved: null,
  investmentSummary: null,
  categories: [],
  isDefaultInvestmentSummary: true,
  isCalculating: false,
  pageType: null,
});

type Props = {
  children: any;
};

const InvestmentProvider = ({ children }: Props) => {
  const [revealed, setRevealed] = useState<boolean>(false);
  const [selectedThemes, setSelectedThemes] = useState<SelectedThemeType[]>();
  const [approved, setApproved] = useState<ApprovedType | null>(null);
  const [isDefaultInvestmentSummary, setIsDefaultInvestmentSummary] =
    useState<boolean>(true);
  const [investmentSummary, setInvestmentSummary] =
    useState<InvestmentSummaryType | null>(null);
  const [categories, setCategories] = useState<AllocationCategory[]>([]);
  const [isCalculating, setIsCalculating] = useState<boolean>(false);
  const [pageType, setPageType] = useState<InvestmentPageType | null>(null);

  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;

    if (path.includes(RATING_PAGE.to)) {
      setRevealed(true);
      setPageType(InvestmentPageType.themeChange);
    } else if (path.includes(REBALANCING_PAGE.to)) {
      setPageType(InvestmentPageType.rebalancing);
    } else if (path.includes(INVEST_MORE_PAGE.to)) {
      setPageType(InvestmentPageType.investMore);
    }
  }, [location]);

  const setCalculationsResult = (
    data: InvestmentSummaryType,
    formChanged: boolean,
  ) => {
    setInvestmentSummary(data);
    setCategories(getAllocationCategories(data));
    setIsDefaultInvestmentSummary(!formChanged);
    setIsCalculating(false);
  };

  return (
    <InvestmentContext.Provider
      value={{
        revealed,
        setRevealed,
        selectedThemes,
        setSelectedThemes,
        approved,
        setApproved,
        investmentSummary,
        categories,
        setCalculationsResult,
        setIsDefaultInvestmentSummary,
        isDefaultInvestmentSummary,
        isCalculating,
        setIsCalculating,
        pageType,
        setPageType,
      }}
    >
      {children}
    </InvestmentContext.Provider>
  );
};

function useInvestmentContext() {
  const context = useContext(InvestmentContext);

  if (typeof context === "undefined") {
    throw new Error(
      "useInvestmentContext() must be used within a InvestmentProvider component",
    );
  }

  return context;
}

export { InvestmentProvider, useInvestmentContext };
