import { useFormikContext } from "formik";
import React from "react";
import { COLOR_PRIMARY } from "../../config/constants";
import ThemePieChart from "./Charts/ThemePieChart";
import { ThemeBalanceForm } from "./themeBalanceForm";
import { UserThemes } from "./votePage";
import { Trans, useTranslation } from "react-i18next";

export const VoteBalancePage: React.FC = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<UserThemes>();

  const minHeight = 600;
  return (
    <div className="w-full h-full lg:flex lg:items-center lg:justify-center pt-8 lg:pt-0">
      <div className="container mx-auto">
        <div
          className="flex flex-col-reverse lg:flex-row relative min-h-30"
          style={{ minHeight: minHeight }}
        >
          <div className="col-12 col-lg-6 order-1 order-lg-0 position-relative">
            <div className="flex flex-col h-full justify-content-center items-center align-middle">
              <div>
                <h1 className="">
                  <Trans i18nKey={"communityInvite.themeBalanceTitle"}>
                    {""}
                    <span className="text-red"></span>
                  </Trans>
                </h1>
                <p>{t("communityInvite.themeBalanceText")}</p>
              </div>
              {values.themes.length > 0 && (
                <ThemePieChart themes={values.themes} />
              )}
            </div>

            <div
              className="h-100 d-none d-lg-block position-absolute pt-6 "
              style={{ top: 0, right: 0, zIndex: 10, width: 1 }}
            >
              <div
                className="w-100 h-100 "
                style={{ background: COLOR_PRIMARY }}
              ></div>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex flex-column justify-content-center mt-6">
            <div className="flex flex-col px-5 pb-5 lg:pb-0">
              <ThemeBalanceForm />
              <div className="flex self-end mt-5"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
