/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 21/12/2020.
 */
import React from "react";

import { Button } from "../display/Button";

import svgImg from "../../assets/svg/invite_modal_img.svg";
import { useTranslation } from "react-i18next";

type Props = {
  emailsSent: string[];
  closeModal: () => void;
  back: () => void;
};

export const InviteSent: React.FC<Props> = ({
  //  emailsSent,
  closeModal,
  back,
}) => {
  const { t } = useTranslation();
  return (
    <div className="container-fluid ">
      <div className="row mt-3">
        <div className="col-12">
          <h5>{t("dashboard.invite.sentTitle")}</h5>
          <p>{t("dashboard.invite.sentText")}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <img src={svgImg} alt="" />
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-4 mt-2 d-flex justify-content-between">
          <Button onClick={back}>
            <p className="mb-0">{t("dashboard.invite.inviteMoreBtn")}</p>
          </Button>
          <Button filled={true} onClick={closeModal}>
            <p className="mb-0">{t("generic.close")}</p>
          </Button>
        </div>
      </div>
    </div>
  );
};
