import { useFormikContext } from "formik";
import { lookUpData } from "../../config/constants";
import { ThemeBalanceItem } from "./themeBalanceItem";
import { UserThemes } from "./votePage";
import { DOMOBILIZE_INTRO_PAGE } from "../../routes";
import { Link } from "react-router-dom";
import { Button } from "../../components/display/Button";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const ThemeBalanceForm = () => {
  const { t } = useTranslation();
  const filteredData = { ...lookUpData };
  delete filteredData[0];
  delete filteredData[1];
  delete filteredData[105];
  delete filteredData[10001];
  const theme_ids = Object.keys(filteredData);
  const { values, setFieldValue, submitForm } = useFormikContext<UserThemes>();
  const [isAnyThemeActive, setActive] = useState(false);
  const onToggleClick = (theme_id: number) => {
    if (!values.themes.some(selectedTheme => selectedTheme.id === theme_id)) {
      setFieldValue("themes", [...values.themes, { id: theme_id, rating: 50 }]);
      return;
    } else if (
      values.themes.some(selectedTheme => selectedTheme.id === theme_id)
    ) {
      setFieldValue(
        "themes",
        values.themes.filter(item => item.id !== theme_id),
      );
    }
  };
  useEffect(() => {
    if (values.themes.length === 0) {
      setActive(false);
    } else {
      setActive(true);
    }
  }, [values.themes.length]);
  const onRatingChange = (theme_id: number) => (rating: number) => {
    const updatedThemes = values.themes.map(item =>
      item.id === theme_id ? { ...item, rating } : item,
    );
    setFieldValue("themes", updatedThemes);
  };

  return (
    <>
      <div className="flex flex-col gap-10">
        <div>
          {Object.values(filteredData).map((item, index) => {
            const active = values.themes.some(
              selectedTheme => selectedTheme.id === +theme_ids[index],
            );
            const themeItemRating = values.themes.find(
              item => item.id === +theme_ids[index],
            );

            return (
              <ThemeBalanceItem
                onToggleClick={() => {
                  onToggleClick(+theme_ids[index]);
                }}
                onSliderChange={onRatingChange(+theme_ids[index])}
                itemColor={item.color}
                key={item.title}
                data={{
                  theme_id: +theme_ids[index],
                  title: item.title,
                  rating: themeItemRating?.rating || 50,
                  active: active,
                  weight: 40,
                }}
              />
            );
          })}
        </div>
        <div className="flex self-end ">
          <Link to={DOMOBILIZE_INTRO_PAGE.to}>
            <Button
              submit
              onClick={submitForm}
              filled={isAnyThemeActive}
              disabled={!isAnyThemeActive}
            >
              <p className="mb-0">
                {!isAnyThemeActive
                  ? t("communityInvite.selectThemesBtn")
                  : "Stem"}
              </p>
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};
