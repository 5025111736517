/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 17/12/2020.
 */
import React, {
  ChangeEvent,
  CSSProperties,
  useEffect,
  useRef,
  useState,
  KeyboardEvent,
} from "react";

type Props = {
  initialTxt: string;

  className: string;
  style: CSSProperties;
  placeholder: string;
  inputChangeFunc?: (e: string) => void;
  enterKeyDown?: (inputTxt: string) => void;
  handleBlurFunc?: () => void;
};

export const InputBox: React.FC<Props> = ({
  initialTxt = "",
  inputChangeFunc,
  enterKeyDown,
  className,
  style = {},
  placeholder = "",
  handleBlurFunc,
}) => {
  const [inputTxt, setInputText] = useState(initialTxt);
  const inputChanged = useRef(false);
  useEffect(() => {
    setInputText(initialTxt);
  }, [initialTxt]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
    inputChanged.current = true;
    if (inputChangeFunc) {
      inputChangeFunc(e.target.value as string);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (enterKeyDown) {
        enterKeyDown(inputTxt);
      }
    }
  };

  const handleBlur = () => {
    if (inputChanged.current) {
      inputChanged.current = false;
      if (handleBlurFunc) {
        handleBlurFunc();
      }
    }
  };

  /*const clear = () => {
    setInputText('')
  }*/

  return (
    <input
      type="text"
      className={className}
      placeholder={placeholder}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      style={{
        color: "#2b2b2b",
        border: "none",
        outline: "none",
        background: "transparent",
        ...style,
      }}
      onChange={onChange}
      value={inputTxt}
    />
  );
};
