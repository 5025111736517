import React from "react";
import { LoadingTypeEnum } from "../../store/OverlayStore";
import OverlayLoader from "./OverlayLoader";
import BlockingLoader from "./BlockingLoader";

const GlobalLoader: React.FC<{
  value: boolean;
  type: LoadingTypeEnum;
  message?: string;
  children?: React.ReactNode;
}> = ({ value, type, message, children }) => {
  if (value && type === LoadingTypeEnum.Overlay)
    return <OverlayLoader message={message}>{children}</OverlayLoader>;

  if (value && type === LoadingTypeEnum.Blocking)
    return <BlockingLoader message={message} />;
  return <>{children}</>;
};

export default GlobalLoader;
