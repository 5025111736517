/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 23-09-2020.
 */

//https://seesparkbox.com/foundry/how_to_code_an_SVG_pie_chart
import React, { useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { Tooltip } from "react-tooltip";
import "./pie-chart.scss";

import {
  COLOR_BROWN,
  COLOR_GREEN,
  COLOR_PRIMARY,
  lookUpData,
} from "../config/constants";
import { InvestMoreModal } from "./invest-more-modal/InvestMoreModal";
import { useAuthStore } from "../hooks/useAuthStore";

type Props = {
  data: any;
  showInvestMore?: boolean;
  showTotalAmount?: boolean;
  title?: string;
  excludeThemes?: number[];
};

export const PieChartComp: React.FC<Props> = ({
  data,
  showInvestMore = true,
  showTotalAmount = true,
  title,
  excludeThemes = [],
}) => {
  const { hasTrialAccess } = useAuthStore();
  const [hovered, setHovered] = useState<number | undefined>(undefined);
  const { portfolio, total_amount } = data;

  const makeTooltipContent = (entry: any) => {
    return (
      <div className="mt-2">
        <h6>{entry.title}</h6>
        {entry.description && <p>{entry.description}</p>}
      </div>
    );
  };

  const generatedData = portfolio
    .filter((entry: any) => !excludeThemes?.includes(entry.id))
    .map((entry: any, i: number) => {
      if (hovered === i) {
        return {
          ...entry,
          title: "",
          myTitle: entry.title,
        };
      }
      entry.value = entry.percentage * 100;
      entry.color = lookUpData[entry.id.toString()].color; //'#F1EDDA'

      return entry;
    });

  const formatter = new Intl.NumberFormat("da-DA", {
    style: "currency",
    currency: "DKK",
    minimumFractionDigits: 0,
  });

  return (
    <div
      data-tip=""
      data-tooltip-id="chart"
      className="position-relative d-flex mx-auto "
      style={{ maxWidth: 420 }}
    >
      <div
        className="position-absolute w-50 h-50"
        style={{
          overflow: "auto",
          margin: "auto",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <div className="d-flex flex-column h-100 justify-content-center pt-3">
          <h6 className="text-center" style={{ color: COLOR_BROWN }}>
            {title}
          </h6>
          {showTotalAmount && (
            <p
              className="text-center pt-1 pt-md-2 mb-2 "
              style={{
                fontSize: "1.5rem",
                borderTop: `1px solid ${COLOR_PRIMARY}`,
                color: COLOR_GREEN,
              }}
            >
              {formatter.format(Math.round(total_amount)).toString()}
            </p>
          )}
          {!hasTrialAccess() && showInvestMore && (
            <div className="d-flex justify-content-center align-items-center">
              <InvestMoreModal />
            </div>
          )}
        </div>
      </div>
      <PieChart
        lineWidth={30}
        onMouseOver={(_, index) => {
          setHovered(index);
        }}
        onMouseOut={() => {
          setHovered(undefined);
        }}
        data={generatedData}
      />
      <Tooltip
        id="chart"
        className="my-tooltip"
        place="top"
        variant="dark"
        float
      >
        {typeof hovered === "number"
          ? makeTooltipContent(portfolio[hovered])
          : null}
      </Tooltip>
    </div>
  );
};
