import { useMediaQuery } from "react-responsive";
import { SpinnerFullHeight } from "../../components/spinner/SpinnerFullHeight";
import { COLOR_PRIMARY } from "../../config/constants";
import { useAuthStore } from "../../hooks/useAuthStore";
import { ButtonGreen } from "../../components/display/ButtonGreen";
import { useNavigate } from "react-router-dom";
import { TOOLS_PAGE, casesUrl } from "../../routes";
import { Trans, useTranslation } from "react-i18next";
const TrialPage: React.FC = () => {
  const { user } = useAuthStore();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const largeScreen = useMediaQuery({ minWidth: 992 });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const minHeight = isMobile ? 500 : 600;
  return (
    <>
      <div className="container flex lg:h-full items-center">
        <div className={`row position-relative min-h-${minHeight}`}>
          <div className="col-12 col-lg-6 order-1 order-lg-0 position-relative">
            <div
              className={`row w-full lg:w-[460px] mx-auto xl:w-[560px] lg:h-[700px] flex justify-center items-center lg:pr-8`}
            >
              <div className="relative w-100 h-80">
                <iframe
                  title="Loom Video"
                  src="https://www.loom.com/embed/a5be7731d66d48bb803280db01411e69?sid=61057c76-aa6a-4639-9e10-c1514a28579e"
                  allowFullScreen
                  className="absolute top-0 left-0 w-full h-full"
                ></iframe>
              </div>
            </div>

            <div
              className="h-100 d-none d-lg-block position-absolute"
              style={{ top: 0, right: 0, zIndex: 10, width: 1 }}
            >
              <div
                className="w-100 h-100 "
                style={{ background: COLOR_PRIMARY }}
              ></div>
            </div>
          </div>

          <div className="col-12 col-lg-6 flex flex-column justify-content-center">
            {!user ? (
              <SpinnerFullHeight />
            ) : (
              <div
                className={`flex flex-col px-5 pb-5 gap-10 ${
                  !largeScreen ? "mt-6" : ""
                }`}
              >
                <h1 className="lg:mt-0" style={{ whiteSpace: "pre-line" }}>
                  {t("trial.header")}
                </h1>
                <div>
                  <Trans i18nKey={"trial.description"}>
                    <p></p>
                    <p></p>
                  </Trans>
                </div>
                <div className="flex gap-2">
                  <ButtonGreen filled>
                    <p className="mb-0" onClick={() => navigate(TOOLS_PAGE.to)}>
                      {t("trial.toolsBtn")}
                    </p>
                  </ButtonGreen>
                  <ButtonGreen white url={casesUrl}>
                    <p className="mb-0">{t("trial.casesBtn")}</p>
                  </ButtonGreen>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default TrialPage;
