//import { authStore, getRefreshToken, getToken } from '../store/auth/authStore'
//import { BASE_URL } from '../config/constants'
import { getToken } from "../store/auth/authStore";

export function get<T = any>(p: any): Promise<T> {
  const token = getToken();

  return fetch(p, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then(res => {
      if (res.status !== 200) {
        return Promise.reject(res);
      }

      return res.json();
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export const post = (p: string, data: any) => {
  const token = getToken();
  return fetch(p, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  })
    .then(res => {
      return res.json();
    })
    .then(res => {
      if (res.exception) {
        return Promise.reject(res);
      }

      return res;
    })

    .catch(err => {
      return Promise.reject(err);
    });
};
