import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { CONFIG_CENTER_API } from "../config/constants";
import { authStore } from "../store/auth/authStore";

/* const signOut = () => {
  sessionStorage.removeItem("token");
  window.location.href = LOGIN.to;
}; */

const axiosInstance = axios.create({
  baseURL: CONFIG_CENTER_API,
  timeout: 5000, // Request timeout
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async config => {
    const token = localStorage.getItem("doland-preview-token");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      console.log(token);

      const decodedToken: any = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        console.error("Token has expired.");

        // Sign the user out and redirect to login page
        const logout = authStore(state => state.logout);
        logout();
      }
    }

    return config;
  },
  error => Promise.reject(error),
);

// Response Interceptor to handle errors
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    // If 401 Unauthorized
    if (error.response.status === 401) {
      console.error("Unauthorized request:", error);

      const logout = authStore(state => state.logout);
      logout();
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
