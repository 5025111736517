import React from "react";
import { roundToOneDecimal } from "../../../utils/roundToOneDecimal";

import i18n from "../../../utils/i18n";
import {
  InvestmentFeesTradingType,
  InvestmentFeesType,
  PercentAmount,
} from "../../../types/adjust-investments";

const lookupItems = [
  {
    key: "doland",
    title: "DoLand",
    byline: "Rådgivning, overvågning og brug af MyDoLand",
  },
  {
    key: "product",
    title: "Produkter",
    byline: "Produktudbydere for administration",
  },
  {
    key: "custody",
    title: "Opbevaringsomkostninger",
    byline: "",
    items: [
      { key: "custody_funds", title: "Opbevaring af investeringsforeninger" },
    ],
  },
];

const feesTrading = {
  key: "fees_trading",
  title: "Handelsomkostninger",
  byline: "",
  items: [
    { key: "trade", title: "Handelsomkostninger" },
    { key: "fx", title: "Veksling af valuta" },
    { key: "indirect", title: "Indirekte omkostninger" },
  ],
};

function getProperty<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

type TotalRowProps = {
  sum: PercentAmount;
};
const TotalRow = ({ sum }: TotalRowProps) => {
  return (
    <div className="row ">
      <div className="col-12">
        <hr />
      </div>
      <div className="col-6">
        <p className="p-table-title fw-bold">{i18n.t("generic.inAll")}</p>
      </div>
      <div className="col-3">
        <p className="p-table-title fw-bold">
          {roundToOneDecimal(sum.percentage * 100, true)}
        </p>
      </div>
      <div className="col-3">
        <p className="p-table-title fw-bold text-right">
          {roundToOneDecimal(sum.amount)}
        </p>
      </div>
    </div>
  );
};

type FeesProps = {
  specObj: any;
  item: any;
};
const Fees = ({ specObj, item }: FeesProps) => {
  return (
    <div className="row">
      <div className="col-12">
        <hr />
      </div>

      <div className="col-6">
        <p className="my-2 p-table-title fw-bold">{specObj.title}</p>
        <p className="my-2 fw-bold">{specObj.byline}</p>
      </div>
      <div className="col-3">
        <p className="mb-1 p-table-title fw-bold">
          {roundToOneDecimal(item.sum.percentage * 100, true)}
        </p>
      </div>
      <div className="col-3">
        <p className="mb-1 p-table-title fw-bold text-right">
          {roundToOneDecimal(item.sum.amount)}
        </p>
      </div>

      {specObj.items &&
        specObj.items.map((subItem: any, subIndex: number) => {
          const subValue = item.items[subItem.key];

          return (
            <div className="col-12" key={subIndex}>
              <div className="row">
                <div className="col-6 mb-1">
                  <p
                    className="mb-0"
                    style={{ fontSize: ".7rem", opacity: 0.4 }}
                  >
                    {subItem.title}
                  </p>
                </div>
                <div className="col-3">
                  <p
                    className="mb-0"
                    style={{ fontSize: ".7rem", opacity: 0.4 }}
                  >
                    {roundToOneDecimal(subValue.percentage * 100, true)}
                  </p>
                </div>
                <div className="col-3">
                  <p
                    className="mb-0 text-right"
                    style={{ fontSize: ".7rem", opacity: 0.4 }}
                  >
                    {roundToOneDecimal(subValue.amount)}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

type FeesHeaderProps = {
  title: string;
};
const FeesHeader = ({ title }: FeesHeaderProps) => {
  return (
    <div className="row pt-4">
      <div className="col-6 d-flex ">
        <p className="mb-0 p-table-title fw-bold">{title}</p>
      </div>
      <div className="col-3 d-flex ">
        <p className="mb-0 p-table-title fw-bold ">
          {i18n.t("generic.percent")}
        </p>
      </div>
      <div className="col-3 d-flex justify-content-end ">
        <p className="mb-0 p-table-title fw-bold ">{i18n.t("generic.inDkk")}</p>
      </div>
    </div>
  );
};

type Props = {
  fees: InvestmentFeesType;
  fees_trading: InvestmentFeesTradingType;
};

export const InvestmentFeesTable = ({ fees, fees_trading }: Props) => {
  return (
    <>
      <FeesHeader title={i18n.t("myThemes.feesHeaderRunningCosts")} />
      {lookupItems.map((specObj: any, index: number) => {
        const item = getProperty(fees, specObj.key as any);
        return <Fees key={index} specObj={specObj} item={item} />;
      })}
      <TotalRow sum={fees.sum} />

      <FeesHeader title={i18n.t("myThemes.feesHeaderTradingCosts")} />
      <Fees specObj={feesTrading} item={fees_trading} />
      <TotalRow sum={fees_trading.sum} />

      <div className="row mt-5">
        <div className="col-12">
          <p className="text-muted" style={{ fontSize: ".7rem" }}>
            {i18n.t("myThemes.changedFeesDescription")}
          </p>
        </div>
      </div>
    </>
  );
};
