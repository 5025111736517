import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  COLOR_GREEN,
  chartConfig,
  trialChartConfig,
} from "../../config/constants";
import { Radio } from "../../pages/profile/fields/FormikRadio";
import { useAuthStore } from "../../hooks/useAuthStore";

export const InvestmentLineChart = ({ allData }: { allData: any }) => {
  const { hasTrialAccess } = useAuthStore();
  const initialChartFilter = { label: "3M", value: 90 };
  const [chartFilter, setChartFilter] = useState(initialChartFilter);
  const configToUse = hasTrialAccess() ? trialChartConfig : chartConfig;

  const [generatedData, setGeneratedData] = useState<
    {
      x: number;
      y: number;
      xLabel: string;
    }[]
  >([]);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const randomArray = (collection: any) => {
      const data = [];

      if (!collection) {
        return [];
      }
      const fromArr = Object.keys(collection); // make keys to an array

      const len = fromArr.length;

      const startDay = new Date(fromArr[0]).getTime();

      for (let i = 0; i < len; i++) {
        const item = fromArr[i];
        const value = collection[item];
        const nextStartTime = new Date(item).getTime() - startDay;
        const daysDiff = nextStartTime / (1000 * 3600 * 24);

        const dateArr = item.split("-");
        const xLabel = dateArr[2] + "/" + dateArr[1];

        const obj = {
          x: daysDiff,
          y: value.toFixed(2),
          xLabel: xLabel,
        };
        obj.x >= len - chartFilter.value && data.push(obj);
      }

      return data;
    };
    setGeneratedData(randomArray(allData.balances));
  }, [allData, chartFilter]);

  const maxChartValue = allData.interval.max;
  const minChartValue = allData.interval.min;

  return (
    <>
      <div className="col-12 d-flex justify-content-end">
        <div className="radio-options-wrapper">
          {configToUse.map(item => (
            <Radio
              key={item.value}
              label={item.label}
              value={item.value}
              selected={chartFilter.value === item.value}
              onClick={() => {
                setChartFilter(item);
              }}
            />
          ))}
        </div>
      </div>
      <ResponsiveContainer
        width="100%"
        className="p-0"
        height={650}
        minHeight={isMobile ? 500 : 600}
      >
        <LineChart
          width={500}
          height={300}
          data={generatedData}
          margin={{
            top: 5,
            right: 30,
            left: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="xLabel" style={{ fontSize: ".8rem" }} />
          <YAxis
            style={{ fontSize: ".8rem" }}
            domain={[minChartValue, maxChartValue]}
            tickSize={5}
            tickFormatter={(value: number) =>
              new Intl.NumberFormat("da-DA", {
                style: "currency",
                currency: "DKK",
                minimumFractionDigits: 0,
              }).format(value)
            }
          />
          <Tooltip
            style={{ fontSize: ".9rem" }}
            formatter={(value: number) =>
              new Intl.NumberFormat("da-DA", {
                style: "currency",
                currency: "DKK",
                minimumFractionDigits: 0,
              }).format(value)
            }
          />
          <Legend />
          <Line
            type="monotone"
            dataKey="y"
            name="Tot"
            stroke={COLOR_GREEN}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};
