import { BASE_URL } from "../config/constants";
import { InvestmentResponse } from "./../service/InvestmentResponse";
import { create } from "zustand";
import { get as getResponse } from "../service/API";
import { SummeryResponse } from "../service/SummeryResponse";
import { FeeResponse } from "../service/FeeResponse";

export enum InvestmentTimeSpanEnum {
  OneMonth = "1month",
  TheeMonths = "3months",
  ThisYear = "thisyear",
  OneYear = "1year",
  SinceStart = "Max",
}

type InvestmentStore = {
  selectedTimeSpan: string;
  dataCache: Record<string, InvestmentResponse>;
  loading: boolean;
  isPct: boolean;
  assetData: Record<string, InvestmentResponse>;
  summeryData: Record<string, SummeryResponse>;
  feesData: Record<string, FeeResponse>;
  setViewType: (isPercentage: boolean) => void;
  setSelectedTimeSpan: (selectedTimeSpan: string) => void;
  loadData: (hasTrialAccess: boolean) => void;
};

const useInvestmentsStore = create<InvestmentStore>((set, get) => ({
  selectedTimeSpan: "1month",
  dataCache: {},
  loading: true,
  assetData: {},
  summeryData: {},
  feesData: {},
  isPct: false,
  setViewType: isPercentage => {
    set({ isPct: isPercentage });
  },
  setSelectedTimeSpan: selectedTimeSpan => {
    set({ selectedTimeSpan });
  },

  loadData: async (hasTrialAccess: boolean) => {
    set({ loading: true });
    const { selectedTimeSpan, dataCache } = get();

    if (dataCache[selectedTimeSpan]) {
      set({ loading: false });
      return;
    }

    try {
      const [assetRes, summaryRes, feesRes] = await Promise.all([
        getResponse(
          `${BASE_URL}${
            hasTrialAccess ? "/trial" : ""
          }/dashboard/investment/assets?period=` + selectedTimeSpan,
        ),
        getResponse(
          `${BASE_URL}${
            hasTrialAccess ? "/trial" : ""
          }/dashboard/investment/summary?period=` + selectedTimeSpan,
        ),
        getResponse(
          `${BASE_URL}${
            hasTrialAccess ? "/trial" : ""
          }/dashboard/investment/fees?period=` + selectedTimeSpan,
        ),
      ]);

      dataCache[selectedTimeSpan] = assetRes;
      set(prevState => ({
        ...prevState,
        dataCache,
        loading: false,
        assetData: {
          ...prevState.assetData,
          [selectedTimeSpan]: assetRes,
        },
        summeryData: {
          ...prevState.summeryData,
          [selectedTimeSpan]: summaryRes,
        },
        feesData: {
          ...prevState.feesData,
          [selectedTimeSpan]: feesRes,
        },
      }));
    } catch (error) {
      console.log(" MyInvestments > error = ", error);
    } finally {
      set({ loading: false });
    }
  },
}));

export default useInvestmentsStore;
