import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthStore } from "../../hooks/useAuthStore";
import { Loading, useOverlayStore } from "../../store/OverlayStore";
import { AnimatedRoute } from "../AnimatedRoute";
import GlobalLoader from "../loading/GlobalLoader";
import { Navigation } from "../navigation/Navigation";
import { LOGIN } from "../../routes";

type ProtectedLayoutProps = {
  loading: Loading;
  token: string | null;
  previewToken: string | null;
};

const ProtectedLayout: React.FC<ProtectedLayoutProps> = ({ loading }) => {
  const {
    getDolandUser,
    validateTrialToken,
    token,
    previewToken,
    user,
    roles,
  } = useAuthStore();
  const { setLoading } = useOverlayStore();

  if (!token && !previewToken) {
    return <Navigate to={LOGIN.to} replace />;
  }

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      if (token && !user) {
        await getDolandUser();
      } else if (previewToken && !user) {
        await validateTrialToken();
      }
      setLoading(false);
    };

    init();
  }, []);

  return (
    <>
      {roles && <Navigation />}
      <GlobalLoader
        type={loading.type}
        value={loading.value}
        message={loading.message}
      />
      <AnimatedRoute>
        <Outlet />
      </AnimatedRoute>
    </>
  );
};

export default ProtectedLayout;
