// Filename: PieChartIcon.tsx
import React from "react";

interface PieChartIconProps {
  id?: string;
  width?: string | number;
  height?: string | number;
  fillColor?: string;
  accentColor?: string;
  className?: string;
  style?: React.CSSProperties;
}

const PieChartIcon: React.FC<PieChartIconProps> = ({
  id = "Layer_1",
  width = 411.68,
  height = 361.66,
  fillColor = "#fff",
  accentColor = "#20a2b9",
  className = "",
  style,
}) => {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 411.68 361.66"
      width={width}
      height={height}
      className={className}
      style={style}
    >
      <defs>
        <style>
          {`.second-cls-1{fill:${fillColor};}.second-cls-2{fill:${accentColor};}`}
        </style>
      </defs>
      <rect
        className="second-cls-2"
        x="88.03"
        y="70.68"
        width="235.61"
        height="235.61"
        rx="25.63"
        ry="25.63"
      />
      <circle className="second-cls-1" cx="205.84" cy="187.05" r="94.32" />
      <circle className="second-cls-2" cx="205.84" cy="187.05" r="58.37" />
      <rect
        className="second-cls-2"
        x="196.46"
        y="235.48"
        width="12.02"
        height="61.62"
      />
      <rect
        className="second-cls-2"
        x="129.78"
        y="156.24"
        width="12.02"
        height="61.62"
        transform="translate(322.84 51.26) rotate(90)"
      />
      <rect
        className="second-cls-2"
        x="256.81"
        y="104.21"
        width="12.02"
        height="61.62"
        transform="translate(353.18 416.33) rotate(-135)"
      />
    </svg>
  );
};

export default PieChartIcon;
