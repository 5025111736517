import { InvestmentRequestType } from "../types/adjust-investments";

type Action =
  | { type: "INIT"; items: InvestmentRequestType[] }
  | { type: "TOGGLE_ITEM"; theme_id: number }
  | { type: "SET_ITEM_RATING"; theme_id: number; rating: number };

export function adjustInvestmentReducer(
  state: InvestmentRequestType[],
  action: Action,
): InvestmentRequestType[] {
  switch (action.type) {
    case "INIT":
      return [...action.items];
    case "TOGGLE_ITEM":
      return state.map(item =>
        item.theme_id === action.theme_id
          ? { ...item, active: !item.active }
          : item,
      );
    case "SET_ITEM_RATING":
      return state.map(item =>
        item.theme_id === action.theme_id
          ? { ...item, rating: action.rating }
          : item,
      );
    default:
      return state;
  }
}
