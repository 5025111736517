/**
 * Created by @author @apurva-toptal - Apurva Mistry on 17/03/2022.
 */
import React from "react";
import "./gravatar.scss";
import defaultGravatar from "../../../assets/images/default-gravatar.png";

interface GravatarProps {
  img?: string;
}

const Gravatar = ({ img = defaultGravatar }: GravatarProps) => {
  return (
    <div className={"gravatar-image-wrapper"}>
      <img src={img} alt={"user gravatar"} className={"gravatar-image"} />
    </div>
  );
};

export default Gravatar;
