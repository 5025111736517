// Filename: InformationIcon.tsx
import React from "react";

interface InformationIconProps {
  id?: string;
  width?: string | number;
  height?: string | number;
  fillColor?: string;
  className?: string;
  style?: React.CSSProperties;
}

const InformationIcon: React.FC<InformationIconProps> = ({
  id = "Layer_1",
  width = 411.68,
  height = 361.66,
  fillColor = "#27aa8c",
  className = "",
  style,
}) => {
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 411.68 361.66"
      width={width}
      height={height}
      className={className}
      style={style}
    >
      <defs>
        <style>{`.sixth-cls-1{fill:${fillColor};}`}</style>
      </defs>
      <path
        className="sixth-cls-1"
        d="m180.12,309.71v-172.65h65.51v172.65h-65.51Z"
      />
      <rect
        className="sixth-cls-1"
        x="138.49"
        y="291.69"
        width="143.84"
        height="27.89"
      />
      <rect
        className="sixth-cls-1"
        x="138.49"
        y="136.81"
        width="107.21"
        height="27.89"
      />
      <circle className="sixth-cls-1" cx="210.41" cy="76.17" r="34.1" />
    </svg>
  );
};

export default InformationIcon;
