export const FRONT_PAGE = {
  title: "Home",
  corporateTitle: "Forside",
  to: `/dashboard`,
  visible: false,
};
export const TRIAL_PAGE = {
  title: "Trial Page",
  corporateTitle: "Trial Page",
  to: `/`,
  visible: false,
};
export const TOOLS_PAGE = {
  title: "DoLand Tools Page",
  corporateTitle: "DoLand Tools Page",
  to: `/tools`,
  visible: false,
};
export const DOMOBILIZE_PAGE = {
  title: "DoMobilize Page",
  corporateTitle: "DoMobilize Page",
  to: `/domobilize`,
  visible: false,
};
export const DOREPORT_PAGE = {
  title: "DoReport Page",
  corporateTitle: "DoReport Page",
  to: `/doreport`,
  visible: false,
};
export const MY_INVESTMENTS = {
  title: "Min investering",
  corporateTitle: "Investering",
  to: `/investering`,
  visible: true,
};
export const NEWS_PAGE = {
  title: "Nyheder",
  to: `/dashboard/nyheder`,
  visible: true,
};
export const NEWS_ARTICLE = {
  title: "Artikel",
  to: `/dashboard/nyheder`,
  visible: false,
};
export const ACCOUNT_PAGE = {
  title: "Konto",
  to: `/dashboard/konto`,
  visible: true,
};
export const RATING_PAGE = {
  title: "Mine temaer",
  corporateTitle: "Temaer",
  to: `/dashboard/temaer`,
  visible: true,
};
export const COMMUNITY_PAGE = {
  title: "Mine communities",
  corporateTitle: "Communities",
  to: `/community`,
  visible: true,
};
export const INVEST_MORE_PAGE = {
  title: "Invester mere",
  corporateTitle: "Invester mere",
  to: `/dashboard/godkend_investering`,
  visible: true,
};

export const REBALANCING_INTRO_PAGE = {
  title: "Rebalancering",
  corporateTitle: "Rebalancering",
  to: `/dashboard/rebalancering/intro`,
  visible: true,
};
export const REBALANCING_PAGE = {
  title: "Rebalancering",
  corporateTitle: "Rebalancering",
  to: `/dashboard/rebalancering`,
  visible: true,
};

export const PROFILE = {
  title: "Profil",
  corporateTitle: "Profil",
  to: `/profile/:section?`,
  visible: true,
};

export const MATTER_IMPACT = {
  title: "Min impact",
  corporateTitle: "Impact",
  to: `/impact`,
  visible: true,
};

export const DOMOBILIZE_INTRO_PAGE = {
  title: "Intro Page",
  to: `/`,
  visible: true,
};
export const DOMOBILIZE_VOTE_PAGE = {
  title: "Vote Page",
  to: `/votepage`,
  visible: true,
};
export const DOMOBILIZE_VOTE_BALANCE_PAGE = {
  title: "Vote Balance Page",
  to: `/votebalancepage`,
  visible: true,
};
export const LOGIN = {
  title: "Login",
  to: `/login`,
  visible: false,
};
export const SIGNUP = {
  title: "Signup",
  to: `/sign-up`,
  visible: false,
};

export const SIGNUP_TRIAL = {
  title: "Trial Sign Up",
  to: `/sign-up-trial`,
  visible: false,
};

// External links
export const casesUrl = "https://thisisdoland.com/da/business";
export const directLoginUrl =
  "https://join.thisisdoland.com/dashboard-sso?token=";
export const meetingUrl = "https://calendly.com/lisbeth-chawes";

export const ROUTES = [FRONT_PAGE, MY_INVESTMENTS, MATTER_IMPACT, RATING_PAGE];
/*export const ROUTES = [FRONT_PAGE]*/
