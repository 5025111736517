import { useEffect } from "react";
import { SpinnerFullHeight } from "../spinner/SpinnerFullHeight";
// import axios from "axios";
import { matterStore } from "../../store/MatterStore";
import { MatterAppItem } from "./MatterItem";
import { useAuthStore } from "../../hooks/useAuthStore";

export const Matter: React.FC = () => {
  const { getToken, getInputData, portfolio } = matterStore(state => ({
    getToken: state.getToken,
    getInputData: state.getInputData,
    portfolio: state.portfolio,
  }));

  const { hasTrialAccess } = useAuthStore();

  useEffect(() => {
    const fetchMatterData = async () => {
      await getToken();
      await getInputData(hasTrialAccess());
    };
    fetchMatterData();
  }, [hasTrialAccess]);

  if (!portfolio) {
    return <SpinnerFullHeight />;
  }

  return (
    <div className="row mt-4">
      <MatterAppItem portfolio={portfolio} type="co2" />
      <MatterAppItem portfolio={portfolio} type="energy" />
      <MatterAppItem portfolio={portfolio} type="fossil" />
    </div>
  );
};

export default MatterElements;
