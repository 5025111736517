import React from "react";
import {
  COLOR_GREEN,
  COLOR_LIGHT_RED,
  COLOR_RED,
  COLOR_TEAL,
} from "../../../config/constants";

const BORDER_RADIUS = 8;

type PillProps = {
  up: number; // pixels
  down: number; // pixels
  width?: number; // pixels
  selected?: boolean;
};

// width is increased in profile.css through a media-query
const Pill = ({
  up = 100,
  down = 50,
  width = 30,
  selected = false,
}: PillProps) => {
  if (up > 100) up = 100;
  if (up < 0) up = 0;

  if (down > 100) down = 100;
  if (down < 0) down = 0;

  return (
    <div
      className="position-relative risk-pill"
      style={{ width, margin: "0 auto" }}
    >
      <div
        style={{
          marginTop: 80 - up,
          height: up,
          background: selected ? COLOR_GREEN : COLOR_TEAL,
          borderTopLeftRadius: BORDER_RADIUS,
          borderTopRightRadius: BORDER_RADIUS,
          borderStyle: selected ? "none" : "dashed",
          borderColor: "#8A8A8A",
        }}
      />
      <div
        style={{
          marginTop: -2,
          height: down,
          background: selected ? COLOR_RED : COLOR_LIGHT_RED,
          borderBottomLeftRadius: BORDER_RADIUS,
          borderBottomRightRadius: BORDER_RADIUS,

          borderStyle: selected ? "none" : "dashed",
          borderColor: "#8A8A8A",
        }}
      />
    </div>
  );
};

type PercentProps = {
  n: number;
  top: number;
};
const Percent = ({ n, top }: PercentProps) => (
  <div
    className="p-small text-black fw-bold position-absolute"
    style={{
      top,
      right: 0,
    }}
  >
    {n > 0 ? "+" : ""}
    {n}%
  </div>
);

type RiskChartProps = {
  selectedValue?: number;
};
export const RiskChart = ({ selectedValue = -1 }: RiskChartProps) => {
  const options = [
    { up: 20, down: 15, value: 15 },
    { up: 40, down: 30, value: 30 },
    { up: 55, down: 40, value: 40 },
    { up: 70, down: 50, value: 50 },
  ];
  return (
    <div className="position-relative d-flex pb-4">
      {options.map(({ up, down, value }) => (
        <div key={value} className="flex-grow-1" style={{ marginRight: 17 }}>
          <Pill {...{ up, down }} selected={selectedValue === value} />
        </div>
      ))}

      <hr
        style={{
          position: "absolute",
          top: 66,
          borderTop: "solid 1px black",
          width: "calc(100% - 30px)",
          boxShadow: "1px 1px 0px gray",
        }}
      />
      <Percent n={75} top={0} />
      <Percent n={50} top={25} />
      <Percent n={25} top={50} />
      <Percent n={0} top={72} />
      <Percent n={-25} top={92} />
      <Percent n={-50} top={116} />
    </div>
  );
};
