//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
export const roundToOneDecimal = (
  num: number,
  isPct: boolean = false,
): string => {
  const roundedNum = Math.round(num * 100) / 100;

  if (isPct) {
    const myvalue = new Intl.NumberFormat("da-DK", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(roundedNum);

    return `${myvalue.toString()} %`;
  }

  const k = new Intl.NumberFormat("da-DK", {
    style: "currency",
    currency: "DKK",
    currencyDisplay: "code",
  }).format(roundedNum);

  return k;
};
