import { SpinnerFullHeight } from "../../components/spinner/SpinnerFullHeight";
import { COLOR_PRIMARY } from "../../config/constants";
import { useAuthStore } from "../../hooks/useAuthStore";
import DoReport1 from "../../assets/images/DoReport/DoReport1.png";
import DoReport2 from "../../assets/images/DoReport/DoReport2.png";
import DoReport3 from "../../assets/images/DoReport/DoReport3.png";
import DoReport4 from "../../assets/images/DoReport/DoReport4.png";
import { ButtonGreen } from "../../components/display/ButtonGreen";
import { PiDownloadSimple } from "react-icons/pi";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { meetingUrl } from "../../routes";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/20/solid";

const DoReportPage: React.FC = () => {
  const { user } = useAuthStore();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const largeScreen = useMediaQuery({ minWidth: 992 });
  const { t } = useTranslation();
  const minHeight = isMobile ? 500 : 600;
  const [activePicture, setActivePicture] = useState(0);
  const pictureSources = [DoReport1, DoReport2, DoReport3, DoReport4];
  const downloadPdf = () => {
    const link = document.createElement("a");
    link.href = `${process.env.PUBLIC_URL}/DoReportExample.pdf`;
    link.download = "DoReportSample.pdf";
    link.click();
  };
  const goToNextPicture = () => {
    if (activePicture < pictureSources.length - 1) {
      setActivePicture(activePicture + 1);
    }
  };

  const goToPreviousPicture = () => {
    if (activePicture > 0) {
      setActivePicture(activePicture - 1);
    }
  };

  return (
    <>
      <div className="container flex lg:h-full items-center">
        <div className={`row position-relative min-h-${minHeight}`}>
          <div className="col-12 col-lg-6 order-1 order-lg-0 position-relative">
            <div className="flex">
              <ChevronLeftIcon
                height={30}
                className={`m-auto ${
                  activePicture === 0
                    ? "text-gray-300"
                    : "cursor-pointer text-primary"
                }`}
                onClick={goToPreviousPicture}
              />
              <div className={`row w-4/5 lg:w-[400px] xl:w-[460px] mx-auto`}>
                <img
                  src={pictureSources[activePicture]}
                  alt=""
                  className="shadow-2xl"
                />
              </div>
              <ChevronRightIcon
                height={30}
                className={`m-auto ${
                  activePicture === pictureSources.length - 1
                    ? "text-gray-300"
                    : "cursor-pointer text-primary"
                }`}
                onClick={goToNextPicture}
              />
            </div>
            <div className=" h-5 w-5 absolute"></div>
            <div
              className="flex w-100 mt-2 z-10 justify-center gap-2 py-5"
              style={{ bottom: 0, zIndex: 1 }}
            >
              {pictureSources.map((_, index) => (
                <span
                  key={index}
                  className={`dot h-3 w-3 rounded-full cursor-pointer ${
                    activePicture === index
                      ? "active bg-primary"
                      : "bg-primary opacity-40"
                  }`}
                  onClick={() => setActivePicture(index)}
                ></span>
              ))}
            </div>
            <div
              className="h-100 d-none d-lg-block position-absolute"
              style={{ top: 0, right: 0, zIndex: 10, width: 1 }}
            >
              <div
                className="w-100 h-100"
                style={{ background: COLOR_PRIMARY }}
              ></div>
            </div>
          </div>

          <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
            {!user ? (
              <SpinnerFullHeight />
            ) : (
              <div
                className={`flex flex-col px-5 pb-5 gap-10 ${
                  !largeScreen ? "mt-6" : ""
                }`}
              >
                <h1 style={{ whiteSpace: "pre-line" }}>
                  {t("trial.doReport.header")}
                </h1>

                <p>{t("trial.doReport.description")}</p>
                <div className="flex gap-2">
                  <ButtonGreen filled url={meetingUrl}>
                    <p className="mb-0">{t("trial.meetingBtn")}</p>
                  </ButtonGreen>
                  <ButtonGreen white>
                    <p
                      className="mb-0 flex items-center gap-2"
                      onClick={downloadPdf}
                    >
                      <PiDownloadSimple />

                      {t("trial.doReport.dwlBtn")}
                    </p>
                  </ButtonGreen>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default DoReportPage;
