import Popup from "reactjs-popup";
import { COLOR_GRAY, COLOR_PRIMARY } from "../../config/constants";
import { CommunityInfoType } from "../../store/CommunitiesStore";
import { ChevronDown } from "react-bootstrap-icons";
import { COMMUNITY_PAGE } from "../../routes";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  communitiesInfo: CommunityInfoType[];
};

const CommunitySelector: React.FC<Props> = ({ communitiesInfo }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <div
      className="px-4 sm:mb-3 mt-4 md:mb-6 relative lg:absolute lg:top-8"
      style={{
        width: "100%",
        maxWidth: "534px",
      }}
    >
      <Popup
        onClose={() => setIsOpen(false)}
        open={isOpen}
        closeOnDocumentClick
        position="bottom left"
        contentStyle={{
          width: isMobile ? "29.5rem" : "31rem",
          backgroundColor: "white",
        }}
        trigger={
          <button
            className={"py-2 px-3 flex w-full"}
            style={{
              borderRadius: 14,
              display: "block",
              border: "1px solid",
              color: COLOR_PRIMARY,
              background: "white",
              borderColor: COLOR_PRIMARY,
            }}
            onClick={() => setIsOpen(true)}
          >
            <div className="flex items-center justify-between">
              {t("community.selector")}
              <ChevronDown className="ml-4" />
            </div>
          </button>
        }
      >
        <div className="flex flex-column py-2">
          {communitiesInfo.map(community => (
            <NavLink
              to={{ pathname: COMMUNITY_PAGE.to + "/" + community.id }}
              key={community.id}
              className="text-decoration-none py-3 px-4 my-2"
              style={({ isActive }) =>
                isActive
                  ? {
                      color: COLOR_PRIMARY,
                      borderColor: COLOR_PRIMARY,
                      background: "white",
                      border: "1px solid",
                      borderRadius: 14,
                    }
                  : {
                      borderRadius: 14,
                      display: "block",
                      border: "1px solid",
                      color: COLOR_GRAY,
                    }
              }
              onClick={() => setIsOpen(false)}
            >
              {community.title}
            </NavLink>
          ))}
        </div>
      </Popup>
    </div>
  );
};

export default CommunitySelector;
