import { InvestmentSummaryType } from "../../types/adjust-investments";

export type Subcategory = {
  title: string;
  key: string; // A1, B1, etc.
  percentage: number;
  amount: number;
  difference: number;
};

export type AllocationCategory = {
  key: string;
  category_id: number;
  category: string;
  totalPercentage: number;
  totalAmount: number;
  totalDifference: number;
  subcategories: Subcategory[];
};

export const getAllocationCategories = (data: InvestmentSummaryType) => {
  const categories: AllocationCategory[] = [];

  if (data?.product_info) {
    Object.keys(data.product_info).forEach(key => {
      //ie 'F1'
      const info = data.product_info[key]; // ie { category_id: 106, category: 'Gr\u00f8n omstilling', title: string }
      const allocation = data.allocation.items[key]; // ie { percentage, amount, difference }

      const category = categories.find(c => c.category_id === info.category_id);
      if (category) {
        category.subcategories.push({
          key,
          title: info.title,
          ...allocation,
        });
        category.totalAmount += allocation.amount;
        category.totalPercentage += allocation.percentage * 100;
        category.totalDifference += allocation.difference;
      } else {
        categories.push({
          key,
          ...info,
          totalPercentage: allocation.percentage * 100,
          totalAmount: allocation.amount,
          totalDifference: allocation.difference,
          subcategories: [
            {
              key,
              title: info.title,
              ...allocation,
            },
          ],
        });
      }
    });
  }

  return categories;
};
