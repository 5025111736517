import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { animated, useTransition } from "react-spring";

type Props = {
  children: React.ReactNode;
};

export const AnimatedRoute: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const state = location.state as null | { index: number };
  const oldIndex = useRef(0);

  const cur = state?.index || 0;
  const direction = cur < oldIndex.current ? 200 : -200; // 100 //index < oldIndex.current ? 200 : -200
  oldIndex.current = cur;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const transition = useTransition(location, {
    key: location.pathname,
    from: {
      opacity: 0,
      delay: 200,
      transform: `translate3d(${-direction}px,0px,0)`,
    },
    enter: {
      opacity: 1,
      delay: 200,
      transform: "translate3d(0px,0px,0)",
    },
    leave: {
      opacity: 1,
      transform: `translate3d(-${direction * 200}px, 0, 0)`,
    },
    config: { friction: 30, tension: 400 },
  });

  return transition((style: any) => {
    return (
      <animated.div
        className="position-absolute w-100 h-100"
        style={{
          paddingTop: "70px",
          zIndex: 0,
          top: 0,
          left: 0,
          ...(style as any),
        }}
      >
        {children}
      </animated.div>
    );
  });
};
