import React from "react";
import { X } from "react-bootstrap-icons";
import { useField } from "formik";
import { useProfileContext } from "../ProfileProvider";
import { COLOR_GRAY } from "../../../config/constants";

type CheckboxProps = {
  label: string;
  onClick: (ev: any, v: string) => void;
  selected: boolean;
};

const Checkbox = ({ label, onClick, selected = false }: CheckboxProps) => {
  return (
    <button
      name={label}
      className="d-flex mb-2 align-items-center"
      type="button"
      style={{
        border: 0,
        background: "transparent",
        padding: 0,
        cursor: "pointer",
        outline: "none",
      }}
      onClick={ev => onClick(ev, label)}
    >
      <div
        style={{
          background: "#F1EDDA",
          borderRadius: 3,
          width: 25,
          height: 25,
          marginRight: 10,
          position: "relative",
          cursor: "pointer",
        }}
      >
        {selected && (
          <div style={{ position: "absolute" }}>
            <X size={25} />
          </div>
        )}
      </div>
      <div
        className="text-black p-small fw-bold d-inline-block"
        id={label}
        style={{ cursor: "pointer" }}
      >
        {label}
      </div>
    </button>
  );
};

type Props = {
  name: string;
  label: string;
  values: string[];
  description?: string;
};

export const FormikCheckbox = ({
  name,
  label,
  values = [],
  description,
}: Props) => {
  const { setFormTouched, setSaved } = useProfileContext();
  const [field, meta, helpers] = useField(name);

  const onBlur = (e: React.FocusEvent<any>) => {
    field?.onBlur(e);
    setFormTouched?.(true);
    setSaved?.(false);
  };

  const onClick = (ev: any, value: string) => {
    helpers.setValue(value);
    onBlur(ev);
  };

  return (
    <div className="">
      <p className="mb-2 text-black fw-bold ">{label}</p>
      <div className="">
        {values.map(v => (
          <Checkbox
            key={v}
            label={v}
            selected={field.value === v}
            onClick={onClick}
          />
        ))}
      </div>
      {description && (
        <div className="mt-1">
          <p className="p-small" style={{ color: COLOR_GRAY }}>
            {description}
          </p>
        </div>
      )}
      {meta.error && meta.touched && (
        <div className="">
          <p className="p-small text-danger mt-2 mb-0">{meta.error}</p>
          {/*<hr className="mt-2 mb-1" />*/}
        </div>
      )}
    </div>
  );
};
