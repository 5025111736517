import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";

import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import "@react-pdf-viewer/core/lib/styles/index.css";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";

import Spinner from "../spinner/Spinner";
import { ApproveInvestmentButton } from "../adjust-investment/form/ApproveInvestmentButton";
import { Step } from "../adjust-investment/form/ApproveInvestment";
import { ApprovedType } from "../../context/InvestmentContext";
import { useMediaQuery } from "react-responsive";
import { t } from "i18next";

interface PDFViewerProps {
  step: Step;
  approved: ApprovedType | null;
  error: string;
  open: boolean;
  title: string;
  base64Data: string | null;
  isSendingApprovalOrder: boolean;
  onAccept: () => void;
  onClose: () => void;
}

export const PDFViewer: React.FC<PDFViewerProps> = ({
  step,
  open,
  approved,
  error,
  title,
  base64Data,
  isSendingApprovalOrder,
  onAccept,
  onClose,
}) => {
  const [agreed, setAgreed] = useState(false);
  const viewerContainerRef = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const base64 = `data:application/pdf;base64,${base64Data}`;
  const pdfContentType = "application/pdf";

  const base64toBlob = (data: string) => {
    const base64WithoutPrefix = data.substr(
      `data:${pdfContentType};base64,`.length,
    );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    const out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: pdfContentType });
  };

  const url = URL.createObjectURL(base64toBlob(base64));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative font-exa z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center sm:p-4 text-center sm:items-center font-tera">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative flex flex-col transform overflow-hidden sm:rounded-lg h-screen sm:h-[95vh] bg-white text-left shadow-xl transition-all w-full sm:max-w-4xl sm:p-6 font-sans`}
              >
                <div className="flex-none">
                  <div className="flex flex-row p-4 sm:p-0 justify-between sticky top-0 bg-white">
                    <Dialog.Title className="text-brown z-10 max-w-7xl text-sm font-serif font-bold tracking-tight sm:text-md md:text-lg">
                      {title}
                    </Dialog.Title>
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => onClose()}
                    >
                      <span className="sr-only">Close</span>
                      <CloseIcon className="flex self-center" />
                    </button>
                  </div>
                  {(step === Step.ErrorPDF || step === Step.ErrorApproval) && (
                    <div className="col-12 mt-4 mb-4">
                      {approved?.message ? (
                        <>
                          <div className="fw-bold text-center">
                            {approved?.message?.title}
                          </div>
                          {approved?.message?.text && (
                            <div
                              className="mt-4"
                              dangerouslySetInnerHTML={{
                                __html: approved?.message?.text,
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <div className="fw-bold text-center">{error}</div>
                      )}
                    </div>
                  )}
                  {step === Step.AgreementDisplay && (
                    <div className="flex flex-col flex-grow overflow-hidden">
                      <div
                        className="overflow-y-auto"
                        ref={viewerContainerRef}
                        style={{
                          height: isMobile
                            ? "calc(100vh - 210px)"
                            : "calc(100vh - 250px)",
                        }}
                      >
                        <Viewer
                          fileUrl={url}
                          renderLoader={() => <Spinner />}
                        />
                      </div>
                      <div className="flex-none col-12 flex flex-col gap-y-3 sm:flex-row justify-between container py-2 md:py-5">
                        <div className="flex flex-row items-center">
                          <div className="flex h-6 items-center">
                            <input
                              id="approve"
                              name="approve"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-teal focus:ring-teal"
                              onChange={() => setAgreed(!agreed)}
                              checked={agreed}
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label
                              onChange={() => setAgreed(!agreed)}
                              htmlFor="approve"
                              className="font-medium text-gray-900"
                            >
                              {t("myInvestment.acceptTerms")}
                            </label>
                          </div>
                        </div>

                        <ApproveInvestmentButton
                          onClick={onAccept}
                          disabled={!agreed || isSendingApprovalOrder}
                        >
                          {t("myInvestment.approveBtn")}
                        </ApproveInvestmentButton>
                      </div>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
