/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 07/06/2021.
 */
import { useTranslation } from "react-i18next";
import Spinner from "../../components/spinner/Spinner";
import {
  COLOR_INFO,
  COLOR_NEGATIVE_NUMBER,
  COLOR_PLACEHOLDER,
  IData,
} from "../../config/constants";
import { InvestmentResponse } from "../../service/InvestmentResponse";
import { roundToOneDecimal } from "../../utils/roundToOneDecimal";

type Props = {
  loading: boolean;
  data: InvestmentResponse | null;
  lookUpData: IData;
  isPct: boolean;
};

export const MyInvestmentsMobile = ({
  loading,
  lookUpData,
  data,
  isPct,
}: Props) => {
  const typeKey = isPct ? "return_pct" : "return_amount";

  const { t } = useTranslation();

  return (
    <>
      <div className="row">
        <div className="col-12 mt-6">
          <div className="row  ">
            <div className="col-7 ">
              <p className="mb-0 p-table-title fw-bold">
                {t("myInvestment.theme")}
              </p>
            </div>
            <div className="col-3 px-0">
              <p className="mb-0 p-table-title fw-bold">
                {t("myInvestment.currentAssets")}
              </p>
            </div>
            <div className="col-2 px-0">
              <p className="mb-0 p-table-title fw-bold">
                {t("myInvestment.periodReturns")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row ">
        {loading && !data && (
          <>
            <div className="col-12">
              <hr className="mb-0" />
            </div>
            <div className="col-12 mt-0 mb-6 position-relative">
              <div className="position-absolute w-100 d-flex justify-content-center align-items-center">
                <Spinner />
              </div>
            </div>
          </>
        )}
        {data &&
          Object.keys(data.result)
            .sort((a: any, b: any) => {
              return parseInt(data.result[a]._sort) >
                parseInt(data.result[b]._sort)
                ? 1
                : -1;
            })
            .map((key, index) => {
              const category = lookUpData[key];
              const item = data.result[key];

              // it seems that kontanter is missing
              if (!item) {
                return null;
              }

              return (
                <div
                  key={index}
                  className="col-12 my-2"
                  style={{ opacity: loading ? 0.2 : 1 }}
                >
                  <div className="row">
                    <div className="col-12 mb-3 ">
                      <hr />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-7 d-flex pr-0">
                      <img
                        style={{ width: 24, height: 24 }}
                        src={category.icon}
                        alt=""
                      />
                      <p className="mb-1 mt-1 ml-2 p-table-title fw-bold ">
                        {item.title}
                      </p>
                    </div>

                    <div className="col-4 px-0">
                      <p className="mb-1 fw-bold p-table-title  ">
                        {roundToOneDecimal(item.balance)}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-7 mt-3 d-flex ">
                      <div>
                        {Object.keys(item.assets).map(
                          (subKey: any, subIndex: number) => {
                            const subItem = item.assets[subKey];

                            return (
                              <div key={subIndex}>
                                <p
                                  className="mb-1"
                                  style={{ fontSize: ".7rem", opacity: 0.4 }}
                                >
                                  {subItem.title}
                                </p>
                              </div>
                            );
                          },
                        )}
                      </div>
                    </div>
                    <div className="col-3 mt-3 px-0">
                      <div>
                        {Object.keys(item.assets).map(
                          (subKey: any, subIndex: number) => {
                            const subItem = item.assets[subKey];

                            return (
                              <div key={subIndex}>
                                <p
                                  className="mb-1 "
                                  style={{ fontSize: ".7rem", opacity: 0.4 }}
                                >
                                  {roundToOneDecimal(subItem.balance_end)}
                                </p>
                              </div>
                            );
                          },
                        )}
                      </div>
                    </div>
                    <div className="col-2 mt-3 px-0 ">
                      <div>
                        {Object.keys(item.assets).map(
                          (subKey: any, subIndex: number) => {
                            const subItem = item.assets[subKey];
                            const isBelow =
                              subItem[typeKey] < 0
                                ? COLOR_NEGATIVE_NUMBER
                                : COLOR_INFO;
                            return (
                              <div key={subIndex}>
                                <p
                                  className="mb-1"
                                  style={{ fontSize: ".7rem", color: isBelow }}
                                >
                                  {roundToOneDecimal(subItem[typeKey], isPct)}
                                </p>
                              </div>
                            );
                          },
                        )}
                      </div>
                    </div>
                    <div className="col-12 mt-3 d-flex justify-content-between">
                      <p className="mb-1 p-table-title fw-bold ">
                        Tema fordeling
                      </p>
                      <p className="ml-3 p-table-title fw-bold">
                        {roundToOneDecimal(item.pct, true)}
                      </p>
                      <div
                        className="position-relative "
                        style={{ width: "50%", marginTop: 3 }}
                      >
                        <div
                          className="rounded-pill w-100"
                          style={{ height: 12, background: COLOR_PLACEHOLDER }}
                        ></div>
                        <div
                          className="position-absolute rounded-pill "
                          style={{
                            height: 12,
                            background: category.color,
                            top: 0,
                            left: 0,
                            width: `${item.pct}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </>
  );
};
