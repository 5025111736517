import { create } from "zustand";
import { MY_BASE_URL } from "../config/constants";
import { get as getRequest } from "../service/API";
export interface IBannerItem {
  url: string;
  banner: string;
}

export const bannerStore = create((set: any, get: any) => {
  return {
    bannerList: [],

    getBannerList: async () => {
      const cachedBannerList = get().bannerList;
      if (cachedBannerList.length > 0) {
        return cachedBannerList;
      }
      const bannerList = await getRequest(
        `${MY_BASE_URL}/dashboard/frontpage_banners`,
      );
      set({ bannerList });
      return bannerList;
    },
  };
});
