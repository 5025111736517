import { InvestmentForm } from "../../components/adjust-investment/form/InvestmentForm";
import { InvestmentProvider } from "../../context/InvestmentContext";

import { useTranslation } from "react-i18next";
import { ApprovedOverlay } from "../../components/adjust-investment/overlay/ApprovedOverlay";
import { AvailabilityOverlay } from "../../components/adjust-investment/overlay/AvailabilityOverlay";
import { InvestmentSummary } from "../../components/adjust-investment/summary/InvestmentSummary";
import { BASE_URL } from "../../config/constants";
import { useAuthStore } from "../../hooks/useAuthStore";

export const ThemeChangePage = () => {
  const { hasTrialAccess } = useAuthStore();

  const availabilityPath = `${BASE_URL}${
    hasTrialAccess() ? "/trial" : ""
  }/dashboard/investment/theme_change/available`;
  const { t } = useTranslation();

  return (
    <InvestmentProvider>
      <div className="container mt-6">
        <ApprovedOverlay />
        <AvailabilityOverlay availabilityPath={availabilityPath} />
        <div>
          <h2>{t("myThemes.header")}</h2>
          <p className="mt-3">{t("myThemes.description")}</p>
          <p className="mt-3">{t("myThemes.subtitle")}</p>
        </div>
        <InvestmentForm />
        <InvestmentSummary />
      </div>
    </InvestmentProvider>
  );
};
