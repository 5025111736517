import { Main } from "./Main";
// import { Login } from "./pages/login/Login";
// import { IntroPage } from "./pages/domobilize/introPage";
// import { VotePage } from "./pages/domobilize/votePage";
// import { Trial } from "./Trial";
// import { VoteBalancePage } from "./pages/domobilize/voteBalancePage";
// import LogRocket from "logrocket"; */
// import setupLogRocketReact from "logrocket-react";

function App() {
  // only initialize when in the browser
  /* if (typeof window !== "undefined") {
    LogRocket.init("7heypg/doland-dashboard");
    // plugins should also only be initialized when in the browser
    setupLogRocketReact(LogRocket);
  } */

  return <Main />;
  // return token ? <Main /> : <VotePage />;
  // return token ? <Main /> : <VoteBalancePage />;
}

export default App;
