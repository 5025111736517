/**
 * Created by @author Johan
 */
import React, { useEffect, useState } from "react";
import { ErrorComponent } from "../../components/ErrorComponent";
import { BASE, COLOR_PRIMARY } from "../../config/constants";
import { bannerStore, IBannerItem } from "../../store/BannerStore";

export const FrontpageBanners: React.FC = () => {
  const getBannerList = bannerStore(state => state.getBannerList);
  const bannerList = bannerStore(state => state.bannerList);
  const [error, setError] = useState<string>("");
  useEffect(() => {
    getBannerList().catch((error: any) => {
      console.log(" BannerList > error = ", error);
      setError(error);
    });
  }, [getBannerList]);

  if (error) {
    return <ErrorComponent error={error}></ErrorComponent>;
  }

  return (
    <>
      <div className="row position-relative mt-5">
        <div
          className="w-100 "
          style={{ borderTop: `1px solid ${COLOR_PRIMARY}` }}
        ></div>

        <div className="grid grid-cols-1 my-5 md:grid-cols-2 max-w-none gap-x-8">
          {bannerList.map((item: IBannerItem, index) => {
            return (
              <div className="p-4" key={index}>
                {item?.url ? (
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <img
                      className="w-100 "
                      style={{ borderRadius: 10 }}
                      src={BASE + item?.banner}
                      alt=""
                    />
                  </a>
                ) : (
                  <img
                    className="w-100 "
                    style={{ borderRadius: 10 }}
                    src={BASE + item?.banner}
                    alt=""
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
