import { Form, Formik, FormikProps } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button } from "../../../components/display/Button";
import Spinner from "../../../components/spinner/Spinner";
import { MY_BASE_URL } from "../../../config/constants";
import { get, post } from "../../../service/API";
import { FormikInput } from "../fields";
import { useProfileContext } from "../ProfileProvider";
import { PersonalInfoResponse } from "../types/PersonalInfoResponse";
import SaveSuccessMessage from "./SaveSuccessMessage";
import { useRebalancingCheck } from "../../../utils/navigation";
import { useLocation, useNavigate } from "react-router-dom";

type PersonalInfoForm = {
  firstname: string;
  lastname: string;
  email: string;
  city: string;
  country: string;
  street: string;
  street_no: string;
  zip: string;
  phone: string;

  password?: string;
  passwordConfirmation?: string;
};

const MyInfoSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(2, "Too short")
    .max(50, "Too long")
    .required("Required"),
  lastname: Yup.string()
    .min(2, "Too short")
    .max(50, "Too long")
    .required("Required"),
  street: Yup.string()
    .min(2, "Too short")
    .max(50, "Too long")
    .required("Required"),
  street_no: Yup.number().required("Required"),
  zip: Yup.string().min(4, "Too short").max(8, "Too long").required("Required"),
  city: Yup.string()
    .min(4, "Too short")
    .max(40, "Too long")
    .required("Required"),

  email: Yup.string().email("Invalid email").required("Required"),
  phone: Yup.string().matches(/^\+?[0-9]{8,12}$/, "Not valid"),

  password: Yup.string().min(6, "Too short").max(20, "Too long"),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), ""],
    "Passwords must match",
  ),
});

export const MyInfoSection = () => {
  const { saved, setSaved } = useProfileContext();
  const { savedError } = useProfileContext();
  const [initialValues, setInitialValues] = useState<PersonalInfoForm | null>(
    null,
  );

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    get(`${MY_BASE_URL}/dashboard/profile/personal`).then(
      (res: PersonalInfoResponse) => {
        setInitialValues({
          firstname: res.firstname,
          lastname: res.lastname,
          email: res.email,
          phone: `+45${res.phone.number}`,
          street: res.address.street,
          street_no: res.address.street_no,
          city: res.address.city,
          country: res.address.country,
          zip: res.address.zip,
        });
      },
    );
  }, []);

  const onSubmit = (values: PersonalInfoForm, { setSubmitting }: any) => {
    const data: PersonalInfoResponse = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      phone: {
        country: "+45",
        number: parseInt(
          values.phone.startsWith("+45")
            ? values.phone.replace("+45", "")
            : values.phone,
        ),
      },
      address: {
        street: values.street,
        street_no: values.street_no,
        zip: values.zip,
        city: values.city,
        country: values.country,
      },
    };

    if (values.password && values.passwordConfirmation) {
      data.password = values.password;
      data.password_confirmation = values.passwordConfirmation;
    }

    post(`${MY_BASE_URL}/dashboard/profile/personal`, data)
      .then(() => {
        setSubmitting(false);
        setSaved?.(true);
        useRebalancingCheck(location, navigate);
      })
      .catch((err: any) => {
        console.log(err);
        setSubmitting(false);
      });
  };

  return (
    <div>
      <div className={"text-primary mb-5"}>Mine informationer</div>
      {saved && !savedError && <SaveSuccessMessage simplified />}

      {initialValues == null && (
        <div className="col-12">
          <div className="my-6 d-flex justify-content-center">
            <Spinner />
          </div>
        </div>
      )}

      {initialValues != null && (
        <Formik
          {...{ initialValues, onSubmit }}
          validationSchema={MyInfoSchema}
        >
          {({ isSubmitting }: FormikProps<any>) => (
            <Form>
              <div className={"row"}>
                <div className={"col-lg-6"}>
                  <FormikInput name={"firstname"} label={"Fornavn"} />
                </div>
                <div className={"col-lg-6"}>
                  <FormikInput name={"lastname"} label={"Efternavn"} />
                </div>
              </div>

              <div className={"row mt-5"}>
                <div className={"col-lg-9"}>
                  <FormikInput name={"street"} label={"Vejnavn"} />
                </div>
                <div className={"col-lg-3"}>
                  <FormikInput name={"street_no"} label={"Husnummer"} />
                </div>
              </div>

              <div className="row mt-5">
                <div className={"col-lg-3"}>
                  <FormikInput name={"zip"} label={"Postnummer"} />
                </div>

                <div className="col-lg-9">
                  <FormikInput name="city" label="By" />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-lg-6">
                  <FormikInput name="country" label="Land" disabled />
                </div>
              </div>

              <hr style={{ borderTop: "solid 1px #28AA8C", marginTop: 40 }} />

              <div className={"row"}>
                <div className={"col-lg-6"}>
                  <FormikInput name={"email"} label={"E-mail"} />
                </div>
                <div className="col-lg-6">
                  <FormikInput name="phone" label="Telefonnummer" />
                </div>
              </div>

              <div className="row mt-5">
                <div className={"col-lg-6"}>
                  <FormikInput
                    name={"password"}
                    label={"Adgangskode"}
                    type={"password"}
                  />
                </div>
                <div className={"col-lg-6"}>
                  <FormikInput
                    name={"passwordConfirmation"}
                    label={"Gentag adgangskode"}
                    type={"password"}
                  />
                </div>
              </div>

              <div className="mt-6 mb-6 d-flex justify-content-center">
                <Button disabled={isSubmitting} filled submit>
                  <p className="mb-0">Opdater informationer</p>
                </Button>
                {isSubmitting && (
                  <div style={{ marginTop: -5, marginLeft: 40 }}>
                    <Spinner />
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
