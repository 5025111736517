/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 29-09-2020.
 */
import React from "react";

//  import { COLOR_PRIMARY } from "../../config/constants";

import { Link } from "react-router-dom";
import "../../components/pie-chart.scss";
import { NEWS_ARTICLE } from "../../routes";
import { INewsItem } from "../../store/NewsStore";
import { getTimestamp } from "../../utils/getDate";
import { ThemeItem } from "./ThemeItem";

type Props = {
  item: INewsItem;
};

export const NewsItem: React.FC<Props> = ({ item }) => {
  const content = item.content;
  const id = item.id;

  const timestamp = getTimestamp(item.timestamp);

  const img = item.content.image;

  return (
    <article className="p-4 mb-5">
      <div className="relative w-full">
        <img
          src={img}
          alt="news"
          className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover "
        />
        <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
      </div>
      <div className="max-w-xl">
        <div
          className="mt-8 flex items-center gap-x-4 text-xs"
          data-tooltip-id="news-item"
          data-tip=""
        >
          <time className="text-gray-500">{timestamp}</time>
          <div className="flex flex-row">
            {item.content.themes.map((item: any, index) => {
              return (
                <ThemeItem
                  key={index}
                  id={item.id}
                  themeName={content.themes[index].title}
                  index={index}
                  // setHover={setHovered}
                  useBackground={false}
                  size={25}
                  className="ml-2"
                ></ThemeItem>
              );
            })}
          </div>
        </div>
        <div className="group relative">
          <h3 className="mt-3 text-lg font-semibold  leading-6 text-teal group-hover:text-teal-600">
            <Link
              to={NEWS_ARTICLE.to + "/" + id}
              className="hover:no-underline"
            >
              <span className="absolute inset-0" />
              {content.title}
            </Link>
          </h3>
          <p className="mt-5 line-clamp-3 text-sm leading-6 text-brown">
            {content.teaser}
          </p>
        </div>
      </div>
    </article>
  );
};
