import React from "react";
import Spinner from "../spinner/Spinner";

const OverlayLoader: React.FC<{
  children: React.ReactNode;
  message?: string;
}> = ({ children, message }) => {
  return (
    <div
      className="fixed flex flex-col items-center justify-center w-screen left-0 right-0 top-0 bottom-0"
      style={{
        background: "rgba(255, 255, 255, 0.8)",
        zIndex: 1,
      }}
    >
      <Spinner />
      {message && <h6 className="mt-6 text-teal">{message}</h6>}
      {children}
    </div>
  );
};

export default OverlayLoader;
