import { authStore } from "../store/auth/authStore";

export const useAuthStore = () => {
  const {
    token,
    previewToken,
    redirectPath,
    roles,
    user,
    validateAndRedirect,
    resetRedirectPath,
    loginDoland,
    getDolandUser,
    loginTrial,
    validateTrialToken,
    login,
    logout,
    updateUser,
    hasTrialAccess,
    hasVoteAccess,
  } = authStore();

  return {
    token,
    previewToken,
    redirectPath,
    roles,
    user,
    validateAndRedirect,
    resetRedirectPath,
    loginDoland,
    getDolandUser,
    loginTrial,
    validateTrialToken,
    login,
    logout,
    updateUser,
    hasTrialAccess,
    hasVoteAccess,
  };
};
