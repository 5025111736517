import React from "react";
import Popup from "reactjs-popup";
import { Button } from "../../../components/display/Button";

type Props = {
  onCancel?: () => void;
  onYes?: () => void;
};

export const SectionChangeWarningPopup = ({ onCancel, onYes }: Props) => {
  return (
    <Popup
      open
      modal
      nested
      className="board-popup approve-investment-popup"
      position="bottom center"
    >
      <div className="container-fluid mt-3 px-5 py-4">
        <div className="row">
          <div className="col-12 d-flex justify-content-between">
            <h5>Er du sikker?</h5>
          </div>
        </div>
        <div className="row mb-4 mt-2">
          <div className="col-12">
            Dine ændringer er endnu ikke gemt. Hvis du går videre vil dine
            ændringer gå tabt.
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4 d-flex justify-content-between">
            <Button onClick={onYes}>
              <p className="mb-0 px-4">Gå videre uden at gemme</p>
            </Button>
            <Button onClick={onCancel} filled={true}>
              <p className="mb-0 px-4 ">Afbryd</p>
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  );
};
