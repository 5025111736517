/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 07/06/2021.
 */
import { useTranslation } from "react-i18next";
import Spinner from "../../components/spinner/Spinner";
import useInvestmentsStore from "../../store/MyInvestmentsStore";
import i18n from "../../utils/i18n";
import { roundToOneDecimal } from "../../utils/roundToOneDecimal";

const lookupItems = [
  {
    key: "doland",
    title: i18n.t("generic.doland"),
    byline: i18n.t("myInvestment.counseling"),
  },
  {
    key: "products",
    title: i18n.t("generic.products"),
    byline: i18n.t("myInvestment.productProviders"),
  },
  {
    key: "trading_custody",
    title: i18n.t("myInvestment.tradingAndStorageCosts"),
    byline: "",
    subs: [
      { key: "custody", title: i18n.t("myInvestment.custodyMutualFunds") },
      { key: "trading", title: i18n.t("myInvestment.tradingCosts") },
      { key: "fx", title: i18n.t("myInvestment.currencyExchange") },
    ],
  },
];

function getProperty<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

export const MyFees = () => {
  const { loading, selectedTimeSpan, feesData } = useInvestmentsStore(
    state => ({
      loading: state.loading,
      selectedTimeSpan: state.selectedTimeSpan,
      feesData: state.feesData,
    }),
  );
  const { t } = useTranslation();

  return (
    <>
      <div className="row mt-4">
        <div className="col-6 d-flex ">
          <p className="mb-0 p-table-title fw-bold">
            {t("myInvestment.costOverview")}
          </p>
        </div>

        <div className="col-3 d-flex ">
          <p className="mb-0 p-table-title fw-bold">{t("generic.percent")}</p>
        </div>
        <div className="col-3 d-flex justify-content-end ">
          <p className="mb-0 p-table-title fw-bold ">{t("generic.inDkk")}</p>
        </div>
      </div>

      {!loading && feesData[selectedTimeSpan] ? (
        <>
          {lookupItems.map((specObj, index) => {
            const item = getProperty(
              feesData[selectedTimeSpan].result,
              specObj.key as any,
            );

            return (
              <div key={index} className="row">
                <div className="col-12">
                  <hr />
                </div>

                <div className="col-6">
                  <p className="my-2 p-table-title fw-bold">{specObj.title}</p>
                  <p className="my-2 fw-bold">{specObj.byline}</p>
                </div>
                <div className="col-3">
                  <p className="mb-1 p-table-title fw-bold">
                    {roundToOneDecimal(item.sum.pct, true)}
                  </p>
                </div>
                <div className="col-3">
                  <p className="mb-1 p-table-title fw-bold text-right">
                    {roundToOneDecimal(item.sum.amount)}
                  </p>
                </div>

                {specObj &&
                  specObj.subs &&
                  specObj.subs.map((subItem, subIndex) => {
                    const subValue = item[subItem.key];

                    return (
                      <div className="col-12" key={subIndex}>
                        <div className="row">
                          <div className="col-6 mb-1">
                            <p
                              className="mb-0"
                              style={{ fontSize: ".7rem", opacity: 0.4 }}
                            >
                              {subItem.title}
                            </p>
                          </div>
                          <div className="col-3">
                            <p
                              className="mb-0"
                              style={{ fontSize: ".7rem", opacity: 0.4 }}
                            >
                              {roundToOneDecimal(subValue.pct, true)}
                            </p>
                          </div>
                          <div className="col-3">
                            <p
                              className="mb-0 text-right"
                              style={{ fontSize: ".7rem", opacity: 0.4 }}
                            >
                              {roundToOneDecimal(subValue.amount)}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          })}
          <div className="row">
            <div className="col-12">
              <hr />
            </div>
            <div className="col-6">
              <p className="p-table-title fw-bold">{t("generic.inAll")}</p>
            </div>
            <div className="col-3">
              <p className="p-table-title fw-bold">
                {roundToOneDecimal(
                  feesData[selectedTimeSpan].result.sum.pct,
                  true,
                )}
              </p>
            </div>
            <div className="col-3">
              <p className="p-table-title fw-bold text-right">
                {roundToOneDecimal(
                  feesData[selectedTimeSpan].result.sum.amount,
                )}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className="mt-2 text-muted" style={{ fontSize: ".7rem" }}>
                {t("myInvestment.explanation")}
              </p>
              <p className="text-muted" style={{ fontSize: ".7rem" }}>
                {t("myInvestment.support")}
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-12 my-6 d-flex justify-content-center">
            <Spinner />
          </div>
        </div>
      )}
    </>
  );
};
