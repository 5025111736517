/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 16/12/2020.
 */
import React, { ChangeEvent, CSSProperties, useEffect, useState } from "react";

type Props = {
  initialTxt: string;
  placeholder: string;
  inputChangeFunc?: (value: string) => void;
  style?: CSSProperties;
};

export const TextArea: React.FC<Props> = ({
  inputChangeFunc,
  initialTxt,
  style = {},
  placeholder,
}) => {
  const [txt, setTxt] = useState("");
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTxt(e.target.value);
    if (inputChangeFunc) {
      inputChangeFunc(e.target.value);
    }
  };

  useEffect(() => {
    setTxt(initialTxt);
  }, [initialTxt]);

  return (
    <textarea
      value={txt}
      onChange={handleChange}
      className="w-100 px-3 py-3"
      placeholder={placeholder}
      rows={6}
      cols={5}
      style={{
        //fontStyle: 'italic',
        color: "#4e4e4e",
        border: "none",
        outline: "none",
        borderRadius: 10,
        background: "#F1EDDA",
        ...style,
      }}
    />
  );
};
