import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useProfileContext } from "./ProfileProvider";
import { SectionChangeWarningPopup } from "./sections/SectionChangeWarningPopup";

export const ProfileMenu = () => {
  const { section } = useParams<any>();
  const navigate = useNavigate();
  const [nextSection, setNextSection] = useState("");
  const { formTouched, saved } = useProfileContext();

  const urlParams = new URLSearchParams(window.location.search);
  const hasRebalancingParam = urlParams.get("rebalancing") === "true";
  const rebalancingParam = hasRebalancingParam ? "?rebalancing=true" : "";

  useEffect(() => {
    if (!section) {
      navigate("/profile/info");
    }
  }, [section, navigate]);

  const menuItems = [
    {
      label: "Mine information",
      path: "info",
    },
    {
      label: "Risiko og formål",
      path: "risk",
    },
    {
      label: "Indtægtsinformation",
      path: "income",
    },
  ];

  const onClick = (_section: string) => (ev: any) => {
    ev.stopPropagation();
    if (formTouched && !saved) {
      setNextSection(_section);
    } else {
      navigate(`/profile/${_section}${rebalancingParam}`);
    }
  };

  const onCancel = () => {
    setNextSection("");
  };

  const onYes = () => {
    navigate(`/profile/${nextSection}${rebalancingParam}`);
    setNextSection("");
  };

  return (
    <div className={"pr-6 mr-6 profile-menu"}>
      {menuItems.map(item => (
        <div key={item.path} className={"mb-6"}>
          <span
            className={
              "text-primary pb-3 profile-menu-item" +
              (item.path === section ? " selected" : "")
            }
            onClick={onClick(item.path)}
          >
            {item.label}
          </span>
        </div>
      ))}
      {nextSection && <SectionChangeWarningPopup {...{ onYes, onCancel }} />}
    </div>
  );
};
