/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 24/09/2021.
 */
import { useCallback, useEffect, useReducer, useState } from "react";
import { useDebounce } from "../../../hooks/useDebounce";

import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../config/constants";
import {
  InvestmentPageType,
  useInvestmentContext,
} from "../../../context/InvestmentContext";
import { useAuthStore } from "../../../hooks/useAuthStore";
import { adjustInvestmentReducer } from "../../../reducers/adjustInvestmentReducer";
import { get, post } from "../../../service/API";
import { LoadingTypeEnum, useOverlayStore } from "../../../store/OverlayStore";
import { InvestmentRequestType } from "../../../types/adjust-investments";
import { pageTypeToURLSegment } from "../../../utils/adjustInvestment/pageTypeToURLSegment";
import { Button } from "../../display/Button";
import { InvestmentPieChart } from "../chart/InvestmentPieChart";
import { InvestmentThemeItem } from "./InvestmentThemeItem";

export const InvestmentForm = () => {
  const { hasTrialAccess } = useAuthStore();

  const [data, setData] = useState<Record<
    string,
    InvestmentRequestType
  > | null>(null);
  const [error, setError] = useState<any>(null);

  const { setLoading, clearLoading } = useOverlayStore();

  useEffect(() => {
    setLoading(true, LoadingTypeEnum.Overlay);

    get(
      `${BASE_URL}${
        hasTrialAccess() ? "/trial" : ""
      }/dashboard/user/profile/themes/rating`,
    )
      .then((res: any) => {
        setData(res);
      })
      .catch((err: any) => {
        setError(err);
        clearLoading();
      })
      .finally(() => clearLoading());
  }, [hasTrialAccess]);

  const {
    selectedThemes,
    setSelectedThemes,
    setCalculationsResult,
    investmentSummary,
    pageType,
    revealed,
  } = useInvestmentContext();
  const [items, dispatch] = useReducer(adjustInvestmentReducer, []);
  const itemsDebounced = useDebounce(items, 500);
  const [formChanged, setFormChanged] = useState(false);

  const initItems = useCallback(() => {
    if (data) {
      dispatch({
        type: "INIT",
        items: Object.values(data).sort((a, b) => a.weight - b.weight),
      });
    }
  }, [data]);

  useEffect(() => {
    if (items.length === 0) {
      initItems();
    }
  }, [items.length, initItems]);

  const onToggleClick = (theme_id: number) => () => {
    dispatch({ type: "TOGGLE_ITEM", theme_id });
    setFormChanged(true);
  };

  const onRatingChange = (theme_id: number) => (rating: number) => {
    dispatch({ type: "SET_ITEM_RATING", theme_id, rating });
    setFormChanged(true);
  };

  useEffect(() => {
    if (pageType !== InvestmentPageType.themeChange) {
      calculateRating();
      return;
    }

    const selectedThemes = items
      .filter(item => item.active)
      .map(item => ({ theme_id: item.theme_id, rating: item.rating }));
    setSelectedThemes?.(selectedThemes);
  }, [itemsDebounced]);

  const calculateRating = () => {
    if (!data || pageType === null) {
      return;
    }

    const selectedThemes = items
      .filter(item => item.active)
      .map(item => ({ theme_id: item.theme_id, rating: item.rating }));
    setSelectedThemes?.(selectedThemes);

    const urlSegment = pageTypeToURLSegment(pageType);

    setLoading(
      true,
      LoadingTypeEnum.Overlay,
      "Vent venligst mens vi beregner din nye fordeling",
    );
    post(
      `${BASE_URL}${
        hasTrialAccess() ? "/trial" : ""
      }/dashboard/investment/${urlSegment}/calculate`,
      {
        selectedThemes,
      },
    )
      .then((response: any) => {
        setCalculationsResult && setCalculationsResult(response, formChanged);
      })
      .catch((err: any) => {
        clearLoading();
        console.log(" Rating calculate > error = ", err);
      })
      .finally(() => {
        clearLoading();
      });

    setFormChanged(false);
  };

  const resetFormToInitialState = () => {
    initItems();
    setFormChanged(false);
  };

  const { t } = useTranslation();
  if (!revealed) return null;
  if (error) return <div>{t("generic.loadingFailed")}</div>;

  return (
    <div className="pb-4">
      <div className="row mt-6">
        <div className="col-12 col-lg-6">
          <div>
            {items
              .filter(
                (value: InvestmentRequestType) =>
                  !hasTrialAccess() ||
                  (hasTrialAccess() && value.theme_id !== 105),
              )
              .map((value: InvestmentRequestType) => (
                <InvestmentThemeItem
                  key={value.theme_id}
                  data={value}
                  onToggleClick={onToggleClick(value.theme_id)}
                  onSliderChange={onRatingChange(value.theme_id)}
                />
              ))}
          </div>
          {formChanged && (
            <div className="d-flex justify-content-start">
              <Button onClick={resetFormToInitialState}>
                <p className="mb-0">{t("investMore.reset")}</p>
              </Button>
            </div>
          )}
        </div>
        <div className="col-12 col-lg-6">
          {selectedThemes && <InvestmentPieChart />}
          {formChanged && (
            <div className="text-center mt-4" style={{ fontSize: "0.8em" }}>
              <p className="text-gray-600">{t("investMore.changes")}</p>
              {hasTrialAccess() ? (
                <Button disabled onClick={calculateRating}>
                  <p className="mb-0">{t("investMore.getNewValues")}</p>
                </Button>
              ) : (
                <Button onClick={calculateRating} filled>
                  <p className="mb-0">{t("investMore.getNewValues")}</p>
                </Button>
              )}
            </div>
          )}
          {investmentSummary?.info?.fee && !formChanged && (
            <div
              dangerouslySetInnerHTML={{ __html: investmentSummary.info.fee }}
              className={"text-center mt-4"}
              style={{ opacity: 0.6, fontSize: "0.8em" }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
