import React from "react";
import Spinner from "../spinner/Spinner";

const BlockingLoader: React.FC<{ message?: string }> = ({ message }) => {
  return (
    <div
      className="fixed flex items-center justify-center w-screen left-0 right-0 top-0 bottom-0"
      style={{
        background: "rgba(255, 255, 255)",
        zIndex: 1,
      }}
    >
      {/* <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-teal"></div> */}
      <Spinner />
      {message && <h6 className="mt-4">{message}</h6>}
    </div>
  );
};

export default BlockingLoader;
