import axios from "axios";
import { create } from "zustand";
import { MATTER_API_KEY, MY_BASE_URL } from "../config/constants";
import { get as getRequest } from "../service/API";

export const matterStore = create((set: any, get: any) => {
  return {
    portfolio: null,
    token: null,
    getInputData: async (hasTrialAccess: boolean) => {
      const cachedInputData = get().portfolio;
      if (cachedInputData) {
        return cachedInputData;
      }

      const inputData = await getRequest(
        `${MY_BASE_URL}${
          hasTrialAccess ? "/trial" : ""
        }/dashboard/matter/input_data`,
      );

      if (!inputData || inputData.length === 0) {
        throw new Error("no result");
      }

      const folio = inputData.map((item: any) => {
        return {
          id: item.id,
          type: "uid",
          allocation: {
            amount: item.amount,
            currency: item.currency,
          },
        };
      });
      set({ folio });
      set({ portfolio: { ids: folio } });

      return folio;
    },

    getToken: async () => {
      const cachedToken = get().token;
      if (cachedToken) {
        return cachedToken;
      }
      const token = (
        await axios.get(
          "https://api.thisismatter.com/elements/v1/auth/user_token",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${MATTER_API_KEY}`,
            },
          },
        )
      ).data.token;
      MatterElements.setAuthToken(token);

      set({ token });

      return token;
    },
  };
});
