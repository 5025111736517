/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 21-09-2020.
 */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";
import { ReactComponent as DownArrowIcon } from "../../assets/svg/arrow_bottom_down_icon.svg";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { COLOR_BROWN, COLOR_PRIMARY } from "../../config/constants";
import { useAuthStore } from "../../hooks/useAuthStore";
import {
  FRONT_PAGE,
  MATTER_IMPACT,
  MY_INVESTMENTS,
  RATING_PAGE,
  TRIAL_PAGE,
} from "../../routes";
import { communitiesStore } from "../../store/CommunitiesStore";
import { Desktop } from "../display/Desktop";
import { Mobile } from "../display/Mobile";
import Avatar from "../display/gravatar/Gravatar";
import { MobileNavigation } from "./MobileNavigation";
import "./profile-modal.scss";
import { NavItem } from "./NavigationItems";
import { generateDDNavItems, generateNavItems } from "./NavigationItems";

export enum ClientType {
  Personal = "personal",
  Business = "business",
  Trial = "trial",
  Vote = "vote",
  DoMobilize = "domobilize",
}

export const Navigation: React.FC = () => {
  const { t } = useTranslation();
  const [, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [communities, setCommunities] = useState<number[] | null>(null);
  const { logout, roles, hasTrialAccess, user, previewToken } = useAuthStore();

  const getCommunities = communitiesStore(state => state.getCommunities);
  const location = useLocation();

  const isPage = (page: string) =>
    hasTrialAccess()
      ? location.pathname === page
      : location.pathname === TRIAL_PAGE.to;

  const isFrontPage = isPage(FRONT_PAGE.to);
  const isInvestPage = isPage(MY_INVESTMENTS.to);
  const isImpactPage = isPage(MATTER_IMPACT.to);
  const isRatingPage = isPage(RATING_PAGE.to);
  const isTrialPage = isPage(TRIAL_PAGE.to);

  if (!roles) return null;

  useEffect(() => {
    setLoading(true);
    if (
      (roles.includes(ClientType.Personal) ||
        roles.includes(ClientType.Business)) &&
      !roles.includes(ClientType.Trial)
    ) {
      getCommunities()
        .then((res: any) => {
          setCommunities(res);
        })
        .catch((err: any) => {
          const status = err.status ? "status " + err.status : "";
          setCommunities(null);
          setError(t("error.communityError") + status);
        })
        .finally(() => setLoading(false));
    } else {
      setCommunities(null);
      setLoading(false);
    }
  }, [hasTrialAccess]);

  const dropDownNavItems: NavItem[] = generateDDNavItems({
    roles,
    trialAccess: hasTrialAccess(),
    inDoInvestPath: isFrontPage || isInvestPage || isImpactPage || isRatingPage,
    previewToken: previewToken,
  });

  const navItems: NavItem[] = generateNavItems({
    roles,
    trialAccess: hasTrialAccess(),
    communities,
    inDoInvestPath: isFrontPage || isInvestPage || isImpactPage || isRatingPage,
  });

  const logoutClick = () => {
    logout();
  };

  if (loading) return null;

  return (
    <>
      <div
        className="container-fluid position-relative bg-white "
        style={{ zIndex: 2 }}
      >
        <div
          className="row "
          style={{ boxShadow: "0px 4px 15px rgba(0,0,0,.1)" }}
        >
          <div
            className="col-12 d-flex justify-content-between"
            style={{ height: 70 }}
          >
            <NavLink
              to={hasTrialAccess() ? TRIAL_PAGE.to : FRONT_PAGE.to}
              className="mr-8 lg:mr-12 ml-3 self-center"
            >
              <Logo
                className="mb-1"
                style={{ height: "1.2rem" }}
                fill={
                  (hasTrialAccess() && isTrialPage) ||
                  (!hasTrialAccess() && location.pathname === FRONT_PAGE.to)
                    ? COLOR_PRIMARY
                    : COLOR_BROWN
                }
              />
            </NavLink>
            <Desktop>
              <div className="flex flex-row mr-auto self-center">
                {navItems
                  .filter(navItem => navItem.displayIf)
                  .map((navItem, index) => (
                    <NavLink
                      className="mr-6 lg:mr-12 text-sm lg:text-base text-decoration-none"
                      key={index}
                      to={navItem.internalLink}
                      target={navItem.target ? "_blank" : ""}
                      rel={navItem.target ? "noreferrer" : ""}
                      style={({ isActive }) =>
                        isActive
                          ? { color: COLOR_PRIMARY }
                          : { color: COLOR_BROWN }
                      }
                    >
                      {navItem.title}
                    </NavLink>
                  ))}
              </div>

              <div className="d-none d-md-flex align-items-center">
                {user && (
                  <div style={{ position: "relative" }}>
                    <Popup
                      closeOnDocumentClick
                      trigger={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span className="pointer mb-0 mr-3 min-w-max">
                            <Avatar />
                          </span>
                          <span
                            className=" hidden xl:block pointer mb-0 mr-1 text-black noselect p-small"
                            style={{ color: COLOR_BROWN, fontSize: "0.8rem" }}
                          >
                            {hasTrialAccess() ? user.name : `${user.firstname}`}
                          </span>
                          <span
                            className="pointer mb-0 mr-5 text-black noselect p-small"
                            style={{ color: COLOR_BROWN, fontSize: "0.8rem" }}
                          >
                            <DownArrowIcon style={{ height: 30, width: 30 }} />
                          </span>
                        </div>
                      }
                    >
                      <div className="menu">
                        {dropDownNavItems
                          .filter(navItem => navItem.displayIf)
                          .map((navItem, index) => (
                            <NavLink
                              key={index}
                              className="text-decoration-none menu-item"
                              to={navItem.internalLink}
                              target={navItem.target ? "_blank" : ""}
                              rel={navItem.target ? "noreferrer" : ""}
                            >
                              <p>{navItem.title}</p>
                            </NavLink>
                          ))}
                        <p onClick={logoutClick} className="menu-item">
                          <span>{t("generic.logOut")}</span>
                        </p>
                      </div>
                    </Popup>
                  </div>
                )}
              </div>
            </Desktop>
            <Mobile>
              <>
                <MobileNavigation
                  navItems={navItems}
                  dropDownNavItems={dropDownNavItems}
                />
              </>
            </Mobile>
          </div>
        </div>
      </div>
    </>
  );
};
