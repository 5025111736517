import React, { useRef } from "react";
import { animated, useTransition, useSpring } from "react-spring";

import useMeasure from "react-use-measure";

type Props = {
  children: React.ReactElement[];
  pageIndex: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  endHandler?: Function;
};

export const WizardWrapper: React.FC<Props> = ({ pageIndex, children }) => {
  //const activePage = React.Children.toArray(children)[pageIndex]

  const oldIndex = useRef(0);
  const direction = oldIndex.current > pageIndex ? 100 : -100;
  oldIndex.current = pageIndex;
  const [ref, bounds] = useMeasure({
    debounce: 200,
  });

  const { height } = useSpring({
    from: { height: 300, opacity: 0 },
    to: {
      height: bounds.height ? bounds.height : 0,
      opacity: 1,
    },
    config: { mass: 1, tension: 400, friction: 35 },
  });

  const item: any = children[pageIndex];
  const transition = useTransition(item, {
    from: { opacity: 0, transform: `translate3d(${-direction}px,0,0)` },
    enter: { opacity: 1, transform: "translate3d(0,0px,0)" },
    leave: {
      opacity: 0,
      transform: `translate3d(${direction}px,0,0)`,
    },
    config: { mass: 1, tension: 400, friction: 35 },
  });

  return (
    <animated.div
      className="w-100 overflow-hidden"
      style={{
        height: height as any,
        background: "white",
        position: "relative",
      }}
    >
      {transition((style, item) => {
        return (
          <animated.div
            ref={ref}
            className="position-absolute w-100 "
            style={{ top: 0, left: 0, ...(style as any) }}
          >
            {item}
          </animated.div>
        );
      })}
    </animated.div>
  );
};
