import { create } from "zustand";
import { MY_BASE_URL } from "../config/constants";
import { get as getRequest } from "../service/API";

export interface INewsItem {
  id: number;
  type: string;
  timestamp: Date;
  content: {
    image: string;
    title: string;
    teaser: string;
    themes: { id: number; title: string }[];
  };
}

export interface INewsArticle {
  id: number;
  type: string;
  timestamp: Date;
  content: {
    images: string[];
    title: string;
    body: string;
    themes: { id: number; title: string }[];
    sources: string;
  };
}

export interface PatientStoreType {
  newsList: INewsItem[];
  newsArticles: object;
  getNewsList: () => Promise<INewsItem[]>;
  getNewsArticle: () => Promise<INewsArticle>;
}

export const newsStore = create((set: any, get: any) => {
  return {
    newsList: [],
    newsArticles: {},

    getNewsList: async () => {
      const cachedNewsList = get().newsList;
      if (cachedNewsList.length > 0) {
        return cachedNewsList;
      }
      const newsList = await getRequest(
        `${MY_BASE_URL}/dashboard/investment/news/list`,
      );
      set({ newsList });
      return newsList;
    },

    getNewsArticle: async (id: string) => {
      const article = await getRequest(
        `${MY_BASE_URL}/dashboard/investment/news/get/${id}`,
      );
      set({ newsArticles: { id: article } });
      return article;
    },
  };
});
