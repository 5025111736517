import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { PieChartWrapper } from "../../components/PieChartWrapper";
import { InvestMoreModal } from "../../components/invest-more-modal/InvestMoreModal";
import { InviteFriendModal } from "../../components/invite-friend/InviteFriendModal";
import { Matter } from "../../components/matter/Matter";
import { SpinnerFullHeight } from "../../components/spinner/SpinnerFullHeight";
import { COLOR_PRIMARY } from "../../config/constants";
import { communitiesStore } from "../../store/CommunitiesStore";
import CommunitySelector from "./CommunitySelector";
import { ClientType } from "../../components/navigation/Navigation";
import { useAuthStore } from "../../hooks/useAuthStore";

const CommunityPage: React.FC = () => {
  const { t } = useTranslation();
  const { hasTrialAccess, roles } = useAuthStore();

  const { communityId } = useParams<{ communityId: string }>();

  const [, setError] = useState<string>("");
  const [communityDistribution, setCommunityDistribution] = useState<
    any | null
  >(null);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const h = 700;
  const minHeight = isMobile ? 500 : 600;

  const { getCommunityDistribution, communitiesInfo } = communitiesStore(
    state => ({
      getCommunityDistribution: state.getCommunityDistribution,
      communitiesInfo: state.communitiesInfo,
    }),
  );

  const getCommunityName = () => {
    const community = communitiesInfo.find(
      community => community?.id === Number(communityId),
    );
    return community ? community.title : t("community.unknownCommunity");
  };

  useEffect(() => {
    if (!communityId) return;
    getCommunityDistribution(+communityId)
      .then(res => {
        setCommunityDistribution(res);
      })
      .catch(err => {
        const status = err.status ? "status " + err.status : "";
        setError(t("error.communityError") + status);
      });
  }, [communityId, communitiesInfo]);

  if (!communityDistribution) {
    return <SpinnerFullHeight />;
  }

  if (!roles) return null;

  return (
    <>
      <div className="container">
        <div className="row position-relative" style={{ minHeight: minHeight }}>
          <div className="col-12 col-lg-6 order-1 order-lg-0 position-relative">
            <div
              className="row mt-2 mt-md-5"
              style={{ minHeight: minHeight, height: h }}
            >
              <PieChartWrapper
                pieChartData={communityDistribution}
                title="Samlet investering"
              />
            </div>
            <div
              className="h-100 d-none d-lg-block position-absolute pt-6 "
              style={{ top: 0, right: 0, zIndex: 10, width: 1 }}
            >
              <div
                className="w-100 h-100 "
                style={{ background: COLOR_PRIMARY }}
              ></div>
            </div>
          </div>
          <div className="col-12 col-lg-6 flex flex-column justify-center md:mt-6 lg:mt-6">
            <CommunitySelector communitiesInfo={communitiesInfo} />
            <div
              className="px-5 pb-5 pb-lg-0"
              style={{
                borderBottom: `1px solid ${isMobile ? COLOR_PRIMARY : "white"}`,
              }}
            >
              <h1 style={{ whiteSpace: "pre-line" }}>
                <Trans
                  i18nKey={"community.welcome"}
                  values={{ communityName: getCommunityName() }}
                >
                  {" "}
                  <span className="text-primary"></span>
                </Trans>
              </h1>
              <p style={{ whiteSpace: "pre-line" }}>
                {t("community.investingInfo")} {getCommunityName()}.
              </p>

              <InvestMoreModal />
              {!hasTrialAccess && roles.includes(ClientType.Personal) && (
                <InviteFriendModal />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-6">
        <div className="row">
          <div className="col-12 my-3">
            <h2>{t("matterApp.communityHeader")}</h2>
          </div>
        </div>
        <Matter />
        <div className="col-12 mt-5">
          <p>
            <b className="text-uppercase">{t("matterApp.explanationHeader")}</b>
          </p>
          <p>{t("matterApp.communityExplanationDescription")}</p>
        </div>
      </div>
    </>
  );
};

export default CommunityPage;
