import { ErrorMessage } from "formik";

const FormikErrorMessage: React.FC<{ name: string }> = ({ name }) => {
  return (
    <ErrorMessage name={name} component="div">
      {(msg: any) => {
        try {
          const parsedMsg = JSON.parse(msg);
          if (parsedMsg.key) {
            return <p className="text-red text-sm mt-2">{parsedMsg.key}</p>;
          }
        } catch (error) {
          // Not a JSON string, fallback to the original error message
          return <p className="text-red text-sm mt-2">{msg}</p>;
        }
      }}
    </ErrorMessage>
  );
};

export default FormikErrorMessage;
