/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/06/2021.
 */
import { animated, useSpring } from "react-spring";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import usePortal from "react-useportal";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import { NavItem } from "./NavigationItems";
import { useAuthStore } from "../../hooks/useAuthStore";
import { useEffect, useState } from "react";
import { communitiesStore } from "../../store/CommunitiesStore";
interface MobileNavigationProps {
  dropDownNavItems: NavItem[];
  navItems: NavItem[];
}
export const MobileNavigation: React.FC<MobileNavigationProps> = ({
  dropDownNavItems,
  navItems,
}) => {
  const { hasTrialAccess, logout } = useAuthStore();
  const { t } = useTranslation();
  const logoutClick = () => {
    logout();
  };
  const [, setError] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const getCommunities = communitiesStore(state => state.getCommunities);
  const [, setCommunities] = useState<number[] | null>(null);

  const styles = useSpring({
    to: async next => {
      await next({ opacity: isOpen ? 1 : 0, color: "#ffaaee" });
    },
    from: { opacity: 0 },
  });
  const contentStyles = useSpring({
    to: async next => {
      await next({
        transform: isOpen
          ? "translate3d(0px,0px,0px)"
          : "translate3d(250px,0px,0px)",
      });
    },
    from: { transform: "translate3d(300px,0px,0px)" },
    config: { mass: 1, tension: 280, friction: 30 },
    delay: 100,
  });

  const { openPortal, closePortal, isOpen, Portal } = usePortal();
  useEffect(() => {
    setLoading(true);
    if (!hasTrialAccess()) {
      getCommunities()
        .then((res: any) => {
          setCommunities(res);
        })
        .catch((err: any) => {
          const status = err.status ? "status " + err.status : "";
          setError(t("error.communityError") + status);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [hasTrialAccess]);
  if (loading) return null;
  return (
    <>
      <div onClick={openPortal} className="mt-5" style={{ width: 40 }}>
        <div
          className="bg-black w-100 mb-2"
          style={{ background: "black", height: 3 }}
        ></div>
        <div
          className="bg-black w-100 mb-2"
          style={{ background: "black", height: 3 }}
        ></div>
        <div
          className="bg-black w-100"
          style={{ background: "black", height: 3 }}
        ></div>
      </div>
      {isOpen && (
        <Portal>
          <animated.div
            className="container-fluid position-fixed w-100 vh-100"
            style={{ zIndex: 2000, top: 0, left: 0 }}
          >
            <div className="row h-100 position-relative">
              <div
                onClick={closePortal}
                className="position-absolute h-100 w-100"
                style={{ background: "rgba(0,0,0,.2)", ...(styles as any) }}
              ></div>

              <div className="col-12 h-100 px-0 d-flex justify-content-end ">
                <animated.div
                  className="bg-white h-100 "
                  style={{ width: 250, ...contentStyles }}
                >
                  <div className="w-100 d-flex justify-content-end mt-4 pr-4">
                    <CloseIcon
                      onClick={closePortal}
                      style={{ fill: "black", width: 30, height: 30 }}
                    ></CloseIcon>
                  </div>

                  <div className="h-75 px-4 d-flex flex-column justify-content-center">
                    {navItems
                      .filter(navItem => navItem.displayIf)
                      .map((navItem, index) => (
                        <NavLink
                          key={index}
                          onClick={closePortal}
                          className="text-decoration-none"
                          to={navItem.internalLink}
                          target={navItem.target ? "_blank" : ""}
                          rel={navItem.target ? "noreferrer" : ""}
                          state={{ index }}
                        >
                          <p
                            className="pointer mb-4 text-primary noselect"
                            style={{ fontSize: "1rem" }}
                          >
                            {navItem.title}
                          </p>
                        </NavLink>
                      ))}
                    {dropDownNavItems
                      .filter(navItem => navItem.displayIf)
                      .map((navItem, index) => (
                        <NavLink
                          key={index}
                          onClick={closePortal}
                          className="text-decoration-none"
                          to={navItem.internalLink}
                          target={navItem.target ? "_blank" : ""}
                          rel={navItem.target ? "noreferrer" : ""}
                        >
                          <p
                            className="pointer mb-4 text-primary noselect"
                            style={{ fontSize: "1rem" }}
                          >
                            {navItem.title}
                          </p>
                        </NavLink>
                      ))}
                    <p
                      onClick={logoutClick}
                      className="pointer mb-0 mr-2 text-primary noselect"
                      style={{ fontSize: "1rem" }}
                    >
                      {t("generic.logOut")}
                    </p>
                  </div>
                </animated.div>
              </div>
            </div>
          </animated.div>
        </Portal>
      )}
    </>
  );
};
