interface SubmitButtonProps {
  label: string;
  isSubmitting: boolean;
  className?: string;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  label,
  isSubmitting,
  className = "",
}) => {
  return (
    <button
      type="submit"
      disabled={isSubmitting}
      className={`flex w-full justify-center rounded-md bg-teal py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 ${className}`}
    >
      {isSubmitting ? (
        <div className="animate-spin rounded-full h-5 w-5 border-t-4 border-white pl-6"></div>
      ) : (
        label
      )}
    </button>
  );
};

export default SubmitButton;
