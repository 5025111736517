import Slider from "rc-slider";
import { Toggle } from "../../components/form/Toggle";
import { COLOR_PRIMARY } from "../../config/constants";
import { InvestmentRequestType } from "../../types/adjust-investments";
import { Modal } from "./modal";
import { useState } from "react";
import { InfoCircle } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

type ThemeBalanceItemProps = {
  data: InvestmentRequestType;
  itemColor: string;
  onToggleClick: () => void;
  onSliderChange: (rating: number) => void;
};

const defaultToggleOptions = {
  pillWidth: 30,
  switchWidth: 50,
  switchHeight: 23,
};

export const ThemeBalanceItem = ({
  data,
  itemColor,
  onSliderChange,
  onToggleClick,
}: ThemeBalanceItemProps) => {
  const { t } = useTranslation();
  const toggleOptions = {
    ...defaultToggleOptions,
    activeColor: itemColor,
  };
  const min = 10;
  const max = 100;
  const handleSliderChange = (newValue: number | number[]) => {
    if (typeof newValue === "number") {
      onSliderChange(newValue);
    }
  };
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(!open);
  };
  return (
    <div className="pb-4">
      <div className="flex">
        <div className="ml-2">
          <Toggle
            onClick={onToggleClick}
            active={data.active}
            options={toggleOptions}
          />
        </div>
        <div className="ml-3 flex-grow-1 d-flex align-items-center">
          <p className="mb-2 mr-3">{data.title}</p>
          <button
            onClick={openModal}
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: 0,
              marginTop: -4,
            }}
            className="d-flex align-items-center d-inline"
          >
            <InfoCircle color={"gray"} size={14} />
          </button>
          <Modal
            open={open}
            theme_id={data.theme_id}
            onClose={closeModal}
            isButton={false}
          />
        </div>
      </div>
      {data.active && (
        <div className="row" style={{ marginTop: -7 }}>
          <div className="col-12">
            <div
              style={{ marginLeft: 62, marginRight: 0 }}
              className="position-relative"
            >
              <div
                className="position-absolute w-100 d-flex"
                style={{ marginTop: 9 }}
              >
                <p
                  className="ml-2 flex-grow-1"
                  style={{ fontSize: ".5rem", opacity: 0.4 }}
                >
                  {t("generic.little")}
                </p>
                <p className="mr-2" style={{ fontSize: ".5rem", opacity: 0.4 }}>
                  {t("generic.much")}
                </p>
              </div>
              <div className="px-2 position-relative">
                <Slider
                  min={min}
                  max={max}
                  value={data.rating}
                  onChange={handleSliderChange}
                  trackStyle={{
                    backgroundColor: data.active ? COLOR_PRIMARY : "#ccc",
                  }}
                  handleStyle={{
                    borderColor: data.active ? COLOR_PRIMARY : "transparent",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
