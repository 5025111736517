/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 26/09/2021.
 */

import React, { useState } from "react";
import Popup from "reactjs-popup";
import { InfoCircle } from "react-bootstrap-icons";
import "./styles/theme-info-modal.scss";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import { lookUpData } from "../../config/constants";
import { Trans } from "react-i18next";

type ContentProps = {
  theme_id: number;
};

const Content = ({ theme_id }: ContentProps) => {
  if (theme_id === 0) {
    return <div></div>;
  } else if (theme_id === 1) {
    return <div></div>;
  } else if (theme_id === 101) {
    return (
      <div>
        <p>
          <Trans i18nKey={"investmentThemes.description.renewableEnergy"} />
        </p>
      </div>
    );
  } else if (theme_id === 102) {
    return (
      <div>
        <p>
          <Trans
            i18nKey={"investmentThemes.description.innovativeDiseaseControl"}
          />
        </p>
      </div>
    );
  } else if (theme_id === 103) {
    return (
      <div>
        <p>
          <Trans i18nKey={"investmentThemes.description.electricCars"} />
        </p>
      </div>
    );
  } else if (theme_id === 104) {
    return (
      <div>
        <p>
          <Trans i18nKey={"investmentThemes.description.equality"} />
        </p>
      </div>
    );
  } else if (theme_id === 105) {
    return (
      <div>
        <p>
          <Trans i18nKey={"investmentThemes.description.health"} />
        </p>
      </div>
    );
  } else if (theme_id === 106) {
    return (
      <div>
        <p>
          <Trans i18nKey={"investmentThemes.description.greenTransition"} />
        </p>
      </div>
    );
  } else if (theme_id === 107) {
    return (
      <div>
        <p>
          <Trans
            i18nKey={"investmentThemes.description.sustainableInfrastructure"}
          />
        </p>
      </div>
    );
  } else if (theme_id === 108) {
    return (
      <div>
        <p>
          <Trans i18nKey={"investmentThemes.description.socialImpact"} />
        </p>
      </div>
    );
  } else if (theme_id === 109) {
    return (
      <div>
        <p>
          <Trans i18nKey={"investmentThemes.description.waterResources"} />
        </p>
      </div>
    );
  } else if (theme_id === 110) {
    return (
      <div>
        <p>
          <Trans i18nKey={"investmentThemes.description.sustainableFoods"} />
        </p>
      </div>
    );
  }

  return null;
};

type Props = {
  theme_id: number;
};

export const ThemeInfoModal: React.FC<Props> = ({ theme_id }: Props) => {
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(!open);
  };

  return (
    <>
      <button
        onClick={openModal}
        style={{
          backgroundColor: "transparent",
          border: "none",
          outline: 0,
          marginTop: -4,
        }}
        className="d-flex align-items-center d-inline"
      >
        <InfoCircle color={"gray"} size={14} />
      </button>

      <Popup
        onClose={closeModal}
        open={open}
        modal
        nested
        className="rating-info-popup"
        position="top center"
        /*closeOnDocumentClick*/
      >
        <div className="container-fluid mh-100 mb-3 mt-3 px-5 py-4 relative">
          <div
            onClick={closeModal}
            className="rounded-full pointer absolute right-0 top-0"
            style={{
              width: 25,
              height: 25,
              background: "rgba(226,226,226,.5)",
              top: 0,
              right: 10,
              zIndex: 20,
            }}
          >
            <CloseIcon className="absolute left-1.5 top-1.5" />
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              <h3>{lookUpData[theme_id].title}</h3>
              <Content theme_id={theme_id} />
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};
