import { ButtonGreen } from "../../display/ButtonGreen";
import React from "react";
interface ApproveInvestmentButtonProps {
  onClick: () => void;
  disabled?: boolean;
  children: any;
}

export const ApproveInvestmentButton = ({
  onClick,
  disabled = false,
  children,
}: ApproveInvestmentButtonProps) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <div className="py-2 d-flex align-items-center justify-content-center">
      <ButtonGreen onClick={handleClick} disabled={disabled} margin="">
        <p
          className="p-button mb-0"
          style={{ letterSpacing: -1, fontSize: "15px" }}
        >
          {children}
        </p>
      </ButtonGreen>
    </div>
  );
};
