import { FC, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import { Lexend_Exa, Lexend_Tera } from "next/font/google";
import { XMarkIcon } from "@heroicons/react/20/solid";
import InformationIcon from "../icons/InformationIcon";

// const exa = Lexend_Exa({
//   variable: "--font-exa",
//   subsets: ["latin"],
//   display: "swap",
// });
// const tera = Lexend_Tera({
//   variable: "--font-tera",
//   subsets: ["latin"],
//   display: "swap",
// });

type ModalProps = {
  open: boolean;
  title: string;
  content?: React.ReactNode[] | null;
  stringContent?: string | null;
  onClose: () => void;
};

export const InformationModal: FC<ModalProps> = ({
  open,
  title,
  content,
  stringContent,
  onClose,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 font-sans`}
              >
                <div className="absolute right-0 top-0 pr-4 pt-4 block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-lightYellow sm:mx-0 sm:h-10 sm:w-10">
                    <InformationIcon className="h-8 w-8" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-brown"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      {content && (
                        <div className="font-exa space-y-4">
                          {content.map((paragraph, index) => (
                            <p
                              key={index}
                              className="mt-6 text-xs prose-xs leading-relaxed md:text-sm text-gray-700"
                            >
                              {paragraph}
                            </p>
                          ))}
                        </div>
                      )}
                      {stringContent && stringContent !== "undefined" && (
                        <p className="mt-6 text-xs prose-xs leading-relaxed md:text-sm text-gray-700">
                          {stringContent}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
