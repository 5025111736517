import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import da from "./lang/da.json";
import co_da from "./lang/co_da.json";

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: "da",
  returnNull: false,
  lng: "da",
  resources: {
    da: {
      translation: da,
    },
    co_da: {
      translation: co_da,
    },
  },
});

export default i18n;
