/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 19/12/2020.
 */
import React, { useRef, useState } from "react";
import { Button } from "../display/Button";
import { InputBox } from "../display/InputBox";
import { TextArea } from "../display/TextArea";

import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import { COLOR_PLACEHOLDER } from "../../config/constants";
import { validateEmail } from "../../utils/validateEmail";

type Props = {
  closeModal: () => void;
  nextStep: (emailArr: string[], txt: string) => void;
};

export const InviteFriends: React.FC<Props> = ({ closeModal, nextStep }) => {
  const [txt, setResetTxt] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emails, setEmails] = useState<string[]>([]);

  const { t } = useTranslation();

  const currentEmail = useRef("");
  const txtAreaValue = useRef("");

  const addFriend = () => {
    const errorObj = getError();

    if (errorObj.error) {
      setEmailError(errorObj.error);
      return;
    }

    const arr = emails.concat(errorObj.mailArr);
    currentEmail.current = "";
    setResetTxt("");
    setEmails(arr);
  };

  const getError = () => {
    if (
      currentEmail.current.length < 4 ||
      currentEmail.current.indexOf("@") === -1
    ) {
      return {
        error: t("error.inputFieldError"),
        mailArr: [],
      };
    }

    const currentInputStr = currentEmail.current.split(",");
    const trimmedInput = currentInputStr.map(str => str.trim());
    const hasErrorArr = trimmedInput.filter(item => {
      const isValid = validateEmail(item);
      return !isValid;
    });

    return hasErrorArr.length === 0
      ? { error: "", mailArr: trimmedInput }
      : { error: t("error.inputFieldError"), mailArr: [] };
  };

  const changeHandler = (value: string) => {
    if (emailError) {
      setEmailError("");
    }

    setResetTxt(value);
    currentEmail.current = value;
  };

  const textAreaChangeHandler = (s: string) => {
    txtAreaValue.current = s;
  };

  const deleteItem = (emailIndex: number) => {
    const arr = emails.filter((email, index) => {
      return index !== emailIndex;
    });
    setEmails(arr);
  };

  const sendEmails = () => {
    if (emails.length === 0) {
      const errorObj = getError();
      if (errorObj.error) {
        setEmailError(errorObj.error);
        return;
      }

      nextStep(errorObj.mailArr, txtAreaValue.current);
    } else {
      nextStep(emails, txtAreaValue.current);
    }
  };

  return (
    <div className="container-fluid ">
      <div className="row mt-3">
        <div className="col-12 d-flex justify-content-between">
          <h5>{t("dashboard.invite.title")}</h5>

          <div
            onClick={closeModal}
            className="rounded-full flex pointer justify-center"
            style={{
              width: 25,
              height: 25,
              background: "rgba(226,226,226,.5)",
            }}
          >
            <CloseIcon className="flex self-center"></CloseIcon>
          </div>
        </div>
        <div className="col-12">
          <p>{t("dashboard.invite.subTitle")}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p className="mb-2 text-black p-small fw-bold ">
            {t("generic.email")}
          </p>
          <div
            className="w-full flex"
            style={{ background: "#F1EDDA", borderRadius: 10 }}
          >
            <InputBox
              initialTxt={txt}
              placeholder=""
              className="p-button w-100 mw-100 py-3 pl-3 pr-2"
              inputChangeFunc={changeHandler}
              enterKeyDown={addFriend}
              style={{
                background: "transparent",
                outline: "none",
                border: "none",
                color: emailError ? "red" : "black",
              }}
            />

            <div className="rounded-full self-center m-2 pointer p-2 px-3 bg-primary">
              <p onClick={addFriend} className="mb-0 text-xs text-white">
                {t("generic.add")}
              </p>
            </div>
          </div>
        </div>
        {emailError && (
          <div className="col-12">
            <p className="p-small text-danger mt-2 mb-0">{emailError}</p>
            <hr className="mt-2 mb-1" />
          </div>
        )}
      </div>
      <div className="row mt-2">
        <div className="col-12 d-flex flex-wrap">
          {emails.map((item, index) => {
            return (
              <EmailItem
                key={index}
                index={index}
                item={item}
                deleteItem={deleteItem}
              ></EmailItem>
            );
          })}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 mb-2">
          <p className="mb-2 text-black p-small fw-bold ">
            {t("dashboard.invite.writeMsg")}
          </p>
          <TextArea
            placeholder={t("dashboard.invite.descriptionPlaceholder")}
            inputChangeFunc={textAreaChangeHandler}
            initialTxt=""
            style={{ fontSize: ".7rem", padding: "5px 5px 5px 5px" }}
          ></TextArea>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-4 d-flex justify-content-between">
          <Button onClick={closeModal}>
            <p className="mb-0">{t("generic.cancel")}</p>
          </Button>
          <Button onClick={sendEmails} filled={true}>
            <p className="mb-0">{t("dashboard.invite.inviteBtn")}</p>
          </Button>
        </div>
      </div>
    </div>
  );
};

type EmailItemProps = {
  item: string;
  index: number;
  deleteItem: (index: number) => void;
};

const EmailItem: React.FC<EmailItemProps> = ({ item, deleteItem, index }) => {
  const deleteClick = () => {
    deleteItem(index);
  };

  return (
    <div
      className="mt-2 rounded-pill py-1 pl-3 pr-1   mr-2 d-flex noselect"
      style={{ background: "white", border: "1px solid rgba(0,0,0,.2)" }}
    >
      <p className="mb-0" style={{ fontSize: ".7rem" }}>
        {item}
      </p>
      <p
        onClick={deleteClick}
        className="pointer mb-0 ml-2 mr-0 px-2  rounded-circle"
        style={{
          background: COLOR_PLACEHOLDER,
          fontSize: ".6rem",
          paddingTop: 1,
        }}
      >
        X
      </p>
    </div>
  );
};
