import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ButtonGreen } from "../../components/display/ButtonGreen";
import { InvestMoreModal } from "../../components/invest-more-modal/InvestMoreModal";
import { InviteFriendModal } from "../../components/invite-friend/InviteFriendModal";
import { ClientType } from "../../components/navigation/Navigation";
import { COLOR_PRIMARY } from "../../config/constants";
import { useAuthStore } from "../../hooks/useAuthStore";
import { directLoginUrl, meetingUrl } from "../../routes";

type Props = { isMobile: boolean; user: any | null; totalAmount?: number };

export const UserIntroText: React.FC<Props> = ({
  isMobile,
  user,
  totalAmount,
}) => {
  const { hasTrialAccess, roles, previewToken } = useAuthStore();

  const { t } = useTranslation();

  if (!roles) return null;

  return (
    <>
      <div
        className="px-5 pb-5 pb-lg-0"
        style={{
          borderBottom: `1px solid ${isMobile ? COLOR_PRIMARY : "white"}`,
        }}
      >
        <h1 style={{ whiteSpace: "pre-line" }}>
          {t("dashboard.hello")}
          {!hasTrialAccess && roles.includes(ClientType.Personal) && (
            <span className="text-primary"> {user.firstname}</span>
          )}
          ,
        </h1>
        {totalAmount === 0 ? (
          <p>
            <Trans i18nKey={"dashboard.frontPageTextNoAmount"}>
              {""}
              <Link
                className="text-primary"
                to="mailto:support@thisisdoland.com"
              >
                {""}
              </Link>
            </Trans>
          </p>
        ) : (
          <p>{t("dashboard.frontPageText")}</p>
        )}
        {hasTrialAccess() && (
          <div className="flex gap-2">
            <ButtonGreen filled url={directLoginUrl + `${previewToken}`}>
              <p className="mb-0">{t("trial.startBtn")}</p>
            </ButtonGreen>
            <ButtonGreen white url={meetingUrl}>
              <p className="mb-0">{t("trial.meetingBtn")}</p>
            </ButtonGreen>
          </div>
        )}
        {!hasTrialAccess() && roles.includes(ClientType.Business) && (
          <InvestMoreModal />
        )}
        {!hasTrialAccess() && roles.includes(ClientType.Personal) && (
          <>
            <InvestMoreModal />
            <InviteFriendModal />
          </>
        )}
      </div>
    </>
  );
};
