import { useEffect, useState } from "react";
import { BASE_URL } from "../../../config/constants";
import { useInvestmentContext } from "../../../context/InvestmentContext";
import { post } from "../../../service/API";
import { pageTypeToURLSegment } from "../../../utils/adjustInvestment/pageTypeToURLSegment";
import { PDFViewer } from "../../modals/PDFViewer";
import { ApproveInvestmentButton } from "./ApproveInvestmentButton";
import { Worker } from "@react-pdf-viewer/core";
import { useAuthStore } from "../../../hooks/useAuthStore";
import { useTranslation } from "react-i18next";

interface ApproveInvestmentProps {
  disabled?: boolean;
}

export enum Step {
  LoadingPDF,
  ErrorPDF,
  AgreementDisplay,
  LoadingApproval,
  ErrorApproval,
}

export const ApproveInvestment = ({
  disabled = false,
}: ApproveInvestmentProps) => {
  const { hasTrialAccess } = useAuthStore();

  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(Step.LoadingPDF);
  const [pdfData, setPdfData] = useState(null);
  const [error, setError] = useState("");
  const { selectedThemes, approved, setApproved, pageType } =
    useInvestmentContext();
  const [isSendingApprovalOrder, setSendingApprovalOrder] = useState(false);
  const { t } = useTranslation();
  const fetchPDF = () => {
    if (pageType === null) {
      setStep(Step.ErrorPDF);
      return;
    }

    setStep(Step.LoadingPDF);

    const urlSegment = pageTypeToURLSegment(pageType);

    post(`${BASE_URL}/dashboard/investment/${urlSegment}/get_agreement`, {
      selectedThemes,
    })
      .then(res => {
        setStep(Step.AgreementDisplay);
        setPdfData(res.data);
      })
      .catch((err: any) => {
        setStep(Step.ErrorPDF);
        setError(err.tostring());
      });
  };

  const openModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (!hasTrialAccess()) {
      fetchPDF();
    }
  }, []);

  const closeModal = () => setOpen(false);

  const onApproveClick = () => {
    if (pageType === null) {
      setStep(Step.ErrorApproval);
      return;
    }

    const urlSegment = pageTypeToURLSegment(pageType);

    setStep(Step.LoadingApproval);
    setSendingApprovalOrder(true);
    post(`${BASE_URL}/dashboard/investment/${urlSegment}/approve`, {
      selectedThemes,
      agreementPdf: pdfData,
    })
      .then(res => {
        setApproved?.(res);
        if (res.approved) {
          closeModal();
        } else {
          setStep(Step.ErrorApproval);
          setError("Not approved");
        }
      })
      .catch(err => {
        setStep(Step.ErrorApproval);
        setError(err.toString());
      })
      .finally(() => {
        setSendingApprovalOrder(false);
      });
  };

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
      {!hasTrialAccess() && (
        <ApproveInvestmentButton
          onClick={openModal}
          disabled={step === Step.LoadingPDF || disabled}
        >
          {t("myThemes.approveInvestment")}
        </ApproveInvestmentButton>
      )}
      <PDFViewer
        step={step}
        approved={approved}
        error={error}
        open={open}
        title="Godkendelse af ny temafordeling på din investering"
        base64Data={pdfData}
        isSendingApprovalOrder={isSendingApprovalOrder}
        onAccept={onApproveClick}
        onClose={closeModal}
      />
    </Worker>
  );
};
