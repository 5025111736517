import { Disclosure } from "@headlessui/react";
import { ChevronUp } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import {
  InvestmentPageType,
  useInvestmentContext,
} from "../../../context/InvestmentContext";
import { InvestmentFeesTable } from "./InvestmentFeesTable";
import { InvestmentSummaryTableDesktop } from "./InvestmentSummaryTableDesktop";
import { InvestmentSummaryTableMobile } from "./InvestmentSummaryTableMobile";
import { Fragment } from "react";
import { ApproveInvestment } from "../form/ApproveInvestment";

export const InvestmentSummary = () => {
  const {
    investmentSummary,
    approved,
    categories,
    isDefaultInvestmentSummary,
    isCalculating,
    pageType,
  } = useInvestmentContext();

  const { t } = useTranslation();

  const isRatingPage = pageType === InvestmentPageType.themeChange;
  const isButtonDisabled = !isRatingPage;

  if (!investmentSummary || approved?.approved) {
    return null;
  }

  return (
    <div className="py-4">
      <Disclosure defaultOpen>
        {({ open }) => (
          <>
            <Disclosure.Button as={Fragment}>
              <div
                className={`flex flex-row w-full justify-between rounded-lg bg-gray-100 px-4 py-2 text-left text-sm font-medium text-teal ${
                  isButtonDisabled
                    ? "cursor-default hover:bg-gray-100"
                    : "cursor-pointer hover:bg-gray-200"
                }`}
                onClick={e => {
                  if (isButtonDisabled) {
                    e.preventDefault();
                  }
                }}
              >
                <div className="flex flex-row gap-x-5 self-center ">
                  <span style={{ fontSize: "1em" }}>
                    {t("myThemes.moreDetails")}
                  </span>
                  {!isButtonDisabled && (
                    <ChevronUp
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-teal`}
                    />
                  )}
                </div>
                <div>
                  <ApproveInvestment
                    disabled={
                      (isRatingPage && isDefaultInvestmentSummary) ||
                      isCalculating
                    }
                  />
                </div>
              </div>
            </Disclosure.Button>
            <Disclosure.Panel className="py-4 container text-brown">
              <InvestmentSummaryTableDesktop categories={categories} />
              <InvestmentSummaryTableMobile categories={categories} />
              <div className="row">
                <div className="col-12 mt-5">
                  <p className="text-muted" style={{ fontSize: ".7rem" }}>
                    {t("myThemes.updatedThemeExplanation")}
                  </p>
                </div>
              </div>
              <InvestmentFeesTable
                fees={investmentSummary.fees}
                fees_trading={investmentSummary.fees_trading}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
