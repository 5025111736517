export const getDate = () => {};

const months = [
  "Jan",
  "Feb",
  "Marts",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dec",
];

export const getTimestamp = (timestamp: Date) => {
  const d = new Date(Number(timestamp + "000"));
  const monthStr = months[d.getMonth()];
  return d.getDate() + " " + monthStr + " " + d.getFullYear();
};
