import { Trans, useTranslation } from "react-i18next";
import { Matter } from "./Matter";
import "./matter-style.css";

const MatterApp = () => {
  const { t } = useTranslation();

  return (
    <div className="container mt-6">
      <div className="row">
        <div className="col-12">
          <h2>{t("matterApp.header")}</h2>
          <p className="mt-5">{t("matterApp.description")}</p>
        </div>
      </div>

      <Matter />

      <div className="row mt-5">
        <div className="col-12 text-sm">
          <p>
            <b className="text-uppercase">{t("matterApp.explanationHeader")}</b>
          </p>
          <p>{t("matterApp.explanationDescription")}</p>
          <p>
            <b className="text-uppercase">{t("matterApp.feedbackHeader")}</b>
          </p>
          <p>
            <Trans i18nKey={"matterApp.feedbackDescription"}>
              <p></p>
              <a href="mailto:support@thisisdoland.com"></a>
            </Trans>
          </p>
        </div>
      </div>
    </div>
  );
};

export default MatterApp;
