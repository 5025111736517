/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 24/09/2021.
 */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MY_BASE_URL } from "../../config/constants";
import { get } from "../../service/API";
import { IncomeResponse } from "../profile/types/IncomeResponse";
import { PersonalInfoResponse } from "../profile/types/PersonalInfoResponse";
import { RiskResponse } from "../profile/types/RiskResponse";

import { Button } from "../../components/display/Button";
import { ButtonGreen } from "../../components/display/ButtonGreen";
import { Horizon, LossReaction, Purpose, Risk } from "../../enum/user.enum";
import { REBALANCING_PAGE } from "../../routes";
import { useTranslation } from "react-i18next";

export const RebalancingIntroPage = () => {
  const { t } = useTranslation();
  const [personalInfo, setPersonalInfo] = useState<PersonalInfoResponse | null>(
    null,
  );
  const [riskInfo, setRiskInfo] = useState<RiskResponse | null>(null);
  const [incomeInfo, setIncomeInfo] = useState<IncomeResponse | null>(null);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const navigate = useNavigate();
  const navigateWithRebalancing = (route: string) => {
    navigate(`/profile/${route}?rebalancing=true`);
  };

  const editRisk = () => navigateWithRebalancing("risk");
  const editEconomy = () => navigateWithRebalancing("income");
  const editInfo = () => navigateWithRebalancing("info");

  useEffect(() => {
    get(`${MY_BASE_URL}/dashboard/profile/personal`).then(
      (res: PersonalInfoResponse) => {
        setPersonalInfo(res);
      },
    );

    get(`${MY_BASE_URL}/dashboard/profile/risk`).then((res: RiskResponse) => {
      setRiskInfo(res);
    });

    get(`${MY_BASE_URL}/dashboard/profile/income`).then(
      (res: IncomeResponse) => {
        setIncomeInfo(res);
      },
    );
  }, []);

  function getRiskDescription(
    risk: number,
    type: "Risk" | "Horizon" | "Purpose" | "Reaction",
  ) {
    switch (type) {
      case "Risk":
        switch (risk) {
          case Risk.Low:
            return t("profile.risk.little");
          case Risk.Medium:
            return t("profile.risk.middle");
          case Risk.High:
            return t("profile.risk.much");
          case Risk.ExtraHigh:
            return t("profile.risk.veryMuch");
          default:
            return t("profile.risk.unknownRisk");
        }
      case "Horizon":
        switch (risk) {
          case Horizon.Short:
            return t("profile.risk.horizon1");
          case Horizon.Medium:
            return t("profile.risk.horizon2");
          case Horizon.Long:
            return t("profile.risk.horizon3");
          case Horizon.ExtraLong:
            return t("profile.risk.horizon4");
          default:
            return t("profile.risk.unknownHorizon");
        }
      case "Purpose":
        switch (risk) {
          case Purpose.Learn:
            return t("profile.risk.purpose1");
          case Purpose.Part:
            return t("profile.risk.purpose2");
          case Purpose.Save:
            return t("profile.risk.purpose3");
          case Purpose.Other:
            return t("profile.risk.purpose4");
          default:
            return t("profile.risk.unknownPurpose");
        }
      case "Reaction":
        switch (risk) {
          case LossReaction.Invest:
            return t("profile.risk.reactionLoss1");
          case LossReaction.Nothing:
            return t("profile.risk.reactionLoss2");
          case LossReaction.SellPart:
            return t("profile.risk.reactionLoss3");
          case LossReaction.SellAll:
            return t("profile.risk.reactionLoss4");
          default:
            return t("profile.risk.unknownReactionLoss");
        }
      default:
        return t("profile.risk.default");
    }
  }

  return (
    <div className="container mt-6 position-relative flex flex-col">
      <div className="row">
        <div className="col-12">
          <h2>{t("rebalance.header")}</h2>
          <p className="mt-3">{t("rebalance.description")}</p>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-5 justify-between mt-3 mb-2">
        <div className="bg-teal-100 lg:w-1/3 flex flex-col p-4 rounded-xl">
          <h5 className="mb-4">{t("rebalance.risiko.header")}</h5>
          {riskInfo && (
            <div className="flex flex-col gap-3">
              <div>
                <p className="font-bold mb-2">{t("rebalance.risiko.profil")}</p>
                <p>{getRiskDescription(riskInfo.risk, "Risk")}</p>
              </div>
              <div>
                <p className="font-bold mb-2">
                  {t("rebalance.risiko.horizon")}
                </p>
                <p>
                  {riskInfo
                    ? getRiskDescription(riskInfo.horizon, "Horizon")
                    : ""}
                </p>
              </div>
              <div>
                <p className="font-bold mb-2">
                  {t("rebalance.risiko.purpose")}
                </p>
                <p>
                  {riskInfo
                    ? getRiskDescription(riskInfo.purpose, "Purpose")
                    : ""}
                </p>
              </div>
              <div>
                <p className="font-bold mb-2">
                  {t("rebalance.risiko.reactionLoss")}
                </p>
                <p>
                  {riskInfo
                    ? getRiskDescription(riskInfo.reaction_loss, "Reaction")
                    : ""}
                </p>
              </div>
              <Button onClick={editRisk} filled className="mt-auto w-full">
                <p className="mb-0">{t("rebalance.risiko.updateBtn")}</p>
              </Button>
            </div>
          )}
        </div>
        <div className="bg-teal-100 lg:w-1/3 flex flex-col p-4 rounded-xl">
          <h5 className="mb-4 text-brown">{t("rebalance.okonomi.header")}</h5>
          {incomeInfo && (
            <div className="flex flex-col gap-3">
              <div className="flex flex-col">
                <p className="font-bold mb-2">
                  {t("rebalance.okonomi.income")}
                </p>
                <p>{incomeInfo.income.amount} kr.</p>
              </div>
              <div className="flex flex-col">
                <p className="font-bold mb-2">
                  {t("rebalance.okonomi.otherIncome")}
                </p>
                <p>{incomeInfo.income_secondary.amount} kr.</p>
              </div>
              <div className="flex-flex-col">
                <p className="font-bold mb-2">
                  {t("rebalance.okonomi.liquidity")}
                </p>
                <p>{incomeInfo.investment_liquidity.amount} kr.</p>
              </div>
              <div className="flex flex-col">
                <p className="font-bold mb-2">
                  {t("rebalance.okonomi.otherWealth")}
                </p>
                <p>{incomeInfo.other_wealth.amount} kr.</p>
              </div>
            </div>
          )}
          <Button onClick={editEconomy} className="mt-auto w-full" filled>
            <p className="mb-0">{t("rebalance.okonomi.updateBtn")}</p>
          </Button>
        </div>
        <div className="bg-teal-100 lg:w-1/3 flex flex-col p-4 rounded-xl">
          <h5 className="mb-4">{t("rebalance.profile.header")}</h5>
          {personalInfo && (
            <div className="flex flex-col gap-3">
              <div className="flex flex-col">
                <p className="font-bold mb-2">
                  {t("rebalance.profile.address")}
                </p>
                <p className="mb-1">
                  {personalInfo.firstname} {personalInfo.lastname}
                </p>
                <p className="mb-1">
                  {personalInfo.address.street} {personalInfo.address.street_no}
                </p>
                <p className="mb-">
                  {personalInfo.address.zip} {personalInfo.address.city}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="font-bold mb-2">{t("rebalance.profile.email")}</p>
                <p>{personalInfo.email}</p>
              </div>
              <div className="flex flex-col">
                <p className="font-bold mb-2">{t("rebalance.profile.phone")}</p>
                <p>(+45){personalInfo.phone.number}</p>
              </div>
            </div>
          )}
          <Button onClick={editInfo} filled className="mt-auto w-full">
            <p className="mb-0">{t("rebalance.profile.updateBtn")}</p>
          </Button>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row mt-7 mb-5 items-center justify-between">
        <div className="flex mb-3 lg:mb-0 mt-3">
          <input
            id="acceptTerms"
            className="mr-2 h-4 w-4 rounded border-gray-300 text-teal focus:ring-teal"
            type="checkbox"
            name="acceptTerms"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <label className="font-bold text-sm mb-0" htmlFor="acceptTerms">
            {t("rebalance.acceptTerms")}
          </label>
        </div>
        <div className="flex w-full lg:w-auto">
          <Link
            to={REBALANCING_PAGE.to}
            className="hover:no-underline mb-0 w-full lg:w-auto"
          >
            <ButtonGreen
              disabled={!isChecked}
              className="w-full lg:w-auto "
              margin="mb-0 lg:ml-3"
            >
              <p className="mb-0"> {t("rebalance.rebalanceBtn")}</p>
            </ButtonGreen>
          </Link>
        </div>
      </div>
    </div>
  );
};
