/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13-11-2020.
 */
import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ProtectedLayout from "./components/layout/ProtectedLayout";
import VoteLayout from "./components/layout/VoteLayout";
import MatterApp from "./components/matter/MatterApp";
import { ClientType } from "./components/navigation/Navigation";
import { useAuthStore } from "./hooks/useAuthStore";
import { NoContent } from "./pages/NoContent";
import NotFoundPage from "./pages/NotFoundPage";
import CommunityPage from "./pages/community/CommunityPage";
import { IntroPage } from "./pages/domobilize/introPage";
import { VoteBalancePage } from "./pages/domobilize/voteBalancePage";
import { VotePage } from "./pages/domobilize/votePage";
import FrontPage from "./pages/front/FrontPage";
import { InvestMorePage } from "./pages/invest-more/InvestMorePage";
import { Login } from "./pages/login/Login";
import { SignUp } from "./pages/login/SignUp";
import { MyInvestments } from "./pages/my-investments/MyInvestments";
import { NewsArticle } from "./pages/news/NewsArticle";
import { ProfilePage } from "./pages/profile/ProfilePage";
import { RebalancingIntroPage } from "./pages/rebalancing/RebalancingIntroPage";
import { RebalancingPage } from "./pages/rebalancing/RebalancingPage";
import { ThemeChangePage } from "./pages/theme-change/ThemeChangePage";
import DoMobilizePage from "./pages/trial/DoMobilizePage";
import DoReportPage from "./pages/trial/DoReportPage";
import { SignUpTrial } from "./pages/trial/SignUpTrial";
import TrialPage from "./pages/trial/TrialPage";
import TrialToolPage from "./pages/trial/TrialToolPage";
import {
  COMMUNITY_PAGE,
  DOMOBILIZE_INTRO_PAGE,
  DOMOBILIZE_PAGE,
  DOMOBILIZE_VOTE_BALANCE_PAGE,
  DOMOBILIZE_VOTE_PAGE,
  DOREPORT_PAGE,
  FRONT_PAGE,
  INVEST_MORE_PAGE,
  LOGIN,
  MATTER_IMPACT,
  MY_INVESTMENTS,
  NEWS_ARTICLE,
  PROFILE,
  RATING_PAGE,
  REBALANCING_INTRO_PAGE,
  REBALANCING_PAGE,
  SIGNUP,
  SIGNUP_TRIAL,
  TOOLS_PAGE,
  TRIAL_PAGE,
} from "./routes";
import { useOverlayStore } from "./store/OverlayStore";
import i18n from "./utils/i18n";

export const Main: React.FC = () => {
  const { token, previewToken, hasTrialAccess, roles } = useAuthStore();

  useEffect(() => {
    if (!roles) return;
    i18n.changeLanguage(roles.includes(ClientType.Business) ? "co_da" : "da");
  }, [roles]);

  const { loading } = useOverlayStore(state => ({
    loading: state.loading,
  }));

  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <ProtectedLayout
              loading={loading}
              token={token}
              previewToken={previewToken}
            />
          }
        >
          {previewToken && (
            <>
              {roles == null && <Route path="/" element={<NoContent />} />}
              {roles && roles.includes(ClientType.Vote) && (
                <Route element={<VoteLayout />}>
                  <Route
                    path={DOMOBILIZE_INTRO_PAGE.to}
                    element={<IntroPage />}
                  />
                  <Route
                    path={DOMOBILIZE_VOTE_PAGE.to}
                    element={<VotePage />}
                  />
                  <Route
                    path={DOMOBILIZE_VOTE_BALANCE_PAGE.to}
                    element={<VoteBalancePage />}
                  />
                  <Route
                    path={SIGNUP.to + "/:token"}
                    element={<Navigate to={DOMOBILIZE_INTRO_PAGE.to} replace />}
                  />
                </Route>
              )}

              {roles && hasTrialAccess() && (
                <>
                  <Route path={TRIAL_PAGE.to} element={<TrialPage />} />
                  <Route path={TOOLS_PAGE.to} element={<TrialToolPage />} />
                  <Route path={DOREPORT_PAGE.to} element={<DoReportPage />} />
                  <Route
                    path={DOMOBILIZE_PAGE.to}
                    element={<DoMobilizePage />}
                  />
                  <Route
                    path={LOGIN.to}
                    element={<Navigate to={TRIAL_PAGE.to} replace />}
                  />
                  <Route
                    path={"/trial"}
                    element={<Navigate to={TRIAL_PAGE.to} replace />}
                  />
                </>
              )}
              <Route path={LOGIN.to} element={<Navigate to={"/"} replace />} />
            </>
          )}
          {token && (
            <>
              <Route
                path={`${NEWS_ARTICLE.to}/:newsId`}
                element={<NewsArticle />}
              />
              <Route
                path={`${COMMUNITY_PAGE.to}/:communityId`}
                element={<CommunityPage />}
              />
              <Route path={PROFILE.to} element={<ProfilePage />} />
              <Route path={INVEST_MORE_PAGE.to} element={<InvestMorePage />} />
              <Route path={REBALANCING_PAGE.to} element={<RebalancingPage />} />
              <Route
                path={REBALANCING_INTRO_PAGE.to}
                element={<RebalancingIntroPage />}
              />
              <Route
                path={LOGIN.to}
                element={<Navigate to={FRONT_PAGE.to} replace />}
              />

              <Route path="*" element={<NotFoundPage />} />
            </>
          )}
          {(token || hasTrialAccess()) && (
            <>
              <Route
                index={token ? true : false}
                path={FRONT_PAGE.to}
                element={<FrontPage />}
              />
              <Route path={MY_INVESTMENTS.to} element={<MyInvestments />} />

              <Route path={MATTER_IMPACT.to} element={<MatterApp />} />

              <Route path={RATING_PAGE.to} element={<ThemeChangePage />} />

              <Route
                path={LOGIN.to}
                element={
                  <Navigate
                    to={token ? FRONT_PAGE.to : TRIAL_PAGE.to}
                    replace
                  />
                }
              />
              {token && (
                <Route
                  path="/"
                  element={<Navigate to={FRONT_PAGE.to} replace />}
                />
              )}
              <Route path="*" element={<NotFoundPage />} />
            </>
          )}
        </Route>

        {(!token || !previewToken) && (
          <>
            <Route path={LOGIN.to} element={<Login />} />
            <Route path={SIGNUP.to + "/:token"} element={<SignUp />} />
            <Route path={SIGNUP_TRIAL.to} element={<SignUpTrial />} />
            <Route path="*" element={<Navigate to={LOGIN.to} replace />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};
