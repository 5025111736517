import React from "react";
import { ButtonGreen } from "../../components/display/ButtonGreen";
import { useAuthStore } from "../../hooks/useAuthStore";
import {
  CheckIcon,
  DocumentChartBarIcon,
  PuzzlePieceIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import { Trans, useTranslation } from "react-i18next";
import { SpinnerFullHeight } from "../../components/spinner/SpinnerFullHeight";
import { COLOR_PRIMARY } from "../../config/constants";
import {
  DOMOBILIZE_PAGE,
  DOREPORT_PAGE,
  FRONT_PAGE,
  directLoginUrl,
} from "../../routes";

interface BusinessProduct {
  productIcon: React.ReactNode;
  headline: string;
  description: string;
  buttonText: string;
  linkPath: string;
  features: string[];
}
const TrialToolPage: React.FC = () => {
  const { user, previewToken } = useAuthStore();
  const { t } = useTranslation();
  const products: BusinessProduct[] = [
    {
      productIcon: (
        <PuzzlePieceIcon height={60} stroke={COLOR_PRIMARY} fill={"none"} />
      ),
      headline: t("trial.doInvest.title"),
      description: t("trial.doInvest.intro"),
      linkPath: FRONT_PAGE.to,
      buttonText: t("trial.doInvest.ctaBtn"),
      features: [
        t("trial.doInvest.check1"),
        t("trial.doInvest.check2"),
        t("trial.doInvest.check3"),
      ],
    },
    {
      productIcon: (
        <UserGroupIcon height={60} stroke={COLOR_PRIMARY} fill={"none"} />
      ),
      headline: t("trial.doMobilize.title"),
      description: t("trial.doMobilize.intro"),
      linkPath: DOMOBILIZE_PAGE.to,
      buttonText: t("trial.doMobilize.ctaBtn"),
      features: [
        t("trial.doMobilize.check1"),
        t("trial.doMobilize.check2"),
        t("trial.doMobilize.check3"),
      ],
    },
    {
      productIcon: (
        <DocumentChartBarIcon
          height={60}
          stroke={COLOR_PRIMARY}
          fill={"none"}
        />
      ),
      headline: t("trial.doReport.title"),
      description: t("trial.doReport.intro"),
      linkPath: DOREPORT_PAGE.to,
      buttonText: t("trial.doReport.ctaBtn"),
      features: [
        t("trial.doReport.check1"),
        t("trial.doReport.check2"),
        t("trial.doReport.check3"),
      ],
    },
  ];
  if (!user) {
    return <SpinnerFullHeight />;
  }
  const Product: React.FC<BusinessProduct> = ({
    productIcon,
    headline,
    description,
    buttonText,
    linkPath,
    features,
  }) => {
    return (
      <div className="flex flex-col items-center gap-3 p-7 border-1 border-primary rounded-xl">
        <div className="mx-auto rounded-lg p-3">{productIcon}</div>
        <p className="whitespace-pre-line font text-2xl">{headline}</p>
        <p className="text-xs text-center">{description}</p>
        <div className="flex flex-col flex-grow gap-1 py-5 ">
          {features.map((feature, index) => (
            <div key={index} className="grid grid-cols-6 gap-3">
              <CheckIcon
                className="h-6 w-6 col-span-1"
                height={30}
                fill={COLOR_PRIMARY}
              />
              <p className="col-span-5 my-auto text-xs">{feature}</p>
            </div>
          ))}
        </div>

        <ButtonGreen filled className="flex mx-auto self-end" link={linkPath}>
          <p className="mb-0">{buttonText}</p>
        </ButtonGreen>
      </div>
    );
  };

  return (
    <div className="container">
      <h1 className="text-3xl font-bold text-center pt-12 pb-8">
        {t("trial.tools.header")}
      </h1>
      <p className="text-center text-md md:text-lg pb-8">
        {t("trial.tools.description")}
      </p>
      <div className="flex lg:flex-row flex-col flex-gro gap-5">
        {products.map((product, index) => (
          <div key={index} className="flex justify-center">
            <Product
              productIcon={product.productIcon}
              headline={product.headline}
              description={product.description}
              buttonText={product.buttonText}
              linkPath={product.linkPath}
              features={product.features}
            />
          </div>
        ))}
      </div>
      <div className="my-10 flex flex-col items-center py-5 gap-5">
        <h5> {t("trial.tools.secondary-header")}</h5>
        <div className=" text-center px-2 ">
          <Trans i18nKey="trial.tools.ready">
            <p></p>
            <a
              href={directLoginUrl + `${previewToken}`}
              target="_blank"
              rel="noreferrer"
              className="text-primary cursor-pointer"
            ></a>
          </Trans>
        </div>
      </div>
    </div>
  );
};

export default TrialToolPage;
