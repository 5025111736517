/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13-10-2020.
 */
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import React, { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import svg from "../../assets/svg/windmills.svg";
import SubmitButton from "../../components/form/SubmitButton";
import { BASE, DEBUG } from "../../config/constants";
import { useAuthStore } from "../../hooks/useAuthStore";
import "./login-form.scss";

export const Login: React.FC = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState(DEBUG ? "" : "");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { login } = useAuthStore();

  const handleFormSubmit = (event: FormEvent) => {
    setIsSubmitting(true);
    event.preventDefault();

    login(password, username)
      .then((res: any) => {
        console.log(" Login > res = ", res);
        if (res?.someSuccessCondition) {
          location.reload();
          // navigate("/");
          return <Navigate to="/" replace />;
        } else if (res) {
          setError(res);
        }
      })
      .catch(() => {
        console.log("err");
        setError(t("loginPage.passwordErr"));
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="flex flex-col lg:flex-row-reverse items-center h-screen justify-center lg:justify-between">
      <div className="flex self-center w-1/3 lg:self-end py-10">
        <img src={svg} alt="DoLand svg Icon" className="w-full h-full" />
      </div>
      <div className="flex w-full lg:w-2/3 justify-center self-center">
        <form
          onSubmit={handleFormSubmit}
          className="xl:1/3 xl:max-w-md flex flex-col"
        >
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h1 className="text-base text-center font-semibold leading-7 text-primary mb-10">
              {t("loginPage.header")}
            </h1>
          </div>

          <div className="flex flex-col py-3">
            <label
              className="block text-sm font-medium leading-6 text-gray-900"
              htmlFor="username"
            >
              {t("generic.email")}
            </label>
            <input
              id="username"
              type="text"
              className="mt-2 block w-full rounded-md border py-1.5 text-gray-900 bg-slate-100  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-300 sm:text-sm sm:leading-6"
              autoComplete="username"
              value={username}
              name="username"
              onChange={e => setUsername(e.target.value)}
            />
          </div>
          <div className="flex flex-col py-3">
            <label className="leading-6 pb-2 text-sm" htmlFor="password">
              {t("generic.password")}
            </label>
            <div className="relative mt-2">
              <input
                id="password"
                type={isPasswordVisible ? "text" : "password"}
                autoComplete="current-password"
                className="block w-full rounded-md border py-1.5 text-gray-900 bg-slate-100  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-300 sm:text-sm sm:leading-6"
                value={password}
                name="password"
                onChange={e => setPassword(e.target.value)}
              />
              <div
                className="absolute right-0 inset-y-0 flex items-center pr-3 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {isPasswordVisible ? (
                  <EyeSlashIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <EyeIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="mt-3 mb-10 md:mb-3 lg:mb-0 fle w-full items-center gap-x-6">
            <SubmitButton
              label={t("loginPage.signInBtn") || "Log ind på DoLand"}
              className="w-full"
              isSubmitting={isSubmitting}
            ></SubmitButton>
          </div>
          {error && (
            <p className="mt-4 px-4 text-center text-danger">{error}</p>
          )}
          <p className="px-3 mt-4 text-center">
            {t("loginPage.forgotPasswordText")}
            <a className="pl-2 text-capitalize" href={`${BASE}/password/reset`}>
              {t("generic.clickHere")}
            </a>
          </p>
        </form>
      </div>
    </div>
  );
};
