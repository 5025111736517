import { useFormikContext } from "formik";
import React, { useState } from "react";
import { UserThemes } from "./votePage";
import { Modal } from "./modal";

type Props = {
  theme_id: number;
  item: ThemeItem;
};

type ThemeItem = {
  icon: string; // Update as necessary
  title: string;
};

export const ThemeChoice: React.FC<Props> = ({ item, theme_id }: Props) => {
  const [open, setOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext<UserThemes>();
  const isActive = values.themes.some(item => theme_id === item.id);
  const toggleCheckbox = () => {
    if (!isActive) {
      setFieldValue("themes", [...values.themes, { id: theme_id, rating: 50 }]);
      return;
    }

    setFieldValue(
      "themes",
      values.themes.filter(item => item.id !== theme_id),
    );
  };

  const handleCheckboxChange = () => {
    if (!isActive) {
      setOpen(true);
    } else {
      toggleCheckbox();
    }
  };

  return (
    <>
      <div key={item.title} className="relative" onClick={handleCheckboxChange}>
        <input
          type="checkbox"
          checked={isActive}
          className="border rounded-md h-5 w-5 md:h-8 md:w-8 text-primary focus:ring-0 focus:border-transparent absolute top-2 right-2 md:top-3 md:right-3"
        />
        <img src={item.icon} alt="Theme Icon" />
      </div>
      <Modal
        onClose={() => setOpen(false)}
        theme_id={theme_id}
        open={open}
        isButton={true}
        onButtonClick={toggleCheckbox}
        isActive={isActive}
      />
    </>
  );
};
