/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 11-11-2020.
 */
import React, { useEffect, useState } from "react";
import { COLOR_PRIMARY } from "../../config/constants";
import { NewsItem } from "../../pages/front/NewsItem";

import { newsStore } from "../../store/NewsStore";
import { ErrorComponent } from "../ErrorComponent";

export const NewsList: React.FC = () => {
  const { newsList, getNewsList } = newsStore(state => ({
    newsList: state.newsList,
    getNewsList: state.getNewsList,
  }));
  const [error, setError] = useState<string>("");
  useEffect(() => {
    getNewsList().catch((error: any) => {
      console.log(" NewsList > error = ", error);
      setError(error);
    });
  }, [getNewsList]);

  if (error) {
    return <ErrorComponent error={error}></ErrorComponent>;
  }

  return (
    <div className="row mt-1 ">
      <div
        className="w-100 "
        style={{ borderTop: `1px solid ${COLOR_PRIMARY}` }}
      ></div>
      <div className="grid grid-cols-1 my-5 md:grid-cols-2 max-w-none gap-x-8">
        {newsList.map((item, index) => {
          return <NewsItem key={index} item={item}></NewsItem>;
        })}
      </div>
    </div>
  );
};
