/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04/06/2021.
 */

import { useEffect } from "react";
import { lookUpData } from "../../config/constants";

import { Desktop } from "../../components/display/Desktop";
import { Mobile } from "../../components/display/Mobile";
import { MyInvestmentsDesktop } from "./MyInvestmentsDesktop";
import { MyInvestmentsMobile } from "./MyInvestmentsMobile";

import { useTranslation } from "react-i18next";
import useInvestmentsStore, {
  InvestmentTimeSpanEnum,
} from "../../store/MyInvestmentsStore";
import { MyFees } from "./MyFees";
import { MyInvestmentSummery } from "./MyInvestmentSummery";
import { TimeSpanBtn } from "./TimeSpanBtn";
import { ViewSwitch } from "./ViewSwitch";
import { roundToOneDecimal } from "../../utils/roundToOneDecimal";

import { useAuthStore } from "../../hooks/useAuthStore";

export const MyInvestments = () => {
  const { t } = useTranslation();
  const { hasTrialAccess } = useAuthStore();

  const {
    selectedTimeSpan,
    setSelectedTimeSpan,
    loadData,
    assetData,
    loading,
    summeryData,
    isPct,
    setViewType,
  } = useInvestmentsStore(state => ({
    selectedTimeSpan: state.selectedTimeSpan,
    setSelectedTimeSpan: state.setSelectedTimeSpan,
    loading: state.loading,
    loadData: state.loadData,
    assetData: state.assetData,
    summeryData: state.summeryData,
    isPct: state.isPct,
    setViewType: state.setViewType,
  }));
  const trialTimeSpanArr = [
    {
      title: `1 ${t("timeSpan.month")}`,
      query: InvestmentTimeSpanEnum.OneMonth,
    },
    {
      title: `3 ${t("timeSpan.month")}`,
      query: InvestmentTimeSpanEnum.TheeMonths,
    },
  ];
  const timeSpanArr = [
    {
      title: `1 ${t("timeSpan.month")}`,
      query: InvestmentTimeSpanEnum.OneMonth,
    },
    {
      title: `3 ${t("timeSpan.month")}`,
      query: InvestmentTimeSpanEnum.TheeMonths,
    },
    {
      title: `i ${t("timeSpan.year")}`,
      query: InvestmentTimeSpanEnum.ThisYear,
    },
    { title: `1 ${t("timeSpan.year")}`, query: InvestmentTimeSpanEnum.OneYear },
    {
      title: t("timeSpan.sinceStart"),
      query: InvestmentTimeSpanEnum.SinceStart,
    },
  ];
  const timeArr = hasTrialAccess() ? trialTimeSpanArr : timeSpanArr;
  useEffect(() => {
    if (hasTrialAccess()) {
      setSelectedTimeSpan(trialTimeSpanArr[0].query);
    } else {
      setSelectedTimeSpan(timeSpanArr[0].query);
    }
  }, [hasTrialAccess()]);

  useEffect(() => {
    const fetchData = async () => {
      await loadData(hasTrialAccess());
    };

    fetchData();
  }, [selectedTimeSpan, hasTrialAccess]);

  const updateTimeSpan = (v: any) => {
    setSelectedTimeSpan(v.query);
  };

  return (
    <div className="container ">
      <div className="row mt-5">
        <div className="col-12 d-flex justify-content-between">
          <h6 className="">
            {t("myInvestment.totalHeadline")}{" "}
            {summeryData[selectedTimeSpan] &&
              !loading &&
              roundToOneDecimal(summeryData[selectedTimeSpan].result.total)}
          </h6>
          <ViewSwitch setViewType={setViewType} isPct={isPct}></ViewSwitch>
        </div>
        <div className="col-12 mt-4 d-flex justify-content-between justify-content-md-start">
          {timeArr.map((item, index) => {
            const selected = selectedTimeSpan === item.query;
            return (
              <TimeSpanBtn
                key={index}
                selected={selected}
                onClick={updateTimeSpan}
                data={item}
              ></TimeSpanBtn>
            );
          })}
        </div>
      </div>

      <MyInvestmentSummery
        summeryData={summeryData[selectedTimeSpan]}
        isPct={isPct}
      ></MyInvestmentSummery>

      <Desktop>
        <MyInvestmentsDesktop
          loading={loading}
          data={assetData[selectedTimeSpan]}
          isPct={isPct}
          lookUpData={lookUpData}
        />
      </Desktop>

      <Mobile>
        <MyInvestmentsMobile
          loading={loading}
          data={assetData[selectedTimeSpan]}
          isPct={isPct}
          lookUpData={lookUpData}
        />
      </Mobile>
      {assetData && (
        <div className="row">
          <div className="col-12 mt-5">
            <p className="text-muted" style={{ fontSize: ".7rem" }}>
              {t("myInvestment.productReturnExplanation")}
            </p>
          </div>
        </div>
      )}
      <MyFees />

      <div className="row">
        <div className="col-12 mb-6"></div>
      </div>
    </div>
  );
};
