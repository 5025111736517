// components/CheckBox.tsx

import { ErrorMessage, Field } from "formik";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { InformationModal } from "./InformationModal";
import InformationIcon from "../icons/InformationIcon";
interface CheckBoxProps {
  name: string;
  label: string;
  id?: string;
  description?: string | ReactNode;
  className?: string;
  inputClassName?: string;
  modalTitle?: string | null;
  modalDescription?: React.ReactNode[] | null;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  id,
  name,
  label,
  description,
  className = "",
  inputClassName = "",
  modalTitle = undefined,
  modalDescription = undefined,
}) => {
  const { t } = useTranslation();
  const fieldId = id || name;

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <div className={className}>
      <div className={`relative flex items-center`}>
        <div className="mr-3 flex items-start">
          <Field
            id={fieldId}
            name={name}
            type="checkbox"
            aria-describedby={`${fieldId}-description`}
            className={`h-4 w-4 rounded border-gray-300 text-teal focus:ring-teal ${inputClassName}`}
          />
        </div>
        <div className="text-sm flex-grow flex items-center justify-between">
          <div>
            <label htmlFor={fieldId} className="font-medium text-gray-900">
              {t(label)}
            </label>
            {description && (
              <p
                id={`${fieldId}-description`}
                className="text-gray-500 pb-2 text-xs"
              >
                <span className="sr-only">{label} </span>
                {description}
              </p>
            )}
          </div>
          {modalTitle && (
            <button type="button" onClick={handleModalOpen}>
              <InformationIcon className="mb-2 ml-2 w-5 h-5" />
            </button>
          )}
        </div>
      </div>
      <ErrorMessage name={name} component="div">
        {msg => <span className="text-red text-sm mt-1">{t(msg)}</span>}
      </ErrorMessage>
      {modalOpen && modalTitle && modalDescription && (
        <InformationModal
          open={modalOpen}
          onClose={handleModalClose}
          title={modalTitle}
          content={modalDescription}
        />
      )}
    </div>
  );
};

export default CheckBox;
