import { create } from "zustand";
import { MY_BASE_URL } from "../config/constants";
import { get as getRequest } from "../service/API";
import { DistrobutionResponse } from "../types/investment";

export type CommunityStoreState = {
  communities: number[];
  communitiesInfo: CommunityInfoType[];
  communitiesDistribution: {
    [community_id: number]: DistrobutionResponse;
  };
  getCommunities: () => Promise<number[]>;
  getCommunityInfo: () => Promise<CommunityInfoType[]>;
  getCommunityDistribution: (
    community_id: number,
  ) => Promise<DistrobutionResponse>;
};

export type CommunitiesType = {
  success: boolean;
  community_ids: number[];
};

export type CommunitiesInfoType = {
  success: boolean;
  no_members: number;
  title: string;
};

export type CommunityType = {
  success: boolean;
  community_ids: number[];
};

export type CommunityInfoType = {
  id: number;
  no_members: number;
  title: string;
};

type SetDistribution = {
  communitiesDistribution: {
    [community_id: number]: DistrobutionResponse;
  };
};

const fetchCommunityInfo = async (id: number): Promise<CommunityInfoType> => {
  const response = await getRequest<CommunitiesInfoType>(
    `${MY_BASE_URL}/dashboard/community/${id}/info`,
  );
  return {
    id,
    no_members: response.no_members,
    title: response.title,
  };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const communitiesStore = create<CommunityStoreState>((set, get) => {
  return {
    communities: [],
    communitiesInfo: [],
    communitiesDistribution: {},

    getCommunities: async () => {
      const response = await getRequest<CommunityType>(
        `${MY_BASE_URL}/dashboard/profile/communities`,
      );

      const communities = response.community_ids;

      set({ communities });

      const communitiesInfo: CommunityInfoType[] = Array.isArray(communities)
        ? await Promise.all(communities.map(id => fetchCommunityInfo(id)))
        : [await fetchCommunityInfo(communities)];

      set({ communitiesInfo });

      return communities;
    },

    getCommunityInfo: async (community_id: number | number[]) => {
      let communitiesInfo = [];

      if (Array.isArray(community_id)) {
        communitiesInfo = await Promise.all(
          community_id.map(id => fetchCommunityInfo(id)),
        );
      } else {
        const singleCommunityInfo = await fetchCommunityInfo(community_id);
        communitiesInfo.push(singleCommunityInfo);
      }

      set({ communitiesInfo });
      return communitiesInfo;
    },

    getCommunityDistribution: async (community_id: number) => {
      const cachedDistribution = get().communitiesDistribution[community_id];

      if (cachedDistribution) {
        return cachedDistribution;
      }

      const communityData = await getRequest<DistrobutionResponse>(
        `${MY_BASE_URL}/dashboard/community/${community_id}/portfolio/distribution`,
      );

      set((state: SetDistribution) => ({
        communitiesDistribution: {
          ...state.communitiesDistribution,
          [community_id]: communityData,
        },
      }));

      return communityData;
    },
  };
});
