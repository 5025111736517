/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 15/12/2020.
 */
import React from "react";
import {
  COLOR_GRAY,
  COLOR_LIGHT_GRAY,
  COLOR_PRIMARY,
} from "../../config/constants";

type Props = {
  filled?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
  children: React.ReactElement;
  outlineColor?: string;
  className?: string;
  disabled?: boolean;
  submit?: boolean;
};

export const Button: React.FC<Props> = ({
  children,
  filled = false,
  className = "",
  onClick,
  outlineColor,
  style = {},
  disabled = false,
  submit = false,
}) => {
  const useOutlineColor = outlineColor ? outlineColor : COLOR_PRIMARY;
  const solid = filled
    ? { color: "white", background: COLOR_PRIMARY, borderColor: COLOR_PRIMARY }
    : disabled
    ? {
        color: COLOR_GRAY,
        background: COLOR_LIGHT_GRAY,
        borderColor: COLOR_LIGHT_GRAY,
      }
    : { color: useOutlineColor, background: "white" };

  return (
    <button
      onClick={onClick}
      className={"py-2 px-4 " + className}
      style={{ borderRadius: 14, border: "1px solid", ...solid, ...style }}
      disabled={disabled}
      {...(submit ? { type: "submit" } : {})}
    >
      {children}
    </button>
  );
};
