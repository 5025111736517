import { useTranslation } from "react-i18next";
import { useInvestmentContext } from "../../../context/InvestmentContext";
import { Button } from "../../display/Button";

export const RevealButton = () => {
  const { setRevealed } = useInvestmentContext();
  const { t } = useTranslation();

  const reveal = () => {
    setRevealed?.(true);
  };

  return (
    <Button onClick={reveal}>
      <p className="mb-0">{t("rebalance.revealBtn")}</p>
    </Button>
  );
};
