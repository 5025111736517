import { t } from "i18next";
import { useEffect, useState } from "react";

import { get } from "../../../service/API";
import { LoadingTypeEnum, useOverlayStore } from "../../../store/OverlayStore";

type AvailabilityType = {
  requestStatus: "loading" | "success" | "failed";
  available?: boolean;
  message?: {
    title: string;
    text: string;
  };
  err?: string;
};

export const AvailabilityOverlay = ({
  availabilityPath,
}: {
  availabilityPath: string;
}) => {
  const [availability, setAvailability] = useState<AvailabilityType>({
    requestStatus: "loading",
  });

  const { setLoading, clearLoading } = useOverlayStore();

  const fetchAvailability = async () => {
    try {
      const currentTheme = await get(availabilityPath);
      setAvailability({ ...currentTheme, requestStatus: "success" });
    } catch (err: any) {
      setAvailability({ requestStatus: "failed", err: err.toString() });
    }
  };

  useEffect(() => {
    if (availability.requestStatus === "loading")
      setLoading(true, LoadingTypeEnum.Overlay);
    else clearLoading();
  }, [availability.requestStatus]);

  useEffect(() => {
    fetchAvailability();
  }, []);

  if (availability.requestStatus === "success" && availability.available) {
    return null;
  }

  return (
    <div
      className="position-absolute d-flex justify-content-center align-items-center w-100 h-100"
      style={{
        background: "rgba(255, 255, 255, 0.8)",
        zIndex: 1000,
      }}
    >
      {/* {availability.requestStatus === "loading" && <Spinner />} */}
      {availability.requestStatus === "failed" && (
        <div>{t("error.permissionError")}</div>
      )}
      {availability.requestStatus === "success" && !availability.available && (
        <div>
          <div className="fw-bold text-center">
            {availability.message?.title}
          </div>
          {availability.message?.text && (
            <div
              className="mt-4"
              dangerouslySetInnerHTML={{ __html: availability.message?.text }}
            />
          )}
        </div>
      )}
    </div>
  );
};
