import React from "react";
import Popup from "reactjs-popup";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import { Button } from "../../components/display/Button";
import { lookUpData } from "../../config/constants"; // Import your data file
import { Trans } from "react-i18next";

type ContentProps = {
  theme_id: number;
};
const Content = ({ theme_id }: ContentProps) => {
  if (theme_id === 101) {
    return (
      <div>
        <p>
          <Trans
            i18nKey={"investmentThemes.description.renewableEnergy"}
          ></Trans>
        </p>
      </div>
    );
  } else if (theme_id === 102) {
    return (
      <div>
        <p>
          <Trans
            i18nKey={"investmentThemes.description.innovativeDiseaseControl"}
          ></Trans>
        </p>
      </div>
    );
  } else if (theme_id === 103) {
    return (
      <div>
        <p>
          <Trans i18nKey={"investmentThemes.description.electricCars"}></Trans>
        </p>
      </div>
    );
  } else if (theme_id === 104) {
    return (
      <div>
        <p>
          <Trans i18nKey={"investmentThemes.description.equality"}></Trans>
        </p>
      </div>
    );
  } else if (theme_id === 105) {
    return (
      <div>
        <p>
          <Trans i18nKey={"investmentThemes.description.health"}></Trans>
        </p>
      </div>
    );
  } else if (theme_id === 106) {
    return (
      <div>
        <p>
          <Trans
            i18nKey={"investmentThemes.description.greenTransition"}
          ></Trans>
        </p>
      </div>
    );
  } else if (theme_id === 107) {
    return (
      <div>
        <p>
          <Trans
            i18nKey={"investmentThemes.description.sustainableInfrastructure"}
          ></Trans>
        </p>
      </div>
    );
  } else if (theme_id === 108) {
    return (
      <div>
        <p>
          <Trans i18nKey={"investmentThemes.description.socialImpact"}></Trans>
        </p>
      </div>
    );
  } else if (theme_id === 109) {
    return (
      <div>
        <p>
          <Trans
            i18nKey={"investmentThemes.description.waterResources"}
          ></Trans>
        </p>
      </div>
    );
  } else if (theme_id === 110) {
    return (
      <div>
        <p>
          <Trans
            i18nKey={"investmentThemes.description.sustainableFoods"}
          ></Trans>
        </p>
      </div>
    );
  }

  return null;
};

type Props = {
  theme_id: number;
  onClose: () => void;
  isButton: boolean;
  onButtonClick?: () => void;
  isActive?: boolean;
  open: boolean;
};

export const Modal: React.FC<Props> = ({
  theme_id,
  onClose,
  isButton,
  onButtonClick,
  isActive,
  open,
}: Props) => {
  return (
    <Popup
      className="rating-info-popup"
      onClose={onClose}
      open={open}
      modal
      nested
    >
      <div className="container-fluid mh-100 mb-3 px-5 py-4 relative">
        <div
          onClick={onClose}
          className="rounded-full p-4 pointer absolute right-0 top-0"
        >
          <CloseIcon />
        </div>
        <div className="flex">
          <div className="w-full mt-3">
            <h3>{lookUpData[theme_id].title}</h3>
            <Content theme_id={theme_id} />
            {isButton && (
              <Button
                filled
                onClick={() => {
                  if (!isActive && onButtonClick) {
                    onButtonClick();
                  }
                  onClose();
                }}
              >
                <p className="mb-0">Vælg tema</p>
              </Button>
            )}
          </div>
        </div>
      </div>
    </Popup>
  );
};
