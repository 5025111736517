import { ClientType } from "./../../components/navigation/Navigation";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { StateCreator, create } from "zustand";
import { CONFIG_CENTER_API, MY_BASE_URL } from "../../config/constants";
import { FRONT_PAGE, LOGIN } from "../../routes";
import { get as getRequest } from "../../service/API";
import axiosInstance from "../../service/axiosInstance";

export const tokenKey = "doland-token";
export const refreshTokenKey = "doland-refreshtoken";
export const previewTokenKey = "doland-preview-token";
interface AuthState {
  token: string | null;
  previewToken: string | null;
  redirectPath: string | null;
  roles: ClientType[] | null;
  user: any | null;
  validateAndRedirect: (tokenParam?: string) => void;
  resetRedirectPath: () => void;
  loginTrial: (password: string, email: string) => Promise<any>;
  loginDoland: (password: string, email: string) => Promise<any>;
  login: (password: string, email: string) => Promise<any>;
  getDolandUser: () => Promise<any>;
  validateTrialToken: () => Promise<any>;
  logout: () => void;
  updateUser: (user: any) => void;
  hasTrialAccess: () => boolean;
  hasVoteAccess: () => boolean;
}

export const getToken = () => {
  return localStorage.getItem(tokenKey);
};

export const getPreviewToken = () => {
  return localStorage.getItem(previewTokenKey);
};

const handleError = (error: any, context: string) => {
  // Log the error only if it's not a 500 status (or adjust as needed)
  if (!axios.isAxiosError(error) || error.response?.status !== 500) {
    console.error(`Error in ${context}:`, error);
  }
  throw error; // Re-throw the error for further handling
};

const authStateCreator: StateCreator<AuthState> = (set, get) => ({
  token: getToken(),
  previewToken: getPreviewToken(),
  redirectPath: null,
  user: null,
  roles: null,
  hasTrialAccess: () => {
    const roles = get().roles;
    if (!roles) return false;
    return (
      roles.includes(ClientType.Business) && roles.includes(ClientType.Trial)
    );
  },

  hasVoteAccess: () => {
    const roles = get().roles;
    if (!roles) return false;
    return roles.includes(ClientType.Vote);
  },

  validateAndRedirect: tokenParam => {
    const token = get().token;
    const previewToken = get().previewToken;

    if (token || previewToken) {
      set({ redirectPath: FRONT_PAGE.to });
    } else if (!tokenParam) {
      set({ redirectPath: LOGIN.to });
    } else {
      try {
        jwtDecode(tokenParam);
        set({ redirectPath: null }); // No redirection needed
      } catch (error) {
        set({ redirectPath: LOGIN.to }); // Invalid token, redirect to login
      }
    }
  },

  resetRedirectPath: () => {
    set({ redirectPath: null });
  },

  loginDoland: async (password: string, email: string) => {
    try {
      const response = await axios.post(`${MY_BASE_URL}/dashboard/auth`, {
        email,
        password,
      });
      if (response.status === 401) {
        return "Dit password eller brugernavn er ikke korrekt - prøv igen";
      }
      if (response.status === 200 && response.data.token) {
        set({ token: response.data.token });
        localStorage.setItem(tokenKey, response.data.token);
        await get().getDolandUser();
        //window.location.reload();
        return response.data;
      }
    } catch (error) {
      handleError(error, "loginDoland");
    }
  },

  getDolandUser: async () => {
    try {
      const [user, userType] = await Promise.all([
        getRequest(`${MY_BASE_URL}/dashboard/profile/personal`),
        getRequest(`${MY_BASE_URL}/dashboard/profile/name`),
      ]);
      set({
        user: user,
        roles:
          userType.client_type === "personal"
            ? [ClientType.Personal]
            : [ClientType.Business],
      });
    } catch (error) {
      console.error("Failed to get user details for doland user: ", error);
      return "DoLand kunne ikke hente din bruger - prøv igen";
    }
  },

  loginTrial: async (password: string, email: string) => {
    try {
      const response = await axios.post(
        `${CONFIG_CENTER_API}/api/auth/dashboard`,
        { email, password },
      );
      if (response.status === 200 && response.data.token) {
        set({
          previewToken: response.data.token,
          roles: response.data.user.roles,
          user: response.data.user,
        });
        localStorage.setItem(previewTokenKey, response.data.token);
        window.location.reload();
        return response.data;
      }
      console.log(response);
    } catch (error) {
      handleError(error, "loginTrial");
    }
  },

  validateTrialToken: async () => {
    try {
      const response = await axiosInstance.get(
        `${CONFIG_CENTER_API}/api/auth/dashboard/validate-token`,
      );
      if (response.status === 200 && response.data.token) {
        set({
          previewToken: response.data.token,
          roles: response.data.user.roles,
          user: response.data.user,
        });
        localStorage.setItem(previewTokenKey, response.data.token);
        return response.data;
      }
    } catch (error) {
      console.error("Token validation failed for trial user:", error);
      return "DoLand kunne ikke hente din bruger - prøv igen";
    }
  },

  updateUser: (user: any) => {
    set({ user });
  },

  login: async (password: string, email: string) => {
    try {
      return await get().loginDoland(password, email);
    } catch (error) {
      return await get().loginTrial(password, email);
    }
  },

  logout: () => {
    set({ token: null, previewToken: null, roles: [] });
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(previewTokenKey);
    window.location.replace("/");
  },
});

export const authStore = create<AuthState>(authStateCreator);
