/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 28-09-2020.
 */
import React, { useState } from "react";
import { a, useSpring } from "react-spring";
import StockIcon from "../../assets/svg/chartIcons/StockIcon";
import PieChartIcon from "../../assets/svg/chartIcons/PieChartIcon";

type Props = {
  setValue: (value: boolean) => void;
  initialValue: boolean;
};

export const ToggleSwitch: React.FC<Props> = ({ setValue, initialValue }) => {
  const pillWidth = 50;
  const switchWidth = 120;
  const switchHeight = 25;
  const [toggleValue, setToggleValue] = useState(initialValue);
  const [transform, set] = useSpring(() => ({
    transform: `translate3d(0px,0px,0px)`,
    config: { friction: 30, tension: 400 },
  }));

  const click = () => {
    const value = !toggleValue;
    setToggleValue(value);

    set({
      transform: `translate3d(${
        value ? 0 : switchWidth - pillWidth
      }px,0px,0px)`,
    });
    setValue(value);
  };

  return (
    <div className="flex justify-center items-center" onClick={click}>
      <PieChartIcon
        className="mr-2"
        width={switchHeight + 10}
        height={switchHeight + 10}
        style={{
          opacity: toggleValue ? 1 : 0.4,
        }}
      ></PieChartIcon>

      <div
        className="relative rounded-full"
        style={{
          height: switchHeight,
          width: switchWidth,
          background: "#dfdfdf",
        }}
      >
        <a.div
          className="pointer absolute bg-primary rounded-full"
          style={{
            width: pillWidth,
            height: switchHeight,
            ...(transform as any),
          }}
        ></a.div>
      </div>
      <StockIcon
        className="ml-2"
        width={switchHeight + 10}
        height={switchHeight + 10}
        style={{
          opacity: toggleValue ? 0.4 : 1,
        }}
      ></StockIcon>
      <p></p>
    </div>
  );
};
