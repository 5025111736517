/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 15/12/2020.
 */

import React, { useState } from "react";
import Popup from "reactjs-popup";
import "./invest-more-modal.scss";
import { ButtonGreen } from "../display/ButtonGreen";

import { get } from "../../service/API";
import { BASE_URL } from "../../config/constants";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import { useTranslation } from "react-i18next";

export const InvestMoreModal: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<{
    account_no: string;
    iban: string;
    reg_no: string;
  }>({
    account_no: "",
    iban: "",
    reg_no: "",
  });

  const { t } = useTranslation();

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(!open);

    const p = BASE_URL + "/dashboard/user/get_bank_account";
    get(p).then((res: any) => {
      setState(res);
    });
  };

  return (
    <>
      <ButtonGreen onClick={openModal} filled>
        <p
          className="p-button mb-0"
          style={{ letterSpacing: -1, fontSize: "15px" }}
        >
          {t("generic.investMore")}
        </p>
      </ButtonGreen>

      <Popup
        onClose={closeModal}
        open={open}
        modal
        nested
        className="board-popup"
        position="bottom center"
        /*closeOnDocumentClick*/
      >
        <div className="container-fluid mb-3 mt-3 px-5 py-4">
          <div className="row">
            <div className="col-12 d-flex justify-content-between">
              <h5>{t("dashboard.investMoreModal.title")}</h5>
              <div>
                <div
                  onClick={closeModal}
                  className="rounded-full pointer flex justify-center"
                  style={{
                    width: 25,
                    height: 25,
                    background: "rgba(226,226,226,.5)",
                  }}
                >
                  <CloseIcon className="flex self-center"></CloseIcon>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <p>{t("dashboard.investMoreModal.content")}</p>
          </div>
          <div className="col-12">
            <p className="mb-0">{t("dashboard.investMoreModal.accountInfo")}</p>
            <hr className="my-2" />
            {state.account_no === "" ? (
              <div className="d-flex mt-3">
                <div
                  className="spinner-border spinner-border-sm mb-0"
                  role="status"
                ></div>
                <p className="ml-2 mb-1">
                  {t("dashboard.investMoreModal.henterData")}
                </p>
              </div>
            ) : (
              <p className="mb-0 mt-3">
                {t("accountInfo.regNum")} <b>{state.reg_no}</b>{" "}
                {t("accountInfo.accountNum")} <b> {state.account_no}</b>
              </p>
            )}
            <hr className="my-2" />
          </div>
        </div>
      </Popup>
    </>
  );
};
