import React from "react";
import { useParams } from "react-router-dom";
import { ProfileProvider } from "./ProfileProvider";
import "./profile.css";
import { IncomeSection, MyInfoSection, RiskSection } from "./sections";
import { ProfileMenu } from "./ProfileMenu";

export const ProfilePage = () => {
  const { section } = useParams<any>();

  return (
    <ProfileProvider>
      <div className="container mt-6 position-relative">
        <div className="col-12">
          <div className={"profile-content"}>
            <ProfileMenu />
            <div className={"flex-grow-1"}>
              {section === "info" && <MyInfoSection />}
              {section === "risk" && <RiskSection />}
              {section === "income" && <IncomeSection />}
            </div>
          </div>
        </div>
      </div>
    </ProfileProvider>
  );
};
