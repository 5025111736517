import { Location, NavigateFunction } from "react-router-dom";
import { REBALANCING_INTRO_PAGE } from "../routes";

type RebalancingCheckFunction = (
  location: Location,
  navigate: NavigateFunction,
) => void;

export const useRebalancingCheck: RebalancingCheckFunction = (
  location: Location,
  navigate: NavigateFunction,
) => {
  if (new URLSearchParams(location.search).get("rebalancing") === "true") {
    navigate(REBALANCING_INTRO_PAGE.to);
  }
};
