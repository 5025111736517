/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 07/03/2021.
 */

import { useMediaQuery } from "react-responsive";

type Props = {
  children: any;
  breakpoint?: any;
};

export const Mobile = ({ children }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
