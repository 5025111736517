import { useInvestmentContext } from "../../../context/InvestmentContext";

export const ApprovedOverlay = () => {
  const { approved } = useInvestmentContext();

  if (!approved?.approved) {
    return null;
  }

  return (
    <div
      className="position-absolute d-flex justify-content-center align-items-center w-100 h-100"
      style={{
        background: "rgba(255, 255, 255, 0.8)",
        zIndex: 1000,
      }}
    >
      <div>
        <div className="fw-bold text-center">{approved?.message?.title}</div>
        {approved?.message?.text && (
          <div
            className="mt-4"
            dangerouslySetInnerHTML={{ __html: approved?.message?.text }}
          />
        )}
      </div>
    </div>
  );
};
