import React from "react";
import { Button } from "../../../components/display/Button";
import "./sections.scss";
import { COLOR_GREEN } from "../../../config/constants";

interface SaveSuccessMessageProps {
  simplified?: boolean;
}

const SaveSuccessMessage = ({ simplified }: SaveSuccessMessageProps) => {
  return (
    <div className="bg-green rounded-2xl p-4 mb-8">
      {simplified ? (
        <div className="fw-bold text-background">Dine ændringer er gemt</div>
      ) : (
        <>
          <div className="fw-bold mb-3 text-background">
            Dine ændringer er gemt
          </div>
          <p className="text-background">
            Du bør derfor efterfølgende lave en justering af din portefølje, så
            den passer til din nye profil (&quot;rebalancering&quot;). Hvis du
            skal foretage andre ændringer af din profil, så lav dem før du går
            til rebalancering.
          </p>
          <a href="/dashboard/rebalancering" rel={"noreferrer"}>
            <Button
              outlineColor="background"
              style={{ backgroundColor: COLOR_GREEN }}
              onClick={() => {}}
            >
              <p className="p-button mb-0">
                {"Klik her og gå til rebalancering"}
              </p>
            </Button>
          </a>
        </>
      )}
    </div>
  );
};

export default SaveSuccessMessage;
