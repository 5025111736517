/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 24/09/2021.
 */
import { Trans, useTranslation } from "react-i18next";
import { InvestmentForm } from "../../components/adjust-investment/form/InvestmentForm";
import { RevealButton } from "../../components/adjust-investment/form/RevealButton";
import { ApprovedOverlay } from "../../components/adjust-investment/overlay/ApprovedOverlay";
import { AvailabilityOverlay } from "../../components/adjust-investment/overlay/AvailabilityOverlay";
import { InvestmentSummary } from "../../components/adjust-investment/summary/InvestmentSummary";
import { BASE_URL } from "../../config/constants";
import {
  InvestmentProvider,
  useInvestmentContext,
} from "../../context/InvestmentContext";

const availabilityPath = `${BASE_URL}/dashboard/investment/rebalancing/available`;

const Content = () => {
  const { revealed } = useInvestmentContext();

  return (
    <>
      {!revealed && <RevealButton />}
      <InvestmentForm />
      <InvestmentSummary />
    </>
  );
};

export const RebalancingPage = () => {
  const { t } = useTranslation();
  return (
    <InvestmentProvider>
      <div className="container mt-6 position-relative">
        <ApprovedOverlay />
        <AvailabilityOverlay availabilityPath={availabilityPath} />
        <div className="col-12">
          <h2>{t("rebalance.dinInvesteringHeader")}</h2>
          <p className="mt-3 flex flex-col gap-4">
            <Trans i18nKey={"rebalance.dinInvesteringText"}>
              {""}
              {""}
              <a href="/profile/risk"></a>
              {""}
            </Trans>
          </p>
        </div>
        <Content />
      </div>
    </InvestmentProvider>
  );
};
