import { InvestmentPageType } from "./../../context/InvestmentContext";

export const pageTypeToURLSegment = (pageType: InvestmentPageType) => {
  switch (pageType) {
    case InvestmentPageType.themeChange:
      return "theme_change";
    case InvestmentPageType.rebalancing:
      return "rebalancing";
    case InvestmentPageType.investMore:
      return "invest_more";
    default:
      throw new Error(`Unhandled page type: ${pageType}`);
  }
};
