import { Form, Formik, FormikProps } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Button } from "../../../components/display/Button";
import Spinner from "../../../components/spinner/Spinner";
import { MY_BASE_URL } from "../../../config/constants";
import { get, post } from "../../../service/API";
import { useRebalancingCheck } from "../../../utils/navigation";
import { useProfileContext } from "../ProfileProvider";
import { FormikRadio } from "../fields";
import { RiskResponse } from "../types/RiskResponse";
import { RiskChart } from "./RiskChart";
import SaveSuccessMessage from "./SaveSuccessMessage";
import "./sections.scss";
import { Trans, useTranslation } from "react-i18next";

const RiskSchema = Yup.object().shape({
  horizon: Yup.number().required("Required"),
  purpose: Yup.number().required("Required"),
  reaction_loss: Yup.number().required("Required"),
  risk: Yup.number().required("Required"),
});

export const RiskSection = () => {
  const { t } = useTranslation();
  const riskSectionTitle = t("profile.risk.riskSectionTitle");
  const riskSectionDescription = (
    <p className="p-small">
      <Trans i18nKey={"profile.risk.riskSectionDescription"}></Trans>
    </p>
  );

  const riskOptions = [
    {
      value: 15,
      label: t("profile.risk.little"),
      title: "Lav risiko",
      description: t("profile.risk.littleDescription"),
    },
    {
      value: 30,
      label: t("profile.risk.middle"),
      title: "Middel risiko",
      description: t("profile.risk.middleDescription"),
    },
    {
      value: 40,
      label: t("profile.risk.much"),
      title: "Høj risiko",
      description: t("profile.risk.muchDescription"),
    },
    {
      value: 50,
      label: t("profile.risk.veryMuch"),
      title: "Meget høj risiko",
      description: t("profile.risk.veryMuchDescription"),
    },
  ];

  const { saved, setSaved } = useProfileContext();
  const [initialValues, setInitialValues] = useState<RiskResponse | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    get(`${MY_BASE_URL}/dashboard/profile/risk`).then(setInitialValues);
  }, []);

  const onSubmit = (values: RiskResponse, { setSubmitting }: any) => {
    post(`${MY_BASE_URL}/dashboard/profile/risk`, values)
      .then(() => {
        setSubmitting(false);
        setSaved?.(true);
        useRebalancingCheck(location, navigate);
      })
      .catch((err: any) => {
        console.log(err);
        setSubmitting(false);
      });
  };

  return (
    <div>
      <div className={"text-primary mb-5"}>{t("profile.risk.header")}</div>
      {saved && <SaveSuccessMessage />}

      {initialValues == null && (
        <div className="col-12">
          <div className="my-6 d-flex justify-content-center">
            <Spinner />
          </div>
        </div>
      )}

      {initialValues != null && (
        <Formik {...{ initialValues, onSubmit }} validationSchema={RiskSchema}>
          {({ isSubmitting, values }: FormikProps<any>) => {
            const selectedRisk = values?.risk;
            let riskDescription = "";
            let riskTitle = "";
            if (selectedRisk) {
              const selectedRiskOption = riskOptions.find(
                riskOption => riskOption.value === selectedRisk,
              );
              riskTitle = selectedRiskOption?.title || "";
              riskDescription = selectedRiskOption?.description || "";
            }
            return (
              <Form>
                <div className="row">
                  <div className="col-12">
                    {/* Risk */}
                    <div className="risk-level-title-wrapper">
                      <p>
                        <b>{riskSectionTitle}</b>
                      </p>
                      {riskSectionDescription}
                    </div>
                    <div className="px-3">
                      <p>
                        <b>{riskTitle}</b>
                      </p>
                      <p>{riskDescription}</p>
                    </div>
                    <FormikRadio
                      name="risk"
                      label=""
                      options={riskOptions.map(riskOption => ({
                        value: riskOption.value,
                        label: riskOption.label,
                      }))}
                    >
                      <div className="mb-5">
                        <RiskChart selectedValue={values.risk} />
                      </div>
                    </FormikRadio>
                    <div className="mt-4 mx-3 risk-level-disclaimer">
                      <p className="p-small mb-2 flex">
                        <Trans i18nKey={"profile.risk.disclaimer"}></Trans>
                      </p>
                    </div>
                  </div>

                  <div className="col-12 mt-6">
                    {/* Reaktion ved fald: reaction in case of all */}
                    <FormikRadio
                      name="reaction_loss"
                      label={t("profile.risk.reactionLossTitle")}
                      options={[
                        {
                          value: 1,
                          label: t("profile.risk.reactionLoss1"),
                        },
                        {
                          value: 2,
                          label: t("profile.risk.reactionLoss2"),
                        },
                        {
                          value: 3,
                          label: t("profile.risk.reactionLoss3"),
                        },
                        { value: 4, label: t("profile.risk.reactionLoss4") },
                      ]}
                      vertical
                    />
                  </div>
                </div>

                <div className="row mt-6">
                  <div className="col-12">
                    {/* Horisont */}
                    <FormikRadio
                      name="horizon"
                      label={t("profile.risk.horizonTitle")}
                      description={t("profile.risk.horizonDescription")}
                      options={[
                        { value: 1, label: t("profile.risk.horizon1") },
                        { value: 2, label: t("profile.risk.horizon2") },
                        { value: 3, label: t("profile.risk.horizon3") },
                        { value: 4, label: t("profile.risk.horizon4") },
                      ]}
                    />
                  </div>
                </div>

                <div className="row mt-6">
                  <div className="col-12">
                    <FormikRadio
                      name="purpose"
                      label={t("profile.risk.purposeTitle")}
                      vertical
                      options={[
                        {
                          value: 1,
                          label: t("profile.risk.purpose1"),
                        },
                        {
                          value: 2,
                          label: t("profile.risk.purpose2"),
                        },
                        {
                          value: 3,
                          label: t("profile.risk.purpose3"),
                        },
                        { value: 4, label: t("profile.risk.purpose4") },
                      ]}
                    />
                  </div>
                </div>

                <div className="mt-6 mb-6 d-flex justify-content-center">
                  <Button disabled={isSubmitting} filled submit>
                    <p className="mb-0">{t("profile.updateInfo")}</p>
                  </Button>
                  {isSubmitting && (
                    <div style={{ marginTop: -5, marginLeft: 40 }}>
                      <Spinner />
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
