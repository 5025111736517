import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { lookUpData } from "../../../config/constants";
import { useResizeObserver } from "../../../hooks/useReziseObserver";
import { useTranslation } from "react-i18next";

const CustomTooltip: React.FC<any> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const themeId = payload[0].name;
    const theme = lookUpData[themeId];

    return (
      <div className="bg-white p-2 border border-primary rounded items-center  flex flex-row">
        <img src={theme.icon} alt="Theme Icon" className="h-10 w-10" />
        <p className="pl-2 text-sm self-center my-0">{theme.title}</p>
      </div>
    );
  }

  return null;
};

const ThemePieChart = ({
  themes,
}: {
  themes: { id: number; rating: number }[];
}) => {
  const [size, ref] = useResizeObserver();
  const { width, height } = size;
  const innerRadius = (Math.min(width, height) / 2) * 0.65;
  const outerRadius = (Math.min(width, height) / 2) * 1;
  const { t } = useTranslation();
  return (
    <div className="w-full h-[60%] flex justify-center items-center">
      <div className="w-[80%] h-full max-w-[500px] mb-12 sm:mb-0" ref={ref}>
        <ResponsiveContainer
          width="100%"
          height="100%"
          minWidth={200}
          minHeight={200}
          maxHeight={500}
        >
          <PieChart>
            <Pie
              data={themes}
              dataKey="rating"
              nameKey="id"
              cx="50%"
              cy="50%"
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              fill="#8884d8"
            >
              {themes.map(entry => (
                <Cell
                  key={`cell-${entry.id}`}
                  fill={lookUpData[entry.id].color}
                />
              ))}
              <foreignObject x="40%" y="45%" width="100%" height="100%">
                <span className="text-lg font-semibold text-brown font-serif">
                  {t("myThemes.header")}
                </span>
              </foreignObject>
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ThemePieChart;
