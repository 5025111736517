/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 11-11-2020.
 */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { INewsArticle, newsStore } from "../../store/NewsStore";
import { getTimestamp } from "../../utils/getDate";
import { ReactComponent as ArrowSvg } from "../../assets/svg/arrow.svg";
import { useHover } from "react-use-gesture";
import { a, useSpring } from "react-spring";
import { ErrorComponent } from "../../components/ErrorComponent";
import { useTranslation } from "react-i18next";
import { LoadingTypeEnum, useOverlayStore } from "../../store/OverlayStore";

export const NewsArticle: React.FC = () => {
  const { newsId } = useParams<{ newsId: string }>();

  const [error, setError] = useState<string>("");
  const [article, setArticle] = useState<INewsArticle | null>(null);

  const { setLoading, clearLoading } = useOverlayStore();

  const getNewsArticle = newsStore(state => state.getNewsArticle);

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true, LoadingTypeEnum.Blocking);
    if (!newsId) return;
    getNewsArticle(newsId)
      .then((res: INewsArticle) => {
        setArticle(res);
      })
      .catch((err: any) => {
        const status = err.status ? "status " + err.status : "";
        setError(t("error.articleError") + status);
      })
      .finally(() => clearLoading());
  }, [newsId, getNewsArticle, t]);

  const [aniProps, set] = useSpring(() => ({
    transform: `translate3d(0px,0,0)`,
    config: { mass: 1, tension: 600, friction: 20 },
  }));

  const bind = useHover(({ active }) => {
    set({
      transform: `translate3d(${active ? -10 : 0}px,0,0)`,
    });
  });

  if (error) {
    return <ErrorComponent error={error}></ErrorComponent>;
  }

  if (article === null) {
    return <div>loading</div>;
  }

  const timestamp = getTimestamp(article.timestamp);

  return (
    <div className="container">
      <div className="row h-100">
        <div className="col-12 h-100 mt-3 mt-md-4">
          <img className="w-100" src={article.content.images[0]} alt="" />
        </div>
        <div className="col-12 d-flex pt-2 justify-content-between">
          <Link {...bind()} to="/" className="text-decoration-none ">
            <a.div style={aniProps} className="d-flex">
              <ArrowSvg
                style={{
                  marginTop: -22,
                  width: 30,
                  height: "auto",
                  transform: "rotate(-180deg)",
                }}
              ></ArrowSvg>

              <p className="text-muted p-news-tag pl-2">{t("generic.back")}</p>
            </a.div>
          </Link>
          <p className="text-muted p-news-tag">
            {article.content.themes[0].title.toUpperCase()}
          </p>
          <p className="text-muted p-news-tag">
            {timestamp.toString().toUpperCase()}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-6 col-md-8 mx-auto">
          <h1 className="text-primary mb-0">{article.content.title}</h1>
        </div>
        <div className="col-12 col-md-8 mx-auto mt-4 mt-lg-6">
          <p
            style={{ color: "black" }}
            dangerouslySetInnerHTML={{ __html: article.content.body }}
          />
        </div>
        {article.content.sources && (
          <div className="col-12 col-md-8 mx-auto mb-6">
            <hr />
            <p
              className="text-right "
              style={{ color: "#7e7e7e" }}
              dangerouslySetInnerHTML={{ __html: article.content.sources }}
            />
          </div>
        )}

        <div className="col-12 col-md-8 mx-auto mt-6 mb-6"></div>
      </div>
    </div>
  );
};
