import { create } from "zustand";
import { MY_BASE_URL } from "../config/constants";
import { get as getRequest } from "../service/API";
import { BalanceResponse, DistrobutionResponse } from "../types/investment";

export type FrontPageStoreState = {
  distribution: DistrobutionResponse | null;
  periodAll: BalanceResponse | null;
  getDistribution: (hasTrialAccess: boolean) => Promise<DistrobutionResponse>;
  getPeriodAll: (hasTrialAccess: boolean) => Promise<BalanceResponse>;
};

export const frontPageStore = create<FrontPageStoreState>(
  (set: any, get: any) => {
    return {
      distribution: null,
      periodAll: null,
      getDistribution: async (hasTrialAccess: boolean) => {
        const cachedDistribution = get().distribution;
        if (cachedDistribution) {
          return cachedDistribution;
        }

        const distribution = await getRequest<DistrobutionResponse>(
          `${MY_BASE_URL}${
            hasTrialAccess ? "/trial" : ""
          }/dashboard/investment/portfolio/distribution`,
        );
        set({ distribution });

        return distribution;
      },
      getPeriodAll: async (hasTrialAccess: boolean) => {
        const cachedPeriodAll = get().periodAll;
        if (cachedPeriodAll) {
          return cachedPeriodAll;
        }

        const periodAll = await getRequest<BalanceResponse>(
          `${MY_BASE_URL}${
            hasTrialAccess ? "/trial" : ""
          }/dashboard/investment/balance/period/all`,
        );
        set({ periodAll });
        return periodAll;
      },
    };
  },
);
