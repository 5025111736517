export enum Risk {
  Low = 15,
  Medium = 30,
  High = 40,
  ExtraHigh = 50,
}

export enum Horizon {
  Short = 1,
  Medium = 2,
  Long = 3,
  ExtraLong = 4,
}

export enum Purpose {
  Learn = 1,
  Part = 2,
  Save = 3,
  Other = 4,
}

export enum LossReaction {
  Invest = 1,
  Nothing = 2,
  SellPart = 3,
  SellAll = 4,
}
