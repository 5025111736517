import { useEffect, useState } from "react";
import { PieChartComp } from "../../PieChartComp";
import { lookUpData } from "../../../config/constants";
import React from "react";
import { useInvestmentContext } from "../../../context/InvestmentContext";

interface PieChartData {
  id: number;
  title: string;
  percentage: number; // percentage
  color: string;
  icon: string; // new - for the icon path
}

export const InvestmentPieChart = () => {
  const { selectedThemes } = useInvestmentContext();

  const [chartData, setChartData] = useState<PieChartData[]>([]);

  useEffect(() => {
    if (!selectedThemes) return;
    // Total rating calculation
    const totalRating = selectedThemes.reduce(
      (total, theme) => total + theme.rating,
      0,
    );

    // Creating data for the pie chart
    const newChartData = selectedThemes.map(theme => {
      // Get the display properties from lookUpData
      const themeData = lookUpData[theme.theme_id] || {}; // default to an empty object if no match is found

      return {
        id: theme.theme_id,
        title: themeData.title || "Unknown", // default title in case it doesn't exist
        percentage: theme.rating / totalRating, // percentage calculation
        color: themeData.color || "#000", // default color in case it doesn't exist
        icon: themeData.icon, // new - adding icon path
      };
    });

    setChartData(newChartData);
  }, [selectedThemes]);

  return (
    <div className="mt-3" style={{ paddingLeft: 48, paddingRight: 48 }}>
      <PieChartComp
        data={{ portfolio: chartData, total_amount: 0 }}
        showInvestMore={false}
        showTotalAmount={false}
        title="Mine temaer"
        excludeThemes={[0, 1]}
      />
    </div>
  );
};
