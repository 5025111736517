/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 07/06/2021.
 */
import { t } from "i18next";
import React from "react";

type Props = {
  isPct: boolean;
  setViewType: (v: boolean) => void;
};

const radius = 20;
const offOpacity = 0.4;

export const ViewSwitch = ({ isPct, setViewType }: Props) => {
  const setViewPct = () => {
    setViewType(true);
  };

  const setViewAmount = () => {
    setViewType(false);
  };

  return (
    <div className="d-flex" style={{ maxHeight: 33 }}>
      <div
        onClick={setViewPct}
        className="px-4 pointer"
        style={{
          border: `1px solid rgba(0,0,0,${isPct ? 1 : offOpacity})`,
          borderTopLeftRadius: radius,
          borderBottomLeftRadius: radius,
        }}
      >
        <p
          className="mb-0 mt-1 "
          style={{ fontSize: 16, opacity: isPct ? 1 : offOpacity }}
        >
          %
        </p>
      </div>

      <div
        onClick={setViewAmount}
        className="px-3 pointer"
        style={{
          border: `1px solid rgba(0,0,0,${isPct ? offOpacity : 1})`,
          borderTopRightRadius: radius,
          borderBottomRightRadius: radius,
        }}
      >
        <p
          className="mb-0 mt-2 pr-2 "
          style={{ fontSize: 13, opacity: isPct ? offOpacity : 1 }}
        >
          {t("generic.danishCurrency")}
        </p>
      </div>
    </div>
  );
};
