import Cash from "../assets/svg/InvestmentThemeIcons/Cash.svg";

import SocialImpactIcon from "../assets/svg/InvestmentThemeIcons/SocialImpact.svg";
import WaterResourceIcon from "../assets/svg/InvestmentThemeIcons/Vandressourcer.svg";
import SustainableFoodsIcon from "../assets/svg/InvestmentThemeIcons/BaredyktigeFodevarer.svg";
import SustainableInfrastructureIcon from "../assets/svg/InvestmentThemeIcons/BaredyktigeByer.svg";
import GreenTransitionIcon from "../assets/svg/InvestmentThemeIcons/GronOmstilling.svg";
import BestEsgIcon from "../assets/svg/InvestmentThemeIcons/BestInClass.svg";
import EqualityIcon from "../assets/svg/InvestmentThemeIcons/LigestillingOgDiversitet.svg";
import HealthIcon from "../assets/svg/InvestmentThemeIcons/SundhedVelvare.svg";
import RenewableEnergyIcon from "../assets/svg/InvestmentThemeIcons/VedvarendeEnergi.svg";
import InnovativeDiseaseControlIcon from "../assets/svg/InvestmentThemeIcons/InnovativSygdom.svg";
import ElectricCarsIcon from "../assets/svg/InvestmentThemeIcons/ElektriskeBiler.svg";

//i18n
import i18n from "../utils/i18n";

//Config center endpoint
export const CONFIG_CENTER_API = "https://admin-service.thisisdoland.com";
//ENDPOINTS
export const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const MATTER_API_KEY = process.env.REACT_APP_MATTER_API_KEY;
export const MY_BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const DEBUG = process.env.REACT_APP_DEBUG === "true";
export const BASE = process.env.REACT_APP_BASE_URL;

// colors
/* export const COLOR_PLACEHOLDER = "#efefef";
export const COLOR_PRIMARY = "#9129e3";
export const COLOR_INFO = "#00cc99";
export const COLOR_NEGATIVE_NUMBER = "#F47B50";
export const COLOR_GRAY = "#495057"; */

export const COLOR_PLACEHOLDER = "#efefef";
export const COLOR_PRIMARY = "#21A2B9";
export const COLOR_INFO = "#28aa8c";
export const COLOR_NEGATIVE_NUMBER = "#F47B50";
export const COLOR_GRAY = "#495057";
export const COLOR_LIGHT_GRAY = "#ced3d9";
export const COLOR_BROWN = "#4a3f43";
export const COLOR_YELLOW = "#F6D888";
export const COLOR_GREEN = "#28AA8C";
export const COLOR_LIGHT_GREEN = "#98D1CB";
export const COLOR_DARK_BLUE = "#213871";
export const COLOR_TEAL = "#98D1CB";
export const COLOR_LIGHT_YELLOW = "#F6EECD";
export const COLOR_LIGHT_RED = "#F9C5AF";
export const COLOR_RED = "#F0826B";
export const COLOR_LIGHT_BLUE = "#B2D6DB";
export const COLOR_TEAL_100 = "#E0F5F9";
export interface IData {
  [key: string]: {
    color: string;
    icon: string;
    title: string;
  };
}

/*0=>'Kontanter',
  1=>'Best In class ESG',
  101=>'Vedvarende energi',
  102=>'Innovativ sygdomsbekæmpelse',
  103=>'Elektriske biler & teknologi',
   104=>'Ligestilling & diversitet',
  105=>'Sundhed & velvære',
  106=>'Grøn omstilling',
  107=>'Bæredygtige byer & infrastruktur',
  108=>'Social impact',
  109=>'Vandressourcer'*/

export const chartConfig = [
  { label: "1M", value: 30 },
  { label: "3M", value: 90 },
  { label: "1Y", value: 365 },
];
export const trialChartConfig = [
  { label: "1M", value: 30 },
  { label: "3M", value: 90 },
];

export const lookUpData: IData = {
  108: {
    color: COLOR_BROWN,
    icon: SocialImpactIcon,
    title: i18n.t("investmentThemes.socialImpact"),
  },
  109: {
    color: COLOR_DARK_BLUE,
    icon: WaterResourceIcon,
    title: i18n.t("investmentThemes.waterResources"),
  },
  110: {
    color: COLOR_TEAL,
    icon: SustainableFoodsIcon,
    title: i18n.t("investmentThemes.sustainableFoods"),
  },
  107: {
    color: COLOR_GREEN,
    icon: SustainableInfrastructureIcon,
    title: i18n.t("investmentThemes.sustainableInfrastructure"),
  },
  106: {
    color: COLOR_LIGHT_YELLOW,
    icon: GreenTransitionIcon,
    title: i18n.t("investmentThemes.greenTransition"),
  },
  1: {
    color: COLOR_YELLOW,
    icon: BestEsgIcon,
    title: i18n.t("investmentThemes.bestEsg"),
  },
  104: {
    color: COLOR_LIGHT_RED,
    icon: EqualityIcon,
    title: i18n.t("investmentThemes.equality"),
  },
  105: {
    color: COLOR_RED,
    icon: HealthIcon,
    title: i18n.t("investmentThemes.health"),
  },
  101: {
    color: COLOR_LIGHT_BLUE,
    icon: RenewableEnergyIcon,
    title: i18n.t("investmentThemes.renewableEnergy"),
  },
  102: {
    color: COLOR_PRIMARY,
    icon: InnovativeDiseaseControlIcon,
    title: i18n.t("investmentThemes.innovativeDiseaseControl"),
  },
  103: {
    color: COLOR_TEAL_100,
    icon: ElectricCarsIcon,
    title: i18n.t("investmentThemes.electricCars"),
  },
  0: {
    color: "#efefef",
    icon: Cash,
    title: i18n.t("investmentThemes.cash"),
  },
  10001: { color: "", icon: "", title: "" },
};
