/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 23/11/2020.
 */
import React, { useState } from "react";
import { lookUpData } from "../../config/constants";

type Props = {
  id: any;
  index: number;
  themeName: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  // setHover: Function;
  useBackground?: boolean;
  size?: number;
  className?: string;
};

export const ThemeItem: React.FC<Props> = ({
  themeName,
  id = "",
  useBackground = true,
  size = 40,
  className = "",
}) => {
  const theme = lookUpData[id];
  const icon = theme?.icon || "";
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`no-select flex flex-col rounded-circle pointer items-center ${className}`}
      style={{
        width: size,
        minWidth: size,
        height: size,
        background: useBackground ? "white" : "none",
        boxShadow: useBackground ? "0px 1px 12px rgba(0,0,0,.15)" : "none",
      }}
    >
      {icon && (
        <>
          <img className="w-100 h-100 mt-50" src={icon} alt="" />
          <div
            className="relative min-w-max text-xs bg-white bg-opacity-90 drop-shadow-lg p-3 z-2"
            style={{
              visibility: isHovered ? "visible" : "hidden",
            }}
          >
            {themeName}
          </div>
        </>
      )}
    </div>
  );
};
