import { create } from "zustand";

export enum LoadingTypeEnum {
  Overlay,
  Blocking,
  Skeleton,
}

export enum NotificationTypeEnum {
  Info,
  Success,
  Error,
}

export type Loading = {
  value: boolean;
  type: LoadingTypeEnum;
  message?: string;
};

type Notification = {
  message: string;
  type: NotificationTypeEnum;
};

export type OverlayStoreState = {
  loading: Loading;
  notification: Notification | null;

  setLoading: (
    isLoading: boolean,
    type?: LoadingTypeEnum,
    message?: string,
  ) => void;
  clearLoading: () => void;

  setNotification: (message: string, type: NotificationTypeEnum) => void;
  clearNotification: () => void;
};

export const useOverlayStore = create<OverlayStoreState>(set => {
  return {
    loading: { value: false, type: LoadingTypeEnum.Overlay },
    notification: null,

    setLoading: (
      value: boolean,
      type: LoadingTypeEnum = LoadingTypeEnum.Overlay,
      message?: string,
    ) =>
      set(() => ({
        loading: {
          value,
          type,
          message,
        },
      })),

    clearLoading: () =>
      set({ loading: { value: false, type: LoadingTypeEnum.Overlay } }),

    setNotification: (message: string, type: NotificationTypeEnum) =>
      set(() => ({ notification: { message, type } })),

    clearNotification: () => set({ notification: null }),
  };
});
