/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04/06/2021.
 */
import React from "react";
import { Button } from "../../components/display/Button";
import { Desktop } from "../../components/display/Desktop";
import { Mobile } from "../../components/display/Mobile";

type Props = {
  data: any;
  selected: boolean;
  onClick: (v: any) => void;
};

export const TimeSpanBtn = ({ onClick, data, selected }: Props) => {
  const clickker = () => {
    onClick(data);
  };
  return (
    <Button
      onClick={clickker}
      outlineColor="black"
      style={{ opacity: selected ? 1 : 0.3 }}
      className="mr-0 md:mr-5"
    >
      <>
        <Mobile>
          <p className="mb-0 px-0 py-0" style={{ fontSize: ".6rem" }}>
            {data.title}
          </p>
        </Mobile>
        <Desktop>
          <p className="mb-0 px-md-2 py-0">{data.title}</p>
        </Desktop>
      </>
    </Button>
  );
};
