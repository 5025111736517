/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 15/12/2020.
 */
import React from "react";
import { COLOR_GREEN, COLOR_PRIMARY } from "../../config/constants";
import { Link } from "react-router-dom";

type Props = {
  filled?: boolean;
  style?: React.StyleHTMLAttributes<any>;
  onClick?: (e?: any) => void;
  children: React.ReactElement;
  className?: string;
  disabled?: boolean;
  margin?: string;
  white?: boolean;
  url?: string;
  link?: string;
};

export const ButtonGreen: React.FC<Props> = ({
  children,
  filled = false,
  white = false,
  className = "",
  onClick,
  style = {},
  disabled = false,
  margin = "mb-3",
  url,
  link,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (url) {
      window.open(url, "_blank");
    }
    // If neither onClick nor url is provided, do nothing.
  };
  const solid = filled
    ? {
        color: "white",
        background: COLOR_PRIMARY,
        borderColor: COLOR_PRIMARY,
        border: "1px solid",
      }
    : disabled
    ? {
        color: "white",
        background: "Silver",
        borderColor: "Silver",
        border: "1px solid",
      }
    : white
    ? {
        color: COLOR_PRIMARY,
        background: "white",
        borderColor: COLOR_PRIMARY,
        border: "1px solid",
      }
    : {
        color: "white",
        background: COLOR_GREEN,
        borderColor: COLOR_GREEN,
        border: "1px solid",
        display: "block",
      };

  return (
    <div className={margin}>
      {link ? (
        <Link
          to={link}
          className={"py-2 px-4 text-decoration-none" + className}
          style={{
            borderRadius: 10,
            display: "block",
            ...style,
            ...solid,
          }}
        >
          {children}
        </Link>
      ) : (
        <button
          onClick={handleClick}
          className={"py-2 px-4 " + className}
          style={{
            borderRadius: 10,
            display: "block",
            ...style,
            ...solid,
          }}
          disabled={disabled}
        >
          {children}
        </button>
      )}
    </div>
  );
};
