import React from "react";
import { useField } from "formik";
import { useProfileContext } from "../ProfileProvider";
import { COLOR_GRAY } from "../../../config/constants";

type Props = {
  name: string;
  label: string;
  disabled?: boolean;
  type?: string;
  prefix?: string;
  description?: string;
  renderer?: (value: string) => string;
  preprocessor?: (value: string) => string;
};

export const FormikInput = ({
  name,
  label,
  disabled = false,
  type = "text",
  prefix,
  description,
  renderer = val => val,
  preprocessor = val => val,
}: Props) => {
  const { setFormTouched, setSaved } = useProfileContext();
  const [field, meta, helpers] = useField(name);

  const textStyle: React.CSSProperties = {
    textAlign: "left",
  };

  const otherProps: any = {};

  switch (type) {
    case "currency":
    case "number":
      textStyle.textAlign = "right";
      otherProps["data-lpignore"] = "true";
      break;
  }

  const onBlur = (e: React.FocusEvent<any>) => {
    field?.onBlur(e);
    setFormTouched?.(true);
    setSaved?.(false);
  };

  return (
    <div className="">
      <p className="mb-2 text-black fw-bold ">{label}</p>
      <div
        className="w-100 d-flex position-relative align-items-center"
        style={{
          background: disabled ? "rgba(241, 237, 218, 0.5)" : "#F1EDDA",
          borderRadius: 10,
        }}
      >
        <input
          name={field.name}
          value={renderer(field.value)}
          onChange={e => {
            helpers.setValue(preprocessor(e.currentTarget.value));
          }}
          onBlur={onBlur}
          className="p-button w-100 mw-100 py-3 pl-3 pr-2"
          disabled={disabled}
          type={type}
          style={{
            background: "transparent",
            outline: "none",
            border: "none",
            color: meta.error && meta.touched ? "red" : "black",
            ...textStyle,
          }}
          {...otherProps}
        />
        {prefix && (
          <div
            className="text-black p-small fw-bold mr-3 ml-1"
            // style={{
            //   right: 10,
            //   top: 13,
            // }}
          >
            {prefix}
          </div>
        )}
      </div>
      {description && (
        <div className="mt-1">
          <p className="p-small" style={{ color: COLOR_GRAY }}>
            {description}
          </p>
        </div>
      )}
      {meta.error && meta.touched && (
        <div className="">
          <p className="p-small text-danger mt-2 mb-0">{meta.error}</p>
          {/*<hr className="mt-2 mb-1" />*/}
        </div>
      )}
    </div>
  );
};
