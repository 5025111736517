import { Trans, useTranslation } from "react-i18next";
import { InvestmentForm } from "../../components/adjust-investment/form/InvestmentForm";
import { ApprovedOverlay } from "../../components/adjust-investment/overlay/ApprovedOverlay";
import { AvailabilityOverlay } from "../../components/adjust-investment/overlay/AvailabilityOverlay";
import { InvestmentSummary } from "../../components/adjust-investment/summary/InvestmentSummary";
import { BASE_URL } from "../../config/constants";
import { InvestmentProvider } from "../../context/InvestmentContext";
import { Link } from "react-router-dom";

const Content = () => {
  return (
    <>
      <InvestmentForm />
      <InvestmentSummary />
    </>
  );
};

const availabilityPath = `${BASE_URL}/dashboard/investment/invest_more/available`;

export const InvestMorePage = () => {
  const { t } = useTranslation();
  return (
    <InvestmentProvider>
      <div className="container mt-6 position-relative">
        <ApprovedOverlay />
        <AvailabilityOverlay availabilityPath={availabilityPath} />
        <div className="col-12">
          <h2>{t("investMore.header")}</h2>
          <p className="flex flex-col gap-3 mt-3">
            <Trans i18nKey={"investMore.text"}>
              <p></p>
              <p></p>
              <Link to="/dashboard/temaer">{""}</Link>
            </Trans>
          </p>
        </div>
        <Content />
      </div>
    </InvestmentProvider>
  );
};
