// Filename: StockIcon.tsx
import React from "react";

interface StockIconProps {
  id?: string;
  width?: string | number;
  height?: string | number;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
}

const StockIcon: React.FC<StockIconProps> = ({
  id = "Layer_1",
  width = 411.68,
  height = 361.66,
  color = "#20a2b9",
  className = "",
  style,
}) => {
  const fillColor = color;
  const strokeColor = color;

  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 411.68 361.66"
      width={width}
      height={height}
      className={className}
      style={style}
    >
      <defs>
        <style>
          {`.cls-1{fill:none;stroke:${strokeColor};stroke-miterlimit:10;stroke-width:12px;}.cls-2{fill:${fillColor};}`}
        </style>
      </defs>
      <path
        className="cls-2"
        d="m307.43,70.68H123.1c-14.16,0-25.63,11.48-25.63,25.63v184.34c0,14.16,11.48,25.63,25.63,25.63h184.34c14.16,0,25.63-11.48,25.63-25.63V96.31c0-14.16-11.48-25.63-25.63-25.63Zm-9.56,197.83c0,1.43-1.16,2.59-2.59,2.59h-160.05c-1.43,0-2.59-1.16-2.59-2.59V108.46c0-1.43,1.16-2.59,2.59-2.59h160.05c1.43,0,2.59,1.16,2.59,2.59v160.05Z"
      />
      <polyline
        className="cls-1"
        points="128.55 252.22 205.19 188.48 227.51 216.52 285.7 145.02"
      />
    </svg>
  );
};

export default StockIcon;
