import {
  ExclamationCircleIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Field, useField } from "formik";
import { useState } from "react";
import FormikErrorMessage from "./FormikErrorMessage";

interface InputFieldProps {
  name: string;
  type: string;
  label?: string;
  autoComplete?: string;
  id?: string;
  className?: string;
  customValidation?: (value: string) => void;
  customWarning?: (value: string) => Promise<string | undefined>;
}

const InputField: React.FC<InputFieldProps> = ({
  name,
  type,
  label,
  autoComplete,
  id,
  className = "",
  customValidation,
  customWarning,
}) => {
  const fieldId = id || name;
  const [field, meta] = useField({ name, validate: customValidation });
  const hasError = meta.touched && meta.error;

  const [warning, setWarning] = useState<string | null>(null);
  // Add state for password visibility toggle
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // First, call Formik's own onChange event handler to update state
    field.onChange(e);

    // Then do your custom handling
    if (customWarning) {
      const warningMessage = await customWarning(e.target.value);
      setWarning(warningMessage || null);
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={fieldId}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
      )}
      <div className="relative mt-2">
        <Field
          id={fieldId}
          name={name}
          type={isPasswordVisible ? "text" : type}
          autoComplete={autoComplete}
          className={`block w-full rounded-md border py-1.5 text-gray-900 bg-slate-100  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-300 sm:text-sm sm:leading-6 ${
            hasError
              ? "text-red ring-1 ring-inset ring-red placeholder:text-red focus:ring-2 focus:ring-inset focus:ring-red"
              : "block w-full rounded-md border py-1.5 text-gray-900 bg-slate-100  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-300 sm:text-sm sm:leading-6"
          } sm:text-sm sm:leading-6`}
          onChange={handleInputChange} // Use the function here
        />
        {type === "password" && (
          <div
            className={clsx(
              "absolute inset-y-0 flex items-center pr-3 cursor-pointer",
              hasError ? "right-6" : "right-0",
            )}
            onClick={togglePasswordVisibility}
          >
            {isPasswordVisible ? (
              <EyeSlashIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            ) : (
              <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            )}
          </div>
        )}
        {hasError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {warning && <div className="text-yellow text-sm mt-2">{warning}</div>}
      <FormikErrorMessage name={name} />
    </div>
  );
};

export default InputField;
