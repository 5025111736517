import React from "react";
import { a, useSpring } from "react-spring";
import { COLOR_PRIMARY } from "../../config/constants";

type Props = {
  onClick: () => void;
  active: boolean;
  options?: {
    pillWidth: number;
    switchHeight: number;
    switchWidth: number;
    activeColor?: string;
  };
};

export const Toggle = ({
  onClick,
  active,
  options = { pillWidth: 50, switchWidth: 120, switchHeight: 25 },
}: Props) => {
  const {
    pillWidth,
    switchWidth,
    switchHeight,
    activeColor = COLOR_PRIMARY,
  } = options;

  const styles = useSpring({
    transform: `translate3d(${active ? switchWidth - pillWidth : 0}px,0px,0px)`,
    background: active ? activeColor : "#8f8f8f",
    config: { friction: 30, tension: 400 },
  });

  return (
    <div className="d-flex justify-content-center" onClick={onClick}>
      <div
        className="position-relative rounded-pill"
        style={{
          height: switchHeight,
          width: switchWidth,
          background: "#dfdfdf",
        }}
      >
        <a.div
          className="pointer position-absolute rounded-pill"
          style={{ width: pillWidth, height: switchHeight, ...(styles as any) }}
        />
      </div>
    </div>
  );
};
