import { Formik } from "formik";
import React from "react";
import { Outlet } from "react-router-dom";
import { useAuthStore } from "../../hooks/useAuthStore";
import { UserThemes } from "../../pages/domobilize/votePage";
import axiosInstance from "../../service/axiosInstance";

//  TODO: Reuse layout from OnboardingStepper
const VoteLayout: React.FC = () => {
  const { user, updateUser } = useAuthStore();

  const submitVote = async (values: UserThemes) => {
    const updateResponse = (
      await axiosInstance.put("/api/user", {
        user_id: user.id,
        themes: values.themes,
      })
    ).data;

    updateUser(updateResponse);
  };

  const initialValues: UserThemes = {
    user_id: null,
    themes: [],
  };

  return (
    <Formik
      onSubmit={async (values, { setSubmitting }) => {
        await submitVote(values);
        setSubmitting(false);
      }}
      initialValues={initialValues}
    >
      {() => (
        <>
          <Outlet />
        </>
      )}
    </Formik>
  );
};

export default VoteLayout;
