import React from "react";
import {
  COLOR_INFO,
  COLOR_NEGATIVE_NUMBER,
  COLOR_PLACEHOLDER,
  lookUpData,
} from "../../../config/constants";
import { roundToOneDecimal } from "../../../utils/roundToOneDecimal";
import {
  AllocationCategory,
  Subcategory,
} from "../../../utils/adjustInvestment/getAllocationCategories";
import { Mobile } from "../../display/Mobile";
import { useTranslation } from "react-i18next";

type Props = {
  categories: AllocationCategory[];
};

export const InvestmentSummaryTableMobile = ({ categories }: Props) => {
  const { t } = useTranslation();

  if (categories.length === 0) {
    return null;
  }

  return (
    <Mobile>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-7 px-0">
              <p className="mb-0 p-table-title fw-bold">
                {t("myInvestment.theme")}
              </p>
            </div>

            <div className="col-3 px-0">
              <p className="mb-0 p-table-title fw-bold">
                {t("myThemes.newDistribution")}
              </p>
            </div>

            <div className="col-2 px-0">
              <p className="mb-0 p-table-title fw-bold">
                {t("myThemes.change")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {categories.map(category => {
          const visualInfo = lookUpData[category.category_id];
          return (
            <div key={category.category_id.toString()} className="col-12 my-2">
              <div className="row px-0">
                <div className="col-12 px-0 mb-3 ">
                  <hr />
                </div>
              </div>

              {/* Row with icon, title and total amount */}
              <div className="row">
                <div className="col-7 d-flex px-0">
                  <img
                    style={{ width: 24, height: 24 }}
                    src={visualInfo.icon}
                    alt=""
                  />
                  <p className="mb-1 mt-1 ml-2 p-table-title fw-bold ">
                    {category.category}
                  </p>
                </div>

                <div className="col-4 px-0">
                  <p className="mb-1 fw-bold p-table-title  ">
                    {roundToOneDecimal(category.totalAmount)}{" "}
                  </p>
                </div>
              </div>

              {/* subcategories */}
              <div className="row">
                <div className="col-7 px-0 mt-3 d-flex ">
                  <div>
                    {category.subcategories.map((subcategory: Subcategory) => (
                      <div key={subcategory.key}>
                        <p
                          className="mb-1"
                          style={{ fontSize: ".7rem", opacity: 0.4 }}
                        >
                          {subcategory.title}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-3 mt-3 px-0">
                  <div>
                    {category.subcategories.map((subcategory: Subcategory) => (
                      <div key={`amount_${subcategory.key}`}>
                        <p
                          className="mb-1"
                          style={{ fontSize: ".7rem", opacity: 0.4 }}
                        >
                          {roundToOneDecimal(subcategory.amount)}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-2 mt-3 px-0 ">
                  <div>
                    {category.subcategories.map((subcategory: Subcategory) => {
                      const isBelow =
                        subcategory.difference < 0
                          ? COLOR_NEGATIVE_NUMBER
                          : COLOR_INFO;
                      return (
                        <div key={`change_${subcategory.key}`}>
                          <p
                            className="mb-1"
                            style={{ fontSize: ".7rem", color: isBelow }}
                          >
                            {roundToOneDecimal(subcategory.difference)}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* Row with bar and percent */}
              <div className="row">
                <div className="col-12 mt-3 px-0 d-flex justify-content-between">
                  <p className="mb-1 p-table-title fw-bold ">Tema fordeling</p>
                  <p className="ml-3 p-table-title fw-bold">
                    {roundToOneDecimal(category.totalPercentage, true)}
                  </p>
                  <div
                    className="position-relative"
                    style={{ width: "50%", marginTop: 3 }}
                  >
                    <div
                      className="rounded-pill w-100"
                      style={{ height: 12, background: COLOR_PLACEHOLDER }}
                    />
                    <div
                      className="position-absolute rounded-pill"
                      style={{
                        height: 12,
                        background: visualInfo.color,
                        top: 0,
                        left: 0,
                        width: `${category.totalPercentage}%`,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Mobile>
  );
};
