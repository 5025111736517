/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 23-09-2020.
 */

import React, { useEffect, useState } from "react";

import { PieChartComp } from "./PieChartComp";

import { ThemeItem } from "../pages/front/ThemeItem";
import { ErrorComponent } from "./ErrorComponent";

import { useTranslation } from "react-i18next";
import "./pie-chart.scss";

type Props = { pieChartData: any; title?: string };

export const PieChartWrapper: React.FC<Props> = ({ pieChartData, title }) => {
  const [error, setError] = useState<string>("");
  const [data, setData] = useState<any>({
    date_updated: "",
    portfolio: [],
    total_amount: 0,
  });

  const { t } = useTranslation();

  title = t("dashboard.pieChartText");

  useEffect(() => {
    const fetchDistribution = async () => {
      try {
        if (pieChartData) {
          setData(pieChartData);
        }
      } catch (err: any) {
        console.log(" PieChartWrapper > error = ", err);
        setError("-");
      }
    };
    fetchDistribution();
  }, [pieChartData]);

  if (error) {
    return <ErrorComponent error={error}></ErrorComponent>;
  }

  return (
    <div className="col-12 mt-4">
      <PieChartComp data={data} title={title} />
      <p className="text-center mt-5">{t("dashboard.investmentThemes")}</p>

      <div
        data-tooltip-id="chart"
        className="d-flex px-3 mt-n2 pt-2 pb-6  justify-content-center flex-wrap"
      >
        {data.portfolio.map((item: any, index: number) => {
          return item.id === 0 ? null : (
            <ThemeItem
              key={index}
              themeName={item.title}
              id={item.id}
              index={index}
              useBackground={false}
              size={55}
              className="px-1"
            ></ThemeItem>
          );
        })}
      </div>
    </div>
  );
};
