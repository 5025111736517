import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { COLOR_PRIMARY, lookUpData } from "../../../config/constants";
import { InvestmentRequestType } from "../../../types/adjust-investments";
import { Toggle } from "../../form/Toggle";
import { ThemeInfoModal } from "../../modals/ThemeInfoModal";
import "../styles/filters-slider.scss";

const SHOW_THEME_ICON = false;

type InvestmentThemeItemProps = {
  data: InvestmentRequestType;
  onToggleClick: () => void;
  onSliderChange: (rating: number) => void;
};

const defaultToggleOptions = {
  pillWidth: 30,
  switchWidth: 50,
  switchHeight: 23,
};
export const InvestmentThemeItem = ({
  data,
  onSliderChange,
  onToggleClick,
}: InvestmentThemeItemProps) => {
  const icon = lookUpData[data.theme_id];

  const toggleOptions = {
    ...defaultToggleOptions,
    activeColor: icon.color,
  };

  const min = 10;
  const max = 100;

  const handleSliderChange = (newValue: number | number[]) => {
    if (typeof newValue === "number") {
      onSliderChange(newValue);
    }
  };

  return (
    <div className="col-12 mb-4 pl-0 pr-0">
      <div className="row">
        <div className="col-12 mb-md-0 d-flex align-items-center">
          {SHOW_THEME_ICON && (
            <img style={{ width: 36, height: 36 }} src={icon.icon} alt="" />
          )}
          <div className="ml-2">
            <Toggle
              onClick={onToggleClick}
              active={data.active}
              options={toggleOptions}
            />
          </div>
          <div className="ml-3 flex-grow-1 d-flex align-items-center">
            <p className="mb-2 mr-3">{data.title}</p>
            <ThemeInfoModal theme_id={data.theme_id} />
          </div>
        </div>
      </div>
      {data.active && (
        <div className="row" style={{ marginTop: -7 }}>
          <div className="col-12">
            <div
              style={{ marginLeft: 62, marginRight: 0 }}
              className="position-relative"
            >
              <div
                className="position-absolute w-100 d-flex"
                style={{ marginTop: 9 }}
              >
                <p
                  className="ml-2 flex-grow-1"
                  style={{ fontSize: ".5rem", opacity: 0.4 }}
                >
                  Lidt
                </p>
                <p className="mr-2" style={{ fontSize: ".5rem", opacity: 0.4 }}>
                  Meget
                </p>
              </div>
              <div className="px-2 position-relative">
                <Slider
                  min={min}
                  max={max}
                  value={data.rating}
                  onChange={handleSliderChange}
                  trackStyle={{
                    backgroundColor: data.active ? COLOR_PRIMARY : '#ccc"',
                  }}
                  handleStyle={{
                    borderColor: data.active ? COLOR_PRIMARY : "transparent",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
