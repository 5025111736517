import { useMediaQuery } from "react-responsive";
import { SpinnerFullHeight } from "../../components/spinner/SpinnerFullHeight";
import { COLOR_PRIMARY } from "../../config/constants";
import { useAuthStore } from "../../hooks/useAuthStore";
import { ButtonGreen } from "../../components/display/ButtonGreen";
import { useTranslation } from "react-i18next";
import { directLoginUrl, meetingUrl } from "../../routes";
const DoMobilizePage: React.FC = () => {
  const { user, previewToken } = useAuthStore();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const largeScreen = useMediaQuery({ minWidth: 992 });
  const { t } = useTranslation();

  const minHeight = isMobile ? 500 : 600;
  return (
    <>
      <div className="container flex lg:h-full items-center">
        <div className={`row position-relative min-h-${minHeight}`}>
          <div className="col-12 col-lg-6 order-1 order-lg-0 position-relative">
            <div
              className={`row w-full lg:w-[460px] mx-auto xl:w-[560px] lg:h-[700px] flex justify-center items-center lg:pr-8`}
            >
              <div className="relative w-100 h-80">
                <iframe
                  title="Loom Video"
                  src="https://www.loom.com/embed/1ff2ef7a52134ad28495751875e64eac?sid=39690518-3ddf-4ffc-96c8-07faca0ece63"
                  allowFullScreen
                  className="absolute top-0 left-0 w-full h-full"
                ></iframe>
              </div>
            </div>

            <div
              className="h-100 d-none d-lg-block position-absolute"
              style={{ top: 0, right: 0, zIndex: 10, width: 1 }}
            >
              <div
                className="w-100 h-100 "
                style={{ background: COLOR_PRIMARY }}
              ></div>
            </div>
          </div>

          <div className="col-12 col-lg-6 flex flex-column justify-content-center">
            {!user ? (
              <SpinnerFullHeight />
            ) : (
              <div className={`px-5 pb-5 ${!largeScreen ? "mt-6" : ""}`}>
                <h1 style={{ whiteSpace: "pre-line" }}>
                  {t("trial.doMobilize.header")}
                </h1>
                <br />
                <p>{t("trial.doMobilize.description")}</p>
                <div className="flex gap-2">
                  <ButtonGreen filled url={meetingUrl}>
                    <p className="mb-0">{t("trial.meetingBtn")}</p>
                  </ButtonGreen>
                  <ButtonGreen white url={directLoginUrl + `${previewToken}`}>
                    <p className="mb-0">{t("trial.startBtn")}</p>
                  </ButtonGreen>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default DoMobilizePage;
