const matcher = /.+@.+\..+/;
export const validateEmail = (str: string) => {
  /**
   * Loosely validate an email address.
   *
   * @param {string} string
   * @return {boolean}
   */

  return matcher.test(str);
};
