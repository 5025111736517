/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 23-10-2020.
 */
import React from "react";
import Spinner from "./Spinner";

type Props = {
  children?: React.ReactNode | null;
};

export const SpinnerFullHeight: React.FC<Props> = ({ children = null }) => {
  return (
    <div className="container-fluid h-100 px-0 no-blurry">
      <div className="row h-100">
        <div className="col-12 h-50 d-flex justify-content-center align-items-center">
          <div className="d-flex align-items-center flex-column">
            <Spinner style={{ marginBottom: 30 }} size={8}></Spinner>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
