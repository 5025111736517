/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 23/11/2020.
 */
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  error: string;
};

export const ErrorComponent: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation();

  return (
    <div className="col-12 mt-4">
      <h1 className="text-center">{t("generic.error")}</h1>
      {error && <p className="text-center">{error}</p>}
    </div>
  );
};
