/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 11/06/2021.
 */
import React from "react";
import { roundToOneDecimal } from "../../utils/roundToOneDecimal";
import { SummeryResponse } from "../../service/SummeryResponse";
import { useTranslation } from "react-i18next";

type Props = {
  summeryData: SummeryResponse | null;
  isPct: boolean;
};

type IinvestmentColumn = {
  title: string;
  value: string;
  mutedText?: string;
};

const InvestmentInfoColumn = ({
  title,
  value,
  mutedText,
}: IinvestmentColumn) => (
  <div className="col-6 mt-4 mt-md-0 col-md-3">
    <div className="row">
      <div className="col-12 d-flex justify-content-between">
        <p className="mb-0 p-table-title fw-bold">{title}</p>
      </div>
      <div className="col-12">
        <hr className="my-3" />
      </div>
      <div className="col-12 d-flex justify-content-between">
        <p className="p-table-title fw-bold">
          {value}
          <br />
          {mutedText && (
            <span className="mb-0 mt-2 text-sm font-light text-muted">
              {mutedText}
            </span>
          )}
        </p>
      </div>
    </div>
  </div>
);
const calculateTotalReturns = (
  summeryData: SummeryResponse,
  isPct: boolean,
) => {
  const summeryReturnKey = isPct ? "return_pct" : "return_amount";
  return roundToOneDecimal(summeryData.result[summeryReturnKey], isPct);
};

const calculateCosts = (summeryData: SummeryResponse, isPct: boolean) => {
  const feesReturnKey = isPct ? "fees_pct" : "fees_amount";
  return roundToOneDecimal(summeryData.result[feesReturnKey], isPct);
};

export const MyInvestmentSummery = ({ summeryData, isPct }: Props) => {
  const { t } = useTranslation();

  const investmentColumns: IinvestmentColumn[] = [
    {
      title: t("myInvestment.totalValue"),
      value:
        (summeryData && roundToOneDecimal(summeryData.result.total)) || "-",
    },
    {
      title: t("myInvestment.totalGrowth"),
      value:
        (summeryData && roundToOneDecimal(summeryData.result.total_change)) ||
        "-",
    },
    {
      title: t("myInvestment.depositsAndWithDrawals"),
      value:
        (summeryData && roundToOneDecimal(summeryData.result.deposits)) || "-",
    },
    {
      title: t("myInvestment.returns"),
      value: (summeryData && calculateTotalReturns(summeryData, isPct)) || "-",
      mutedText: `${t("myInvestment.costs")} ${
        (summeryData && calculateCosts(summeryData, isPct)) || "-"
      }`,
    },
  ];

  return (
    <>
      <div className="row mt-md-6 mt-4">
        {investmentColumns.map((column, index) => (
          <InvestmentInfoColumn
            title={column.title}
            value={column.value}
            mutedText={column.mutedText}
            key={index}
          />
        ))}
      </div>
    </>
  );
};
