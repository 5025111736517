import React, { createContext, useContext, useState } from "react";

interface IProfileContext {
  formTouched: boolean;
  setFormTouched?: (val: boolean) => void;

  saved: boolean;
  setSaved?: (val: boolean) => void;

  savedError: boolean;
  setSavedError?: (val: boolean) => void;
}

const ProfileContext = createContext<IProfileContext>({
  formTouched: false,
  saved: false,
  savedError: false,
});

type Props = {
  children: any;
};

const ProfileProvider = ({ children }: Props) => {
  const [formTouched, setFormTouched] = useState(false);
  const [saved, setSaved] = useState(false);
  const [savedError, setSavedError] = useState(false);

  return (
    <ProfileContext.Provider
      value={{
        formTouched,
        setFormTouched,
        saved,
        setSaved,
        savedError,
        setSavedError,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

function useProfileContext() {
  const context = useContext(ProfileContext);

  if (typeof context === "undefined") {
    throw new Error(
      "useProfileContext() must be used within a ProfileProvider component",
    );
  }

  return context;
}

export { ProfileProvider, useProfileContext };
