import { t } from "i18next";
import {
  COMMUNITY_PAGE,
  FRONT_PAGE,
  MATTER_IMPACT,
  MY_INVESTMENTS,
  PROFILE,
  RATING_PAGE,
  TOOLS_PAGE,
  TRIAL_PAGE,
  directLoginUrl,
  meetingUrl,
} from "../../routes";
import { ClientType } from "./Navigation";
export interface NavItem {
  title: string;
  internalLink: string;
  displayIf: boolean;
  style?: React.CSSProperties;
  target: boolean;
}
export const generateDDNavItems = ({
  roles,
  trialAccess,
  inDoInvestPath,
  previewToken,
}: {
  roles: string[];
  trialAccess: boolean;
  inDoInvestPath: boolean;
  previewToken: string | null;
}): NavItem[] => [
  {
    title: t("profile.changeProfile"),
    internalLink: PROFILE.to.replace(":section?", "info"),
    displayIf: !trialAccess && roles.includes(ClientType.Personal),
    target: true,
  },
  {
    title: t("generic.carryOnOnboarding"),
    internalLink: directLoginUrl + `${previewToken}`,
    displayIf: trialAccess,
    target: true,
  },
  {
    title: t("trial.meetingBtn"),
    internalLink: meetingUrl,
    displayIf: trialAccess,
    target: true,
  },
  {
    title: t("trial.backBtn"),
    internalLink: TRIAL_PAGE.to,
    displayIf: trialAccess && inDoInvestPath,
    target: false,
  },
];

export const generateNavItems = ({
  roles,
  trialAccess,
  communities,
  inDoInvestPath,
}: {
  roles: string[];
  trialAccess: boolean;
  communities: number[] | null;
  inDoInvestPath: boolean;
}): NavItem[] => [
  {
    title: t("trial.tools.header"),
    internalLink: TOOLS_PAGE.to,
    displayIf: trialAccess && !inDoInvestPath,
    target: false,
  },
  {
    title: FRONT_PAGE.title,
    internalLink: FRONT_PAGE.to,
    displayIf:
      (!trialAccess && FRONT_PAGE.visible) ||
      (trialAccess && inDoInvestPath && FRONT_PAGE.visible),
    target: false,
  },
  {
    title: MY_INVESTMENTS.title,
    internalLink: MY_INVESTMENTS.to,
    displayIf: !trialAccess || (trialAccess && inDoInvestPath),
    target: false,
  },
  {
    title: MATTER_IMPACT.title,
    internalLink: MATTER_IMPACT.to,
    displayIf: !trialAccess || (trialAccess && inDoInvestPath),
    target: false,
  },
  {
    title: RATING_PAGE.title,
    internalLink: RATING_PAGE.to,
    displayIf: !trialAccess || (trialAccess && inDoInvestPath),
    target: false,
  },
  {
    title: t("navigation.partners"),
    internalLink: "http://content.thisisdoland.com/?doland_dashboard=partnere",
    displayIf: !trialAccess,
    target: true,
  },
  {
    title: roles.includes(ClientType.Business)
      ? COMMUNITY_PAGE.corporateTitle
      : COMMUNITY_PAGE.title,
    internalLink:
      COMMUNITY_PAGE.to +
      "/" +
      (communities && communities.length > 0 ? communities[0] : ""),

    displayIf: !trialAccess,
    target: false,
  },
];
