/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 21-09-2020.
 */
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { PieChartWrapper } from "../../components/PieChartWrapper";
import { InvestmentLineChart } from "../../components/charts/InvestmentLineChart";
import { NewsList } from "../../components/news/NewsList";
import { SpinnerFullHeight } from "../../components/spinner/SpinnerFullHeight";
import { COLOR_PRIMARY } from "../../config/constants";
import { useAuthStore } from "../../hooks/useAuthStore";
import { frontPageStore } from "../../store/FrontPageStore";
import { LoadingTypeEnum, useOverlayStore } from "../../store/OverlayStore";
import { FrontpageBanners } from "./FrontpageBanners";
import { ToggleSwitch } from "./ToggleSwitch";
import { UserIntroText } from "./UserIntroText";

const FrontPage: React.FC = () => {
  const { hasTrialAccess, user } = useAuthStore();
  const { setLoading, clearLoading } = useOverlayStore();

  const [toggleValue, setToggleValue] = useState(true);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  //  Define store for personal or trial user
  const { getDistribution, getPeriodAll, distribution, periodAll } =
    frontPageStore();

  const h = 700;
  const minHeight = isMobile ? 500 : 600;

  const fetchData = async () => {
    setLoading(true, LoadingTypeEnum.Blocking);
    await Promise.all([
      getDistribution(hasTrialAccess()),
      getPeriodAll(hasTrialAccess()),
    ]);
    clearLoading();
  };

  useEffect(() => {
    if (!distribution || !periodAll) {
      fetchData();
    }
  }, [hasTrialAccess()]);

  if (!distribution) {
    return <SpinnerFullHeight />;
  }

  return (
    <>
      <div className="container ">
        <div className="row position-relative" style={{ minHeight: minHeight }}>
          <div className="col-12 col-lg-6 order-1 order-lg-0 position-relative">
            <div
              className="row mt-2 mt-md-5"
              style={{ minHeight: minHeight, height: h }}
            >
              {toggleValue ? (
                <PieChartWrapper pieChartData={distribution} />
              ) : (
                <InvestmentLineChart allData={periodAll} />
              )}
            </div>

            <div
              className={`position-absolute w-100 pr-4 ${!isMobile ?? "mt-6"}`}
              style={{ bottom: 0 }}
            >
              {periodAll && (
                <ToggleSwitch
                  setValue={setToggleValue}
                  initialValue={toggleValue}
                ></ToggleSwitch>
              )}
            </div>

            <div
              className="h-100 d-none d-lg-block position-absolute pt-6 "
              style={{ top: 0, right: 0, zIndex: 10, width: 1 }}
            >
              <div
                className="w-100 h-100 "
                style={{ background: COLOR_PRIMARY }}
              ></div>
            </div>
          </div>

          <div className="col-12 col-lg-6 d-flex flex-column justify-content-center mt-6">
            {!user ? (
              <SpinnerFullHeight />
            ) : (
              <UserIntroText
                totalAmount={distribution.total_amount}
                user={user}
                isMobile={isMobile}
              ></UserIntroText>
            )}
          </div>
        </div>
        {!hasTrialAccess() && <FrontpageBanners />}

        {!hasTrialAccess() && <NewsList />}
      </div>
    </>
  );
};

export default FrontPage;
