import React, { useEffect, useState } from "react";
import { useProfileContext } from "../ProfileProvider";
import * as Yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import { FormikInput, FormikCheckbox } from "../fields";
import { Button } from "../../../components/display/Button";
import { get, post } from "../../../service/API";
import { MY_BASE_URL } from "../../../config/constants";
import { IncomeResponse } from "../types/IncomeResponse";
import Spinner from "../../../components/spinner/Spinner";
import SaveSuccessMessage from "./SaveSuccessMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { useRebalancingCheck } from "../../../utils/navigation";
import { Trans, useTranslation } from "react-i18next";

type IncomeForm = {
  income: number;
  income_secondary: number;
  investment_liquidity: number;
  other_wealth: number;
  public_benefits: string;
};

const IncomeSchema = Yup.object().shape({
  income: Yup.number()
    .typeError("You must specify a number")
    .min(0, "Must be bigger than 0"),
  income_secondary: Yup.number()
    .typeError("You must specify a number")
    .min(0, "Must be bigger than 0"),
  investment_liquidity: Yup.number()
    .typeError("You must specify a number")
    .min(0, "Must be bigger than 0"),
  other_wealth: Yup.number()
    .typeError("You must specify a number")
    .min(0, "Must be bigger than 0"),
  public_benefits: Yup.string().required("Required"),
});

const currencyRenderer = (val: string = "") => {
  const numericValue = stripLocaleSeparators(val);
  return Number(numericValue).toLocaleString("da-DK");
};

const stripLocaleSeparators = (val: string = "") =>
  String(val || "").replace(/\D/g, "");

export const IncomeSection = () => {
  const { saved, setSaved } = useProfileContext();
  const [currency, setCurrency] = useState("DKK");
  const [initialValues, setInitialValues] = useState<IncomeForm | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    get(`${MY_BASE_URL}/dashboard/profile/income`).then(
      (res: IncomeResponse) => {
        setCurrency(res.income.currency);
        setInitialValues({
          income: res.income.amount,
          income_secondary: res.income_secondary.amount,
          investment_liquidity: res.investment_liquidity.amount,
          other_wealth: res.other_wealth.amount,
          public_benefits: res.public_benefits ? "Ja" : "Nej",
        });
      },
    );
  }, []);

  const onSubmit = (values: any, { setSubmitting }: any) => {
    const data = {
      income: {
        amount: stripLocaleSeparators(values.income),
        currency,
      },
      income_secondary: {
        amount: stripLocaleSeparators(values.income_secondary),
        currency,
      },
      investment_liquidity: {
        amount: stripLocaleSeparators(values.investment_liquidity),
        currency,
      },
      other_wealth: {
        amount: stripLocaleSeparators(values.other_wealth),
        currency,
      },
      public_benefits: values.public_benefits === "Ja",
    };
    post(`${MY_BASE_URL}/dashboard/profile/income`, data)
      .then(() => {
        setSubmitting(false);
        setSaved?.(true);
        useRebalancingCheck(location, navigate);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };
  const { t } = useTranslation();
  return (
    <div>
      <div className={"text-primary mb-5"}>Indtægtsinformation</div>
      {saved && <SaveSuccessMessage />}

      {initialValues == null && (
        <div className="col-12">
          <div className="my-6 d-flex justify-content-center">
            <Spinner />
          </div>
        </div>
      )}

      {initialValues != null && (
        <Formik
          {...{ initialValues, onSubmit }}
          validationSchema={IncomeSchema}
        >
          {({ isSubmitting, values }: FormikProps<any>) => {
            return (
              <Form>
                <div className="row">
                  <div className="col-lg-6">
                    <FormikInput
                      name="income"
                      label="Indtægt efter skat pr. måned"
                      prefix={currency}
                      renderer={currencyRenderer}
                      preprocessor={stripLocaleSeparators}
                      type={"currency"}
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormikInput
                      name="income_secondary"
                      label="Anden indkomst"
                      prefix={currency}
                      renderer={currencyRenderer}
                      preprocessor={stripLocaleSeparators}
                      type={"currency"}
                    />
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-lg-6">
                    <FormikInput
                      name="investment_liquidity"
                      label="Opsparing i likvide midler"
                      prefix={currency}
                      renderer={currencyRenderer}
                      preprocessor={stripLocaleSeparators}
                      type={"currency"}
                    />
                  </div>
                  <div className="col-lg-6">
                    <FormikInput
                      name="other_wealth"
                      label="Anden formue"
                      prefix={currency}
                      renderer={currencyRenderer}
                      preprocessor={stripLocaleSeparators}
                      type={"currency"}
                    />
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-lg-6">
                    <FormikCheckbox
                      name="public_benefits"
                      label="Modtager af overførselsindkomst"
                      values={["Ja", "Nej"]}
                    />
                    {values?.public_benefits === "Ja" && (
                      <div className="mt-4">
                        <p style={{ color: "#FF6800" }}>
                          {t("profile.income.note")}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-6 mb-6 d-flex justify-content-center">
                  <Button disabled={isSubmitting} filled submit>
                    <p className="mb-0">{t("profile.updateInfo")}</p>
                  </Button>
                  {isSubmitting && (
                    <div style={{ marginTop: -5, marginLeft: 40 }}>
                      <Spinner />
                    </div>
                  )}
                </div>

                <div className="row mt-5">
                  <div className="col-lg-12">
                    <p style={{ fontSize: "0.7em" }}>
                      <Trans i18nKey={"profile.income.text"}>
                        {""}
                        {""}
                        <a
                          href="https://pensionsinfo.dk"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {""}
                        </a>
                        {""}
                      </Trans>
                    </p>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
