/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 15/12/2020.
 */

import React, { useState } from "react";
import Popup from "reactjs-popup";
import "./invite-friend-modal.scss";
import { Button } from "../display/Button";
import { WizardWrapper } from "../display/WizardWrapper";
import { InviteFriends } from "./InviteFriends";
import { InviteSent } from "./InviteSent";
import { post } from "../../service/API";
import { BASE_URL } from "../../config/constants";
import { t } from "i18next";

export const InviteFriendModal: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [emailsSent, setEmailsSent] = useState<string[]>([]);

  const closeModal = () => {
    setOpen(false);
    setPageIndex(0);
  };

  const toStepOne = () => {
    setPageIndex(0);
  };

  const nextStep = (arr: string[], msg: string) => {
    if (arr.length === 0) {
      return;
    }

    const obj = {
      email: arr,
      message: msg ? msg : t("invite.descriptionPlaceholder"),
    };

    const p = BASE_URL + "/dashboard/invite_friends";
    post(p, obj);
    const newIndex = pageIndex ? 0 : 1;
    setEmailsSent(arr);
    setPageIndex(newIndex);
  };

  return (
    <>
      <Button
        onClick={() => {
          setOpen(!open);
        }}
      >
        <p className="p-button mb-0" style={{ letterSpacing: -1 }}>
          {t("dashboard.invite.title")}
        </p>
      </Button>

      <Popup
        onClose={closeModal}
        open={open}
        modal
        nested
        className="board-popup"
        position="bottom center"
      >
        <div className="container-fluid mb-3 mt-3 " style={{ minHeight: 200 }}>
          <WizardWrapper pageIndex={pageIndex}>
            <InviteFriends
              closeModal={closeModal}
              nextStep={nextStep}
            ></InviteFriends>
            <InviteSent
              closeModal={closeModal}
              back={toStepOne}
              emailsSent={emailsSent}
            ></InviteSent>
          </WizardWrapper>
        </div>
      </Popup>
    </>
  );
};
