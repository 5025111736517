import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "../../components/display/Button";
import { COLOR_PRIMARY, lookUpData } from "../../config/constants";
import { DOMOBILIZE_VOTE_BALANCE_PAGE } from "../../routes";
import { ThemeChoice } from "./themeCoice";

export type UserThemes = {
  user_id: string | null;
  themes: { id: number; rating: number }[];
};

type ThemeItem = {
  color: string;
  icon: any; // Adjust type if you have a specific type for the icon
  title: string;
};

export const VotePage: React.FC = () => {
  const { t } = useTranslation();

  const minHeight = 600;
  const [isAnyThemeActive, setActive] = useState(false);

  const { values } = useFormikContext<UserThemes>();

  const themeData = Object.entries(lookUpData)
    .filter(([key]) => ![0, 1, 105, 10001].includes(+key))
    .reduce(
      (acc, [key, value]) => {
        acc[+key] = value;
        return acc;
      },
      {} as { [key: number]: ThemeItem },
    );

  useEffect(() => {
    if (values.themes.length === 0) {
      setActive(false);
    } else {
      setActive(true);
    }
  }, [values.themes.length]);
  return (
    <>
      <div className="w-full h-full lg:flex lg:items-center lg:justify-center pt-8 lg:pt-0">
        <div className="container mx-auto">
          <div
            className="flex flex-col-reverse lg:flex-row relative"
            style={{ minHeight: minHeight }}
          >
            <div className="col-12 col-lg-6 order-1 order-lg-0 position-relative">
              <div className="flex h-full justify-content-center items-center align-middle">
                <div className="flex flex-col gap-5 px-5 pb-5 lg:pb-0">
                  <h1 className="">
                    <Trans i18nKey={"communityInvite.voteTitle"}>
                      {""}
                      <span className="text-green"></span>
                    </Trans>
                  </h1>
                  <p>{t("communityInvite.voteText")}</p>
                </div>
              </div>

              <div
                className="h-100 d-none d-lg-block position-absolute pt-6 "
                style={{ top: 0, right: 0, zIndex: 10, width: 1 }}
              >
                <div
                  className="w-100 h-100 "
                  style={{ background: COLOR_PRIMARY }}
                ></div>
              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex flex-column justify-content-center mt-6">
              <div className="flex flex-col gap-5 px-5 pb-5 lg:pb-0">
                <div className="grid pap-x-4 grid-cols-3">
                  {Object.entries(themeData).map(([id, item]) => (
                    <ThemeChoice key={id} theme_id={+id} item={item} />
                  ))}
                </div>
                <div className="flex self-end">
                  <Link to={DOMOBILIZE_VOTE_BALANCE_PAGE.to}>
                    <Button
                      filled={isAnyThemeActive}
                      disabled={!isAnyThemeActive}
                    >
                      <p className="mb-0">
                        {!isAnyThemeActive
                          ? t("communityInvite.selectThemesBtn")
                          : t("communityInvite.nextBtn")}
                      </p>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
