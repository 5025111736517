import React from "react";
import { useField } from "formik";
import { useProfileContext } from "../ProfileProvider";
import { COLOR_GRAY, COLOR_PRIMARY } from "../../../config/constants";

type RadioProps = {
  label: string;
  value: string | number;
  onClick: (ev: any, v: string | number) => void;
  selected: boolean;
};

export const Radio = ({
  label,
  value,
  onClick,
  selected = false,
}: RadioProps) => {
  return (
    <button
      name={label}
      className="d-flex mb-2 align-items-center p-small fw-bold"
      type="button"
      style={{
        outline: "none",
        padding: "10px 20px",
        cursor: "pointer",
        borderRadius: 14,
        border: selected
          ? "1px solid transparent"
          : `1px solid ${COLOR_PRIMARY}`,
        background: selected ? COLOR_PRIMARY : "white",
        color: selected ? "white" : COLOR_PRIMARY,
        textAlign: "center",
      }}
      onClick={ev => onClick(ev, value)}
    >
      <span className="w-100">{label}</span>
    </button>
  );
};

type RadioOption = {
  label: string;
  value: string | number;
};

type Props = {
  name: string;
  label: string;
  options: RadioOption[];
  vertical?: boolean;
  description?: string;
  children?: any;
};

export const FormikRadio = ({
  children,
  name,
  label,
  options = [],
  vertical = false,
  description,
}: Props) => {
  const { setFormTouched, setSaved } = useProfileContext();
  const [field, meta, helpers] = useField(name);

  const onBlur = (e: React.FocusEvent<any>) => {
    field?.onBlur(e);
    setFormTouched?.(true);
    setSaved?.(false);
  };

  const onClick = (ev: any, value: string | number) => {
    helpers.setValue(value);
    onBlur(ev);
  };

  return (
    <div className="">
      <p className="mb-3 text-black fw-bold ">{label}</p>
      {description && (
        <p className="p-small" style={{ color: COLOR_GRAY }}>
          {description}
        </p>
      )}
      {children}
      <div className={`radio-options-wrapper ${vertical ? "vertical" : ""}`}>
        {options.map(({ label, value }: RadioOption) => (
          <Radio
            key={value}
            label={label}
            value={value}
            selected={field.value === value}
            onClick={onClick}
          />
        ))}
      </div>

      {meta.error && meta.touched && (
        <div className="">
          <p className="p-small text-danger mt-2 mb-0">{meta.error}</p>
          {/*<hr className="mt-2 mb-1" />*/}
        </div>
      )}
    </div>
  );
};
