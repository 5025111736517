import { useEffect, useRef } from "react";

const matterOptions = {
  nextIcon: "none",
  doneIcon: "none",
  infoIcon: "none",
  progressCurrentIcon: "dot",
  locale: "da-dk",
  tooltip: {
    readMoreText: "Læs mere",
    readMoreLinks: "https://thisismatter.com",
  },
};

type ItemProps = {
  portfolio: MatterElements.PortfolioQuery;
  type: "co2" | "fossil" | "waste" | "energy";
};

export const MatterAppItem: React.FC<ItemProps> = ({
  portfolio,
  type,
}: ItemProps) => {
  const containerRefOne = useRef<HTMLDivElement>(null);

  // create an element when the portfolio is ready. We don't need to wait for the API key to be ready.
  useEffect(() => {
    if (containerRefOne.current) {
      // create an instance of Single Impact controller

      const singleImpactElement = MatterElements.singleImpact(
        //'co2', // "co2", "waste", "energy", "fossil"
        type,
        portfolio,
        matterOptions,
      );

      // assign a container for rendering.
      // At least the width of the container should be specified for the Element to be displayed.
      // Some height is necessary to display the loading screen.
      singleImpactElement.render(containerRefOne.current);
      return singleImpactElement.destroy;
    }
    return undefined;
  }, [portfolio, type]);

  return (
    <div className="col-12 col-md-6 col-lg-4 mb-5">
      <div ref={containerRefOne} className="" style={{ height: 240 }} />
    </div>
  );
};
