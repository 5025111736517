import React from "react";
import { Button } from "../components/display/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex h-full align-middle">
      <div className="flex flex-col m-auto items-center">
        <p className="text-teal-500 text-7xl md:text-9xl font-bold">
          {t("404.404")}
        </p>
        <h4 className="text-lg md:text-2xl">{t("404.ops")}</h4>
        <p className="">{t("404.sorry")}</p>
        {/* Errors? */}
        <Link to={"/"}>
          <Button filled className="md:mt-3">
            <p className="mb-0 md:text-lg">{t("404.back")}</p>
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
