import React from "react";
import { Link } from "react-router-dom";
import SocialImpactIcon from "../../assets/svg/InvestmentThemeIcons/SocialImpact.svg";
import { Button } from "../../components/display/Button";
import { COLOR_PRIMARY } from "../../config/constants";
import { useAuthStore } from "../../hooks/useAuthStore";
import { DOMOBILIZE_VOTE_PAGE, directLoginUrl } from "../../routes";
import { useTranslation } from "react-i18next";
import ThemePieChart from "./Charts/ThemePieChart";

export const IntroPage: React.FC = () => {
  const { t } = useTranslation();
  const { user, previewToken } = useAuthStore();

  const minHeight = 600;
  const svg = SocialImpactIcon;

  const selectInvestmentThemes =
    user?.onboarding?.formValues?.selectInvestmentThemes;

  const formattedInvestmentThemes =
    selectInvestmentThemes &&
    selectInvestmentThemes.map((item: any) => ({
      id: item.categoryId,
      rating: item.weight,
    }));

  console.log(formattedInvestmentThemes);

  return (
    <>
      <div className="w-full h-full lg:flex lg:items-center lg:justify-center">
        <div className="container mx-auto">
          <div
            className="flex flex-col lg:flex-row"
            style={{ minHeight: minHeight }}
          >
            <div className="col-12 col-lg-6 order-1 order-lg-0 position-relative">
              <div className="flex h-full justify-content-center items-center align-middle">
                {selectInvestmentThemes ? (
                  <ThemePieChart themes={formattedInvestmentThemes} />
                ) : (
                  <img src={svg} alt="" />
                )}
              </div>

              <div
                className="h-100 d-none d-lg-block position-absolute pt-6 "
                style={{ top: 0, right: 0, zIndex: 10, width: 1 }}
              >
                <div
                  className="w-100 h-100 "
                  style={{ background: COLOR_PRIMARY }}
                ></div>
              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex flex-column justify-content-center mt-6">
              <div className="flex flex-col gap-5 px-5 pb-5 lg:pb-0">
                <h1>
                  {selectInvestmentThemes ? "Tak" : "Hej"}
                  <span style={{ color: COLOR_PRIMARY }}>
                    {user?.name || ""}
                  </span>
                  ,
                </h1>
                <p>
                  {selectInvestmentThemes
                    ? t("communityInvite.votingCompleteText")
                    : t("communityInvite.introText")}
                  <br />
                </p>
                <div className="flex flex-row justify-between">
                  <Link
                    className="flex self-end hover:no-underline"
                    to={DOMOBILIZE_VOTE_PAGE.to}
                  >
                    <Button filled={!selectInvestmentThemes}>
                      <p className="mb-0">
                        {selectInvestmentThemes
                          ? t("communityInvite.newVoteBtn")
                          : t("communityInvite.voteBtn")}
                      </p>
                    </Button>
                  </Link>
                  {selectInvestmentThemes && (
                    <a
                      className="flex self-end hover:no-underline"
                      href={directLoginUrl + `${previewToken}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button filled>
                        <p className="mb-0">
                          {t("communityInvite.getStartedBtn")}
                        </p>
                      </Button>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
