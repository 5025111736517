import { Link } from "react-router-dom";
import { Button } from "../components/display/Button";
import { COLOR_PRIMARY } from "../config/constants";
import { useAuthStore } from "../hooks/useAuthStore";
import { directLoginUrl } from "../routes";

export const NoContent = () => {
  const { user, previewToken, logout } = useAuthStore();
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="text-center flex flex-col col-12 col-lg-6 pb-10 gap-5 px-4 md:px-6 lg:px-8">
        <h1 className="text-2xl">
          Hej <span style={{ color: COLOR_PRIMARY }}>{user?.name || ""}</span>,
        </h1>
        <p>
          Du har endnu ikke gennemført onboardingprocessen. Klik her for at
          færdiggøre din onboarding.
          <br />
        </p>
        <div className="flex flex-row justify-between">
          <Button onClick={logout}>
            <p className="mb-0">Log ud</p>
          </Button>
          <Link
            target="_blank"
            className="flex self-end hover:no-underline"
            to={directLoginUrl + previewToken}
          >
            <Button filled>
              <p className="mb-0">Fortsæt onboarding</p>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
