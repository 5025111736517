import axios from "axios";
import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import svg from "../../assets/svg/windmills.svg";
import CheckBox from "../../components/form/Checkbox";
import InputField from "../../components/form/InputField";
import SubmitButton from "../../components/form/SubmitButton";
import { CONFIG_CENTER_API } from "../../config/constants";
import { useAuthStore } from "../../hooks/useAuthStore";
import { previewTokenKey } from "../../store/auth/authStore";

export type SignUpValues = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  acceptTerms: boolean;
};

export const SignUp: React.FC = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const {
    token: existingToken,
    previewToken: existingPreviewToken,
    validateAndRedirect,
    loginTrial,
  } = useAuthStore();

  useEffect(() => {
    validateAndRedirect(token);
  }, [token, validateAndRedirect]);

  if (existingToken || existingPreviewToken) {
    return <Navigate to="/" replace />;
  }

  const initialValues: SignUpValues = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    acceptTerms: false,
  };

  const submitForm = async (values: SignUpValues) => {
    (await axios.post(`${CONFIG_CENTER_API}/api/user?token=${token}`, values))
      .data;

    const user = await loginTrial(values.password, values.email);

    if (user.token) {
      localStorage.setItem(previewTokenKey, user.token);
      location.reload();
      return <Navigate to="/" replace />;
    }
    return null;
  };

  const validate = Yup.object().shape({
    name: Yup.string().required(t("signUp.validationError.required")),
    email: Yup.string()
      .email(t("signUp.validationError.invalidEmail"))
      .required(t("signUp.validationError.required")),
    password: Yup.string()
      .min(8, t("signUp.validationError.passwordShort"))
      .matches(/(?=.*[A-Z])/, t("signUp.validationError.passwordUppercase"))
      .matches(/(?=.*\d)/, t("signUp.validationError.passwordNumber"))
      .required(t("signUp.validationError.required")),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), undefined],
        t("signUp.validationError.passwordMisMatch"),
      )
      .required(t("signUp.validationError.required")),
    acceptTerms: Yup.bool().oneOf(
      [true],
      t("signUp.validationError.termsAndConditions"),
    ),
  });

  return (
    <div className="flex flex-col xl:flex-row-reverse h-full xl:justify-between overflow-y-auto">
      <div className="flex self-center w-1/3 xl:self-end py-10">
        <img src={svg} alt="DoLand svg Icon" className="w-full h-full" />
      </div>
      <div className="flex w-full xl:w-2/3 justify-center self-center">
        <Formik
          initialValues={initialValues}
          validationSchema={validate}
          onSubmit={async (values, { setSubmitting }) => {
            await submitForm(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, status }) => (
            <Form className="xl:1/3 xl:max-w-md flex flex-col">
              <div className="">
                <h1 className="text-base text-center font-semibold leading-7 text-primary mb-10">
                  {t("signUp.title")}
                </h1>
              </div>

              <InputField
                className="py-3"
                name="name"
                type="text"
                label="Name"
                autoComplete="name"
              />
              <InputField
                className="py-3"
                name="email"
                type="email"
                label="E-mail"
                autoComplete="email"
              />
              <InputField
                className="py-3"
                name="password"
                type="password"
                label="Adgangskode"
                autoComplete="password"
              />
              <InputField
                className="py-3"
                name="confirmPassword"
                type="password"
                label="Bekræft adgangskode"
                autoComplete="confirm-password"
              />
              <div className="flex mt-3 items-center justify-between">
                <CheckBox
                  className="flex flex-col"
                  name="acceptTerms"
                  label="signUp.acceptTerms.label"
                  description={
                    <>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-teal underline pr-1"
                        href="https://thisisdoland.com/forretningsbetingelser"
                      >
                        {t("signUp.acceptTerms.businessConditions")}
                      </a>
                      {t("signUp.acceptTerms.and")}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-teal underline pl-1"
                        href="https://thisisdoland.com/privatlivspolitik"
                      >
                        {t("signUp.acceptTerms.privacyPolicy")}
                      </a>
                    </>
                  }
                />
              </div>
              <div className="mt-3 mb-10 md:mb-3 lg:mb-0 fle w-full items-center gap-x-6">
                <SubmitButton
                  label={t("signUp.signUpButton")}
                  className="w-full"
                  isSubmitting={isSubmitting}
                ></SubmitButton>
              </div>
              {status && <p className="text-red-500">{status}</p>}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
